import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditButtonsComponent from './EditButtonsComponent'

class EditButtonsContainer extends Component {
  render() {
    const { pixelBBox, handleEditionMode, interaction, show } = this.props
    return show && interaction === 'edit' && pixelBBox.length > 0 ? (
      <EditButtonsComponent
        pixelBBox={pixelBBox}
        handleEditionMode={handleEditionMode}
      />
    ) : null
  }
}

export default connect(({ map: { interaction }, drawing }) => ({
  interaction,
  show: !drawing.action,
}))(EditButtonsContainer)
