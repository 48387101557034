import React from 'react'

import { Menu, MenuItem } from '@material-ui/core'

import terrainImg from '../../assets/terrain.jpg'

const ActionsMenu = ({
  anchorEl,
  open,
  isValidProject,
  handleClose,
  handleCalibrationConfigWindow,
  handleSimulationResultWindow,
  handlePluAvancementWindow,
  handlePluReglementWindow,
  exportToPdf,
  exportToDXF,
  handlePluToSHP,
  handleCommuneCharte,
}) => {
  return (
    <Menu
      style={{ top: '10px' }}
      // className="w3-deep-orange"
      // style={{  }}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={handleClose}
      keepMounted
      disableAutoFocusItem={true}
      disableScrollLock={true}
    >
      <MenuItem onClick={() => handleCalibrationConfigWindow()}>
        Configurer le gabarit
      </MenuItem>
      <MenuItem onClick={() => handleSimulationResultWindow()}>
        Effectuer une simulation
      </MenuItem>
      <MenuItem disabled={!isValidProject()} onClick={() => exportToPdf()}>
        Exporter le projet au format pdf
      </MenuItem>
      <MenuItem disabled={!isValidProject()} onClick={() => exportToDXF()}>
        Exporter le projet au format DXF
      </MenuItem>
      <MenuItem onClick={() => handlePluToSHP()}>
        Exporter un zonage PLU au format SHP
      </MenuItem>
      <MenuItem onClick={() => handlePluAvancementWindow()}>
        Exporter l'avancement du PLU
      </MenuItem>
      <MenuItem onClick={() => handlePluReglementWindow()}>
        Telecharger la réglementation du PLU
      </MenuItem>
      {/* <MenuItem onClick={() => handleCommuneCharte()}>
        Telecharger une charte promoteur
      </MenuItem> */}
      <img width="300px" src={terrainImg} alt="terrainImg" />
    </Menu>
  )
}

export default ActionsMenu
