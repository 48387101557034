import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function ChargeFonciereIcon(props) {
  return (
    <SvgIcon {...props} style={{ fontSize: '1.7rem' }} viewBox="0 0 64 64">
      <image
        width={64}
        height={64}
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAPFBMVEUAAADVgCvohSzmgyvo gyzohC3vuovvuYnngyzmgyzonFfomlbohS3mfiL9/Pv////s8PEvjxTr5Nvr49o+5zAbAAAADXRS TlMABpDw74/4+PDv9fWOHAadNAAAAAFiS0dEDxi6ANkAAAAHdElNRQfkARwVBQuW5n71AAAAcklE QVQ4y+3Uuw6AMAgF0FaLitqX/f9/tYmLKMSamA7qHWA5AzCg1L3oprVsDGgCoXOejeuBwEFwWRoC rRdjT5Ab8f2w+DwPwyCmBszX2JcQYoyV4L/Mh5fZWg1YNKNZJJeQQBiT4KaZQA3IP3s8PPvrrMSI PN7/1nyqAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTAxLTI5VDA0OjA1OjExLTA3OjAw1foJ7wAA ACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wMS0yOVQwNDowNToxMS0wNzowMKSnsVMAAAAASUVORK5C YII="
      />
    </SvgIcon>
  )
}
