import React, { Component } from 'react'
import { connect } from 'react-redux'
import MapContainer from '../MapContainer'
import {
  getParcelleInfo,
  showHoveredInfo,
  cleanHoveredInfo,
} from '../../../store/map/infoInteraction/infoInteractionReducer'
import { editProjectArea } from '../../../store/project/projectAreaReducer'
import {
  setBuilding,
  setBuildingPolygon,
  cleanBuilding,
  chooseAction,
  setDistanceValue,
  setSurfaceValue,
} from '../../../store/drawing/drawingReducer'

import { deleteBuilding } from '../../../store/project/buildingEditionReducer'

import eventHandler from './helpers/eventHandler'

import WebMercatorViewport from 'viewport-mercator-project'

function getPixelBBox(locations, viewport) {
  // fonction renvoyant la bbox en pixels d'un tableau de coordonnées lon lat
  if (locations.length > 0) {
    const wmViewport = new WebMercatorViewport(viewport)
    const pixels = locations.map(location => wmViewport.project(location))

    const px = pixels.map(pixel => pixel[0])
    const py = pixels.map(pixel => pixel[1])
    return [
      [Math.min(...px), Math.min(...py)],
      [Math.max(...px), Math.max(...py)],
    ]
  } else {
    return []
  }
}

class InteractionsContainer extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      clickLocations: [],
      creatingNode: false,
      editedNode: [],
      mouseLocation: [],
      snappedPoint: [],
      hoveredBuildingId: null,
      hoveredParcelleId: null,
      buildingMoveTarget: null,
    }
    this.state = this.initialState
  }

  getSnappingLocation() {
    // tableau des points accrochables

    // accrochage aux sommets des parcelles du projet
    const areaNodes = this.props.projectArea.features.reduce(
      (locations, feature) => {
        const points = feature.geometry.coordinates.reduce(
          (multipolygon, polygon) => [...multipolygon, ...polygon[0]],
          []
        )
        return [...locations, ...points]
      },
      []
    )

    // accrochage aux sommets des bâtiments du projet
    const buildingNodes = this.props.projectBuildings.features.reduce(
      (locations, feature) => {
        const points = feature.geometry.coordinates.reduce(
          (multipolygon, polygon) => [...multipolygon, ...polygon],
          []
        )
        return [...locations, ...points]
      },
      []
    )

    // accrochage au premier sommet du bâti en cours de dessin
    const nodes = areaNodes
      .concat(buildingNodes)
      .concat(this.state.clickLocations.slice(0, 1))
    // .concat(this.state.clickLocations.slice(0, -1))

    return [...new Set(nodes)] // renvoi un tableau composés d'éléments uniques
  }

  handleEditionMode = action => e => {
    this.props.chooseAction(action)
    if (action === 'delete') {
      const {
        deleteBuilding,
        projectId,
        drawing: { buildingId },
        cleanBuilding,
      } = this.props
      deleteBuilding({ projectId, buildingId })
      cleanBuilding()
      this.setState(this.initialState)
    } else if (action === 'changeHeight') {
      this.setState({
        clickLocations: [],
      })
    } else if (action === 'reduce1m') {
      this.setState({
        clickLocations: [],
      })
    } else if (action === 'movePolygon') {
      this.setState(this.initialState)
    }
  }

  handlePolygonEdition = action => e => {
    if (action === 'apply')
      this.props.setBuildingPolygon(this.state.clickLocations)
    else if (action === 'cancel') this.props.cleanBuilding()

    this.setState(this.initialState)
  }

  changeEventHandler = interaction => {
    // fonction renvoyant les méthodes de gestion des évènements (onClick, onHover...) selon l'interaction
    // Le binding permet de s'assurer que le this appelé dans selectedEventHandler soit celui du contexte actuel pour accéder aux props et au state de ce composant
    const selectedEventHandler = eventHandler[interaction].bind(this)

    return { ...selectedEventHandler() }
  }

  handleKeyDown = e => {
    if (e.key === 'Escape' || e.button === 2) {
      // echap ou clic droit
      e.preventDefault()
      this.setState(this.initialState)
      this.props.cleanBuilding()
    }
  }

  componentDidUpdate(prevProps) {
    const interaction = this.props.interaction
    if (interaction !== prevProps.interaction) {
      // suppression de l'ancien event
      document.removeEventListener('keydown', this.handleKeyDown, false)
      document.removeEventListener('contextmenu', this.handleKeyDown, false) // pour le click droit =Annuler saisie

      if (
        interaction === 'create' ||
        interaction === 'edit' ||
        interaction === 'measure'
      ) {
        // ajout d'un nouvelle event
        document.addEventListener('keydown', this.handleKeyDown, false)
        document.addEventListener('contextmenu', this.handleKeyDown, false) // pour le click droit =Annuler saisie
      }
    }
  }

  componentDidMount() {
    const interaction = this.props.interaction
    if (
      interaction === 'create' ||
      interaction === 'edit' ||
      interaction === 'measure'
    ) {
      // ajout d'un nouvelle event
      document.addEventListener('keydown', this.handleKeyDown, false)
      document.addEventListener('contextmenu', this.handleKeyDown, false) // pour le click droit =Annuler saisie
    }
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false)
    document.removeEventListener('contextmenu', this.handleKeyDown, false)
  }

  render() {
    return (
      <>
        <MapContainer
          eventHandlers={this.changeEventHandler(this.props.interaction)}
          clickLocations={this.state.clickLocations}
          pixelBBox={getPixelBBox(
            this.state.clickLocations,
            this.props.viewport
          )}
          mouseLocation={this.state.mouseLocation}
          snappedPoint={this.state.snappedPoint}
          editedNode={this.state.editedNode}
          hoveredBuildingId={this.state.hoveredBuildingId}
          hoveredParcelleId={this.state.hoveredParcelleId}
          buildingMoveTarget={this.state.buildingMoveTarget}
          handleEditionMode={this.handleEditionMode}
          handlePolygonEdition={this.handlePolygonEdition}
          hideBuildingElevation={
            this.props.drawing.action === 'changePolygon' ||
            this.props.drawing.action === 'movePolygon'
          }
        />
      </>
    )
  }
}

export default connect(
  ({
    drawing,
    map: { interaction, viewport },
    project: { projectId, projectArea, projectBuildings },
  }) => ({
    interaction,
    drawing,
    projectId,
    projectArea,
    projectBuildings,
    viewport,
  }),
  {
    getParcelleInfo,
    showHoveredInfo,
    cleanHoveredInfo,
    editProjectArea,
    setBuildingPolygon,
    setDistanceValue,
    setSurfaceValue,
    deleteBuilding,
    setBuilding,
    cleanBuilding,
    chooseAction,
  }
)(InteractionsContainer)
