import React, { Component } from 'react'

import { withStyles } from '@material-ui/core'

import { Dialog } from '@material-ui/core'

import demonstrationVideo from './BatirDemonstrationVideo.mp4'

const styles = theme => ({
  dialogPaper: { overflow: 'visible', maxWidth: '90vw', maxHeight: '100vh' },
})

class DemoVideo extends Component {
  handleClose = () => {
    this.props.onClose()
  }

  render() {
    const { classes, ...otherProps } = this.props
    return (
      <Dialog
        {...otherProps}
        fullWidth
        onClose={this.handleClose}
        // classes={{ paper: classes.dialogPaper }}
      >
        <video
          // height="900"
          width="100%"
          loop={true}
          autoPlay={true}
          muted={true}
          controls
        >
          <source src={demonstrationVideo} type="video/mp4" />
        </video>
      </Dialog>
    )
    // <BilanPromoteurForm {...otherProps} handleClose={this.handleClose} />
  }
}

export default withStyles(styles)(DemoVideo)
