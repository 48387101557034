import React from 'react'
import { CircularProgress, Typography, withStyles } from '@material-ui/core'

const styles = theme => ({
  div: {
    textAlign: 'center',
    margin: theme.spacing(4),
  },
})

const Loading = props => {
  return (
    <div className={props.classes.div}>
      <CircularProgress />
      <Typography>Récupération des données ...</Typography>
    </div>
  )
}

export default withStyles(styles)(Loading)
