import getHoveredElmts from './hoveredElmts'
import { customMapControlsEventBlocker } from './eventBlock'

export default function() {
  const { editProjectArea, projectId } = this.props
  const cleanHoveredInfo = this.props.cleanHoveredInfo

  return {
    onClick: e => {
      const parcelle = e.features
        ? e.features.find(f => f.layer.id === 'cadastre_parcelles_fill')
        : null

      if (parcelle && customMapControlsEventBlocker(e.target.className)) {
        editProjectArea({
          parcelleId: parcelle.properties.parcelleId,
          projectId,
        })
      }
    },
    onHover: e => {
      const parcelle = e.features
        ? e.features.find(f => f.layer.id === 'cadastre_parcelles_fill')
        : null

      if (parcelle) {
        this.setState({
          hoveredParcelleId: parcelle.properties.parcelleId,
        })
      } else {
        this.setState({
          hoveredParcelleId: null,
        })
      }
      getHoveredElmts(e, this.props)
    },
    onMouseOut: e => {
      // lorsque la souris sort du canevas de la carte
      this.setState({
        hoveredParcelleId: null,
      })
    },
    onMouseLeave: e => {
      cleanHoveredInfo()
    },
  }
}
