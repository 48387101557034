import React, { Component } from 'react'
import { CanvasOverlay } from 'react-map-gl'
import isEqual from 'react-fast-compare'
import EditButtonsContainer from './EditButtonsContainer'
import SnappedPoint from './SnappedPoint'
import EditDialogContainer from './EditDialogContainer'
import BuildingHeightDialogContainer from './BuildingHeightDialogContainer'
import BuildingBufferDialogContainer from './BuildingBufferDialogContainer'

class DrawOverlay extends Component {
  redraw = ({ width, height, ctx, isDragging, project, unproject }) => {
    const { clickLocations, mouseLocation, editedNode } = this.props

    // nettoyage du canevas
    ctx.clearRect(0, 0, width, height)

    // style du trait
    ctx.lineWidth = 2
    ctx.strokeStyle = 'red'

    if (!isDragging && clickLocations.length > 0) {
      const pixels = clickLocations.map(location => project(location))

      if (editedNode.length > 0) {
        // lors de l'édition d'un point, on substitue le sommet modifié par la position du pointeur
        if (clickLocations.indexOf(editedNode) === 0) {
          // le sommet sélectionné est le premier (et donc dernier point) du polygone
          pixels[0] = project(mouseLocation)
          pixels[clickLocations.length - 1] = pixels[0]
        } else {
          pixels[clickLocations.indexOf(editedNode)] = project(mouseLocation)
        }
      }

      ctx.beginPath()
      for (const pixel of pixels) {
        ctx.lineTo(pixel[0], pixel[1])
      }

      // test d'accrochage si le pointeur de la souris contient des coordonnées et que la ligne formée par clickLocations n'est pas fermée
      if (
        mouseLocation.length > 0 &&
        (clickLocations.length === 1 ||
          !isEqual(
            clickLocations[clickLocations.length - 1],
            clickLocations[0]
          ))
      ) {
        const mousePixel = project(mouseLocation)
        ctx.lineTo(mousePixel[0], mousePixel[1])
      }

      ctx.stroke()
    }
  }

  render() {
    return (
      <>
        <SnappedPoint point={this.props.snappedPoint} />
        <CanvasOverlay redraw={this.redraw} />
        <EditButtonsContainer
          pixelBBox={this.props.pixelBBox}
          handleEditionMode={this.props.handleEditionMode}
        />
        <EditDialogContainer
          selectingNode={this.props.editedNode.length === 0}
          clickLocations={this.props.clickLocations}
          handlePolygonEdition={this.props.handlePolygonEdition}
        />
        <BuildingHeightDialogContainer />
        <BuildingBufferDialogContainer />
      </>
    )
  }
}

export default DrawOverlay
