import React, { Component } from 'react'
import { connect } from 'react-redux'
import InteractionButtonsComponent from './InteractionButtonsComponent'
import { changeInteraction } from '../../../store/map/mapReducer'

class InteractionButtonsContainer extends Component {
  render() {
    return <InteractionButtonsComponent {...this.props} />
  }
}

const mapStateToProps = ({ map }) => ({
  interaction: map.interaction,
})

export default connect(
  mapStateToProps,
  { changeInteraction }
)(InteractionButtonsContainer)
