import React from 'react'
import classNames from 'classnames'

import TopBar from '../../common/components/TopBar'
import FailedPopup from '../../common/components/FailedPopup'
import { withStyles } from '@material-ui/core'
import LeftPanel from './LeftPanel/LeftPanel'
import LegendPanel from './LegendPanel/LegendPanel'

import FeatureInfoContainer from './FeatureInfo/FeatureInfoContainer'
import MapToolTipsContainer from './MapToolTips/MapToolTipsContainer'
import InteractionsContainer from './Interactions/InteractionsContainer'
import ActionsMenu from './ActionsMenu'
import Search from './Search'
import PluAvancement from './PluAvancement'
import PluReglement from './PluReglement'
import PluShape from './PluShape'
import CharteProm from './CharteProm'

import CalibrationConfig from './CalibrationConfig'
import SimulationResult from './SimulationResult'
import RulesConfig from './RulesConfig'
import GenerateWindow from './GenerateWindow'
import ChargeFonciere from './ChargeFonciere'
import BilanPromoteur from './BilanPromoteur'

const styles = (theme) => ({
  mapPane: {
    flex: 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  mapPaneLeftShift: {
    width: `calc(100% - ${280}px)`,
    marginLeft: 280,

    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  container: {
    display: 'flex',
    flexFlow: 'column',
    height: '100vh',
  },
})

const ProjectComponent = ({
  title,
  classes,
  openedLayerDrawer,
  handleDrawerToggle,
  handleMenuOpen,
  handleMenuClose,
  isMenuOpen,
  isSearchWindowOpen,
  isPluAvancementWindowOpen,
  isPluReglementWindowOpen,
  isPluShpWindowOpen,
  isChartePromWindowOpen,
  isCalibrationConfigWindowOpen,
  isSimulationResultWindowOpen,
  isRuleConfigWindowOpen,
  isGenerateWindowOpen,
  isChargeFonciereWindowOpen,
  isBilanPromoteurWindowOpen,
  logOut,
  anchorMenuEl,
  handleArrowBack,
  handleSearchWindow,
  handleGenerateWindow,
  getParcelleRules,
  handlePluAvancementWindow,
  handlePluReglementWindow,
  handleCalibrationConfigWindow,
  handleSimulationResultWindow,
  handleRuleConfigWindow,
  handleChargeFonciereWindow,
  handleBilanPromoteurWindow,
  exportToPdf,
  exportToDXF,
  handlePluToSHP,
  handleCommuneCharte,
  isValidProject,
  isPopupOpened,
  handleClosePopup,
  popupMessage,
}) => {
  return (
    <div className={classes.container}>
      <TopBar
        title={title}
        position="relative"
        handleDrawerToggle={handleDrawerToggle}
        LeftPanelIsOpened={openedLayerDrawer}
        handleArrowBack={handleArrowBack}
        handleMenuOpen={handleMenuOpen}
        handleSearchWindow={handleSearchWindow}
        handleChargeFonciereWindow={handleChargeFonciereWindow}
        handleBilanPromoteurWindow={handleBilanPromoteurWindow}
        logOut={() => logOut()}
      />

      <LeftPanel
        open={openedLayerDrawer}
        handleDrawerToggle={handleDrawerToggle}
      />
      <LegendPanel
        open={openedLayerDrawer}
        handleDrawerToggle={handleDrawerToggle}
      />
      <div
        className={classNames(classes.mapPane, {
          [classes.mapPaneLeftShift]: openedLayerDrawer,
        })}
      >
        <InteractionsContainer
          getParcelleRules={getParcelleRules}
          handleGenerateWindow={handleGenerateWindow}
        />
      </div>
      <FeatureInfoContainer handleRuleConfigWindow={handleRuleConfigWindow} />
      <ActionsMenu
        open={isMenuOpen}
        isValidProject={isValidProject}
        anchorEl={anchorMenuEl}
        handleClose={handleMenuClose}
        handleCalibrationConfigWindow={handleCalibrationConfigWindow}
        handleSimulationResultWindow={handleSimulationResultWindow}
        handlePluAvancementWindow={handlePluAvancementWindow}
        handlePluReglementWindow={handlePluReglementWindow}
        exportToPdf={exportToPdf}
        exportToDXF={exportToDXF}
        handlePluToSHP={handlePluToSHP}
        handleCommuneCharte={handleCommuneCharte}
      />
      <MapToolTipsContainer layerPanelsDisplayed={openedLayerDrawer} />
      <Search open={isSearchWindowOpen} onClose={handleSearchWindow} />
      <PluAvancement
        open={isPluAvancementWindowOpen}
        onClose={handlePluAvancementWindow}
      />

      <PluReglement
        open={isPluReglementWindowOpen}
        onClose={handlePluReglementWindow}
      />

      <PluShape open={isPluShpWindowOpen} onClose={handlePluToSHP} />

      <CharteProm open={isChartePromWindowOpen} onClose={handleCommuneCharte} />

      <ChargeFonciere
        open={isChargeFonciereWindowOpen}
        onClose={handleChargeFonciereWindow}
      />
      <BilanPromoteur
        open={isBilanPromoteurWindowOpen}
        onClose={handleBilanPromoteurWindow}
      />

      <CalibrationConfig
        open={isCalibrationConfigWindowOpen}
        onClose={handleCalibrationConfigWindow}
      />
      <SimulationResult
        open={isSimulationResultWindowOpen}
        onClose={handleSimulationResultWindow}
      />
      <RulesConfig
        open={isRuleConfigWindowOpen}
        onClose={handleRuleConfigWindow}
      />
      <FailedPopup
        open={isPopupOpened}
        handleClose={handleClosePopup}
        message={popupMessage}
      />
      <GenerateWindow
        disableBackdropClick={true}
        open={isGenerateWindowOpen}
        onClose={handleGenerateWindow}
      />
    </div>
  )
}

export default withStyles(styles)(ProjectComponent)
