import info from './infoEventHandler'
import select from './selectEventHandler'
import create from './createEventHandler'
import edit from './editEventHandler'
import generate from './generateEventHandler'
import measure from './measureEventHandler'
import none from './noneEventHandler'

export default {
  measure,
  info,
  select,
  create,
  edit,
  generate,
  none,
}
