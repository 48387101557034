import React from 'react'
// import PropTypes from 'prop-types'

import { NumberFormatCustom } from '../../../common/components/NumberFormatter'
import Loading from '../../../common/components/Loading'

import {
  Button,
  TextField,
  Card,
  CardContent,
  Grid,
  CardHeader,
  CardActions,
  withStyles,
  Dialog,
  Typography,
  InputAdornment,
  Divider,
  Collapse,
  IconButton,
  Switch,
  Tooltip,
} from '@material-ui/core'
import Clear from '@material-ui/icons/Clear'

import SurfaceCalculatorLogement from './SurfaceCalculatorLogement'
import SurfaceCalculatorBureaux from './SurfaceCalculatorBureaux'

// import FilterContent from '../LeftPanel/FilterContent'

const styles = theme => ({
  dialogPaper: { overflow: 'visible', maxWidth: '90vw', maxHeight: '100vh' },
  card: {
    overflowY: 'scroll',
    transitionDuration: '1s',
  },
  cardHeader: {
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  cardHeaderTitle: {
    color: theme.palette.primary.contrastText,
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  message: {
    marginTop: theme.spacing(2),
  },
  button: {
    backgroundColor: 'rgb(0,0,0)',
  },
})

const GenerateForm = ({
  classes,
  onClose,
  inputs,
  handleSubmit,
  handleChange,
  gabaritConfig,
  errorMessage,
  generateBuildMsg,
  loading,
  isValidForm,
  isValidHauteur,
  isValidEmprise,
  isValidHParNiveau,
  isCoherentHauteurs,
  isSommeSurfLogValid,
  isSommeSurfBurValid,
  aireParcel,
  surfParEtage,
  sommeSurfLog,
  sommeSurfBur,
  sommeNbStation,
  bati_is_log,
  switchLogementBureaux,
  setTheoricValues,
  ruleHelpers,
  ...otherProps
}) => {
  return (
    <Dialog {...otherProps} fullWidth classes={{ paper: classes.dialogPaper }}>
      <Card className={classes.card}>
        <CardHeader
          color="primary"
          classes={{
            root: classes.cardHeader,
            title: classes.cardHeaderTitle,
          }}
          title={
            <Typography variant="h5">
              <u>
                <b>{'Génération du gabarit'}</b>
              </u>
              <span
                className="w3-text-deep-orange"
                style={{ marginLeft: '4em' }}
              >
                {'Bureau '}
              </span>
              <Switch checked={bati_is_log} onChange={switchLogementBureaux} />
              <span className="w3-text-indigo">{' Logement'}</span>
              <Tooltip title="Definir la hauteur et l'emprise maximales autorisées">
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  className={classes.button}
                  value={bati_is_log}
                  style={{ marginLeft: 15 }}
                  onClick={setTheoricValues}
                >
                  Parametrage théorique
                </Button>
              </Tooltip>
            </Typography>
          }
          action={
            <IconButton className="w3-red" color="inherit" onClick={onClose}>
              <Clear />
            </IconButton>
          }
        />
        <CardContent>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                  <TextField
                    color="secondary"
                    fullWidth
                    helperText={ruleHelpers().helpHauteur}
                    label="Hauteur du bâti "
                    value={inputs.hauteur}
                    name="hauteur"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m</InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom,
                    }}
                    onChange={handleChange('hauteur')}
                    error={!(isValidHauteur() && isCoherentHauteurs())}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    color="secondary"
                    fullWidth
                    helperText={ruleHelpers().helpEmprise}
                    label="Emprise au sol du bâti "
                    value={inputs.emprise}
                    name="emprise"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom,
                    }}
                    onChange={handleChange('emprise')}
                    error={!isValidEmprise()}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    color="secondary"
                    fullWidth
                    label="Hauteur par niveaux (2 à 6 max)"
                    value={inputs.hParNiveau}
                    name="hParNiveau"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m</InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom,
                    }}
                    onChange={handleChange('hParNiveau')}
                    error={!isCoherentHauteurs() || !isValidHParNiveau()}
                  />
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={4}>
                  {isValidForm() && (
                    <Typography variant="h6" align="center">
                      {'Surface maximale du gabarit : ' +
                        surfParEtage() +
                        ' m²'}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4} />
              </Grid>
              <Divider
                style={{
                  margin: '0.5em',
                }}
              />
              {errorMessage ? (
                <Typography
                  style={{ whiteSpace: 'pre-line' }}
                  className={classes.message}
                  align="center"
                  color="error"
                >
                  {errorMessage}
                </Typography>
              ) : null}
              <Collapse in={surfParEtage() > 0 && isValidForm()}>
                {bati_is_log ? (
                  <SurfaceCalculatorLogement
                    handleChange={handleChange}
                    inputs={inputs}
                    surfParEtage={surfParEtage()}
                    sommeSurfLog={sommeSurfLog()}
                    sommeNbStation={sommeNbStation()}
                    isSommeSurfLogValid={isSommeSurfLogValid()}
                    aireParcel={aireParcel}
                  />
                ) : (
                  <SurfaceCalculatorBureaux
                    handleChange={handleChange}
                    inputs={inputs}
                    surfParEtage={surfParEtage()}
                    sommeSurfBur={sommeSurfBur()}
                    isSommeSurfBurValid={isSommeSurfBurValid()}
                    aireParcel={aireParcel}
                  />
                )}
              </Collapse>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography
                    className="w3-blue w3-padding-small w3-margin-right"
                    variant="h6"
                    align="center"
                  >
                    {'Superficie de la parcelle : ' + aireParcel + ' m²'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className="w3-blue w3-padding-small w3-margin-left"
                    variant="h6"
                    align="center"
                  >
                    {'Nombre de niveaux : ' +
                      parseFloat(inputs.hauteur / inputs.hParNiveau).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </CardContent>
        <CardActions classes={{ root: classes.cardActions }}>
          {!loading && (
            <Collapse in={surfParEtage() > 0 && isValidForm()}>
              {generateBuildMsg ? (
                <Typography
                  className={classes.message}
                  align="center"
                  color="primary"
                >
                  {generateBuildMsg}
                </Typography>
              ) : null}

              <Button
                onClick={handleSubmit}
                variant="contained"
                color="secondary"
                className={classes.button}
                // disabled={true}
                disabled={!isSommeSurfLogValid()}
              >
                Générer le bâti
              </Button>
            </Collapse>
          )}
        </CardActions>
      </Card>
    </Dialog>
  )
}

GenerateForm.defaultProps = {
  inputs: {},
}

// SearchForm.propTypes = {
//   inputs: PropTypes.shape({
//     commune: PropTypes.string,
//     section: PropTypes.string,
//     numero: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   }).isRequired,
//   handleSubmit: PropTypes.func,
// }

export default withStyles(styles)(GenerateForm)
