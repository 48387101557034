import createDuck from '../createDuck'

const { reducer: startSessionReducer, startSession } = createDuck({
  namespace: 'batir/user',
  actionName: 'startSession',
  path: process.env.REACT_APP_API_PATH + 'common/startSession.php',
})

export default startSessionReducer
export { startSession }
