import React, { Component } from 'react'
import { connect } from 'react-redux'

import { NumberFormatCustom } from '../../../common/components/NumberFormatter'

import {
  withStyles,
  Dialog,
  DialogTitle,
  TextField,
  Button,
  DialogContent,
  DialogActions,
} from '@material-ui/core'

import {
  setBuildingHeight,
  cleanBuilding,
} from '../../../store/drawing/drawingReducer'

const styles = theme => ({
  button: {
    backgroundColor: 'rgb(0,0,0)',
  },
})

class BuildingHeightDialogContainer extends Component {
  state = {
    height: '',
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.open !== prevProps.open) {
      this.setState({ height: Number(this.props.height) })
    }
  }

  handleChange = e => {
    this.setState({ height: Number(e.target.value) })
  }

  render() {
    const height = this.state.height
    const { cleanBuilding, setBuildingHeight, open, classes } = this.props
    const isValidHeight = Boolean(height <= 500)
    return (
      <Dialog open={open}>
        <DialogTitle>Indiquez la hauteur du bâtiment </DialogTitle>
        <DialogContent>
          <TextField
            color="secondary"
            autoFocus
            fullWidth
            margin="dense"
            error={!/^([1-9][0-9.]*)$/.test(height) || !isValidHeight}
            label="hauteur (m) "
            value={height}
            onChange={this.handleChange}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={cleanBuilding}
          >
            Annuler
          </Button>
          <Button
            disabled={!/^([1-9][0-9.]*)$/.test(height) || !isValidHeight}
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={e => setBuildingHeight(height)}
          >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ drawing }) => {
  return {
    open:
      (drawing.polygon && drawing.polygon.length > 0 && !drawing.buildingId) ||
      (drawing.action && drawing.action === 'changeHeight')
        ? true
        : false,

    height: drawing.height,
  }
}

export default connect(mapStateToProps, { setBuildingHeight, cleanBuilding })(
  withStyles(styles)(BuildingHeightDialogContainer)
)
