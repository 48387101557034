const exes_emprise = [
  'round',
  [
    '-', // on soustrait l'aire par (aire * emp_lim/100)
    ['get', 'area'],
    ['*', ['get', 'aire_par'], ['/', ['get', 'emp_lim'], 100]], // multiplicaction
  ],
]

const exes_hauteur = ['round', ['-', ['get', 'height'], ['get', 'h_lim']]]

export default {
  version: 8,
  name: 'Mapbox Basic Template',
  metadata: {
    'mapbox:origin': 'basic-template',
    'mapbox:autocomposite': true,
    'mapbox:type': 'template',
    'mapbox:sdk-support': {
      js: '0.54.0',
      android: '7.4.0',
      ios: '4.11.0',
    },
    'mapbox:groups': {},
  },
  center: [2.4582123858610885, 48.815141084218055],
  zoom: 16.188729594588683,
  bearing: -0.8273443205366675,
  pitch: 28.99999999999997,
  sprite:
    'mapbox://sprites/batir/ck3a7hh1p0uzy1cmzny8y4522/1usw70vkg7e0bye9r11c1kzta',
  glyphs: 'mapbox://fonts/batir/{fontstack}/{range}.pbf',
  layers: [
    {
      id: 'background',
      type: 'background',
      layout: {},
      paint: { 'background-color': 'hsl(36, 0%, 89%)' },
    },

    {
      id: 'national_park',
      type: 'fill',
      source: 'composite',
      'source-layer': 'landuse_overlay',
      minzoom: 5,
      filter: ['==', ['get', 'class'], 'national_park'],
      layout: {},
      paint: {
        'fill-color': 'hsl(100, 85%, 22%)',
        'fill-opacity': ['interpolate', ['linear'], ['zoom'], 5, 0, 6, 1],
        'fill-outline-color': 'hsl(113, 8%, 13%)',
      },
    },
    {
      id: 'landuse',
      type: 'fill',
      source: 'composite',
      'source-layer': 'landuse',
      minzoom: 5,
      filter: [
        'match',
        ['get', 'class'],
        ['airport', 'hospital', 'park', 'pitch', 'school'],
        true,
        false,
      ],
      layout: {},
      paint: {
        'fill-color': [
          'match',
          ['get', 'class'],
          ['airport'],
          'hsl(345, 5%, 44%)',
          ['hospital'],
          'hsl(0, 54%, 60%)',
          ['park', 'pitch'],
          'hsl(78, 55%, 54%)',
          ['school'],
          'hsl(25, 5%, 55%)',
          'hsla(0, 0%, 0%, 0)',
        ],
        'fill-opacity': ['interpolate', ['linear'], ['zoom'], 5, 0, 6, 1],
        'fill-outline-color': 'hsl(0, 6%, 1%)',
      },
    },

    {
      id: 'plu_zonage',
      type: 'fill',
      source: 'static_data',
      'source-layer': 'plu_zonage',
      minzoom: 3,
      maxzoom: 24,
      layout: {},
      paint: {
        'fill-color': [
          'match',
          ['get', 'typezone'],
          ['U'],
          'rgb(255, 193, 7)',
          ['Ua'],
          'rgb(240, 213, 220)',
          ['Ub'],
          'rgb(184, 144, 203)',
          ['Uc'],
          'rgb(255, 222, 133)',
          ['Ud'],
          'rgb(128, 194, 194)',
          ['Ue'],
          'rgb(111, 163, 174)',
          ['AU', 'AU*'],
          'rgb(212, 94, 96)',
          ['1AU', 'IAU'],
          'rgb(206, 166, 120)',
          ['2AU', 'IIAU'],
          'rgb(124, 94, 60)',
          ['A'],
          'rgb(255, 235, 148)',
          ['N'],
          'rgb(205, 220, 57)',
          /* Autres */ 'hsl(0, 0%, 100%)',
        ],
        'fill-opacity': ['interpolate', ['linear'], ['zoom'], 9, 0, 10, 1],
      },
    },

    {
      id: 'waterway',
      type: 'line',
      source: 'composite',
      'source-layer': 'waterway',
      minzoom: 8,
      filter: [
        'all',
        ['==', ['geometry-type'], 'LineString'],
        ['match', ['get', 'class'], ['canal', 'river'], true, false],
      ],
      layout: { 'line-join': 'round', 'line-cap': 'round' },
      paint: {
        'line-color': 'hsl(205, 76%, 70%)',
        'line-width': [
          'interpolate',
          ['exponential', 1.3],
          ['zoom'],
          8.5,
          0.1,
          20,
          8,
        ],
        'line-opacity': ['interpolate', ['linear'], ['zoom'], 8, 0, 8.5, 1],
      },
    },
    {
      id: 'water',
      type: 'fill-extrusion',
      source: 'composite',
      'source-layer': 'water',
      layout: {},
      paint: {
        'fill-extrusion-color': 'hsl(220, 91%, 45%)',
        'fill-extrusion-height': 0.2,
      },
    },
    {
      id: 'aeroway-polygon',
      type: 'fill',
      source: 'composite',
      'source-layer': 'aeroway',
      minzoom: 10,
      filter: [
        'all',
        ['==', ['geometry-type'], 'Polygon'],
        [
          'match',
          ['get', 'type'],
          ['helipad', 'runway', 'taxiway'],
          true,
          false,
        ],
      ],
      layout: {},
      paint: { 'fill-color': 'hsl(0, 2%, 63%)' },
    },

    {
      id: 'satellite',
      type: 'raster',
      source: 'mapbox://mapbox.satellite',
      maxzoom: 19,
      layout: { visibility: 'none' },
      paint: {},
    },

    {
      id: 'parcelles_retrait',
      type: 'line',
      source: 'static_retrait',
      'source-layer': 'parcelles_retrait',
      minzoom: 15,
      maxzoom: 24,
      layout: {},
      paint: {
        'line-color': 'hsla(0, 100%, 50%, 1)',
        'line-width': 3,
        'line-dasharray': [1, 1],
      },
    },

    {
      id: 'cadastre_parcelles_fill',
      type: 'fill',
      source: 'static_parcelles',
      'source-layer': 'cadastre_parcelles',
      minzoom: 15,
      maxzoom: 24,
      layout: {},
      paint: {
        'fill-color': 'hsla(0, 0%, 0%, 0)',
      },
    },
    {
      id: 'cadastre_parcelles_selected',
      type: 'fill',
      source: 'static_parcelles',
      'source-layer': 'cadastre_parcelles',
      minzoom: 15,
      maxzoom: 24,
      layout: {},
      filter: ['==', 'parcelleId', ''],
      paint: {
        'fill-color': 'hsla(200, 100%, 50%, 0.6)',
      },
    },
    {
      id: 'projects_buildings_fill',
      type: 'fill',
      source: 'projects_buildings',
      minzoom: 15,
      layout: {},
      paint: {
        'fill-color': 'hsla(0, 100%, 41%, 0.2)',
      },
    },
    {
      id: 'projects_buildings_selected',
      type: 'fill',
      source: 'projects_buildings',
      minzoom: 15,
      layout: {},
      filter: ['==', '$id', ''],
      paint: {
        'fill-color': 'hsla(0, 100%, 41%, 0.6)',
      },
    },

    {
      id: 'aeroway-line',
      type: 'line',
      source: 'composite',
      'source-layer': 'aeroway',
      minzoom: 9,
      filter: [
        'all',
        ['==', ['geometry-type'], 'LineString'],
        ['match', ['get', 'type'], ['runway', 'taxiway'], true, false],
      ],
      layout: {},
      paint: {
        'line-width': [
          'interpolate',
          ['exponential', 1.5],
          ['zoom'],
          10,
          0.5,
          18,
          20,
        ],
        'line-color': 'hsl(320, 75%, 19%)',
      },
    },

    {
      id: 'pedestrian-path',
      type: 'line',
      source: 'composite',
      'source-layer': 'road',
      minzoom: 14,
      filter: [
        'all',
        ['==', ['geometry-type'], 'LineString'],
        ['!=', ['get', 'type'], 'platform'],
        ['match', ['get', 'class'], ['path', 'pedestrian'], true, false],
      ],
      layout: { 'line-join': 'round', 'line-cap': 'round' },
      paint: {
        'line-width': [
          'interpolate',
          ['exponential', 1.5],
          ['zoom'],
          14,
          ['match', ['get', 'class'], 'pedestrian', 1, 0.75],
          20,
          ['match', ['get', 'class'], 'pedestrian', 8, 5],
        ],
        'line-color': [
          'match',
          ['get', 'type'],
          ['crossing', 'sidewalk'],
          'hsl(217, 88%, 40%)',
          'hsl(13, 6%, 44%)',
        ],
      },
    },

    {
      id: 'tunnel',
      type: 'line',
      source: 'composite',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', ['geometry-type'], 'LineString'],
        ['!=', ['get', 'type'], 'service:parking_aisle'],
        ['==', ['get', 'structure'], 'tunnel'],
        [
          'match',
          ['get', 'class'],
          [
            'motorway',
            'motorway_link',
            'trunk',
            'trunk_link',
            'primary',
            'primary_link',
            'secondary',
            'secondary_link',
            'tertiary',
            'tertiary_link',
            'street',
            'street_limited',
            'service',
            'track',
          ],
          true,
          false,
        ],
      ],
      layout: { 'line-join': 'round' },
      paint: {
        'line-width': [
          'interpolate',
          ['exponential', 1.5],
          ['zoom'],
          5,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            0.5,
            'tertiary',
            0.01,
            0,
          ],
          12,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            3,
            ['secondary', 'tertiary'],
            2,
            ['motorway_link', 'trunk_link', 'street', 'street_limited'],
            0.5,
            0,
          ],
          18,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            30,
            ['secondary', 'tertiary'],
            24,
            ['motorway_link', 'trunk_link', 'street', 'street_limited'],
            12,
            10,
          ],
        ],
        'line-color': [
          'match',
          ['get', 'class'],
          [
            'primary_link',
            'secondary_link',
            'tertiary_link',
            'street',
            'street_limited',
            'service',
            'track',
          ],
          'hsl(30, 87%, 48%)',
          'hsl(0, 0%, 100%)',
        ],
        'line-dasharray': [0.2, 0.2],
      },
    },

    {
      id: 'road',
      type: 'line',
      source: 'composite',
      'source-layer': 'road',
      minzoom: 5,
      filter: [
        'all',
        ['==', ['geometry-type'], 'LineString'],
        ['!=', ['get', 'type'], 'service:parking_aisle'],
        ['match', ['get', 'structure'], ['bridge', 'tunnel'], false, true],
        [
          'match',
          ['get', 'class'],
          [
            'motorway',
            'motorway_link',
            'trunk',
            'trunk_link',
            'primary',
            'primary_link',
            'secondary',
            'secondary_link',
            'tertiary',
            'tertiary_link',
            'street',
            'street_limited',
            'service',
            'track',
          ],
          true,
          false,
        ],
      ],
      layout: { 'line-join': 'round', 'line-cap': 'round' },
      paint: {
        'line-width': [
          'interpolate',
          ['exponential', 1.5],
          ['zoom'],
          5,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            0.5,
            'tertiary',
            0.01,
            0,
          ],
          12,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            3,
            ['secondary', 'tertiary'],
            2,
            ['motorway_link', 'trunk_link', 'street', 'street_limited'],
            0.5,
            0,
          ],
          18,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            30,
            ['secondary', 'tertiary'],
            24,
            ['motorway_link', 'trunk_link', 'street', 'street_limited'],
            12,
            10,
          ],
        ],
        'line-color': [
          'match',
          ['get', 'class'],
          [
            'street',
            'street_limited',
            'service',
            'track',
            'secondary_link',
            'tertiary_link',
            'primary_link',
            'trunk_link',
          ],
          'hsl(38, 5%, 60%)',
          'hsl(0, 0%, 49%)',
        ],
        'line-gap-width': 1,
      },
    },
    {
      id: 'bridge-case',
      type: 'line',
      source: 'composite',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', ['geometry-type'], 'LineString'],
        ['!=', ['get', 'type'], 'service:parking_aisle'],
        ['==', ['get', 'structure'], 'bridge'],
        [
          'match',
          ['get', 'class'],
          [
            'motorway',
            'motorway_link',
            'trunk',
            'trunk_link',
            'primary',
            'primary_link',
            'secondary',
            'secondary_link',
            'tertiary',
            'tertiary_link',
            'street',
            'street_limited',
            'service',
            'track',
          ],
          true,
          false,
        ],
      ],
      layout: { 'line-join': 'round' },
      paint: {
        'line-width': [
          'interpolate',
          ['exponential', 1.5],
          ['zoom'],
          10,
          1,
          16,
          2,
        ],
        'line-color': 'hsl(17, 93%, 41%)',
        'line-gap-width': [
          'interpolate',
          ['exponential', 1.5],
          ['zoom'],
          5,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            0.5,
            'tertiary',
            0.01,
            0,
          ],
          12,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            3,
            ['secondary', 'tertiary'],
            2,
            ['motorway_link', 'trunk_link', 'street', 'street_limited'],
            0.5,
            0,
          ],
          18,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            30,
            ['secondary', 'tertiary'],
            24,
            ['motorway_link', 'trunk_link', 'street', 'street_limited'],
            12,
            10,
          ],
        ],
      },
    },
    {
      id: 'bridge',
      type: 'line',
      source: 'composite',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', ['geometry-type'], 'LineString'],
        ['!=', ['get', 'type'], 'service:parking_aisle'],
        ['==', ['get', 'structure'], 'bridge'],
        [
          'match',
          ['get', 'class'],
          [
            'motorway',
            'motorway_link',
            'trunk',
            'trunk_link',
            'primary',
            'primary_link',
            'secondary',
            'secondary_link',
            'tertiary',
            'tertiary_link',
            'service',
            'street',
            'street_limited',
            'track',
          ],
          true,
          false,
        ],
      ],
      layout: { 'line-join': 'round', 'line-cap': 'round' },
      paint: {
        'line-width': [
          'interpolate',
          ['exponential', 1.5],
          ['zoom'],
          5,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            0.5,
            'tertiary',
            0.01,
            0,
          ],
          12,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            3,
            ['secondary', 'tertiary'],
            2,
            ['motorway_link', 'trunk_link', 'street', 'street_limited'],
            0.5,
            0,
          ],
          18,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            30,
            ['secondary', 'tertiary'],
            24,
            ['motorway_link', 'trunk_link', 'street', 'street_limited'],
            12,
            10,
          ],
        ],
        'line-color': [
          'match',
          ['get', 'class'],
          [
            'primary_link',
            'secondary_link',
            'tertiary_link',
            'street',
            'street_limited',
            'service',
            'track',
          ],
          'hsl(17, 85%, 60%)',
          'hsl(0, 0%, 100%)',
        ],
      },
    },

    {
      id: 'communes',
      type: 'line',
      source: 'static_data',
      'source-layer': 'communes',
      minzoom: 2,
      maxzoom: 24,
      paint: {
        'line-color': 'hsl(0, 0%, 30%)',
        'line-opacity': ['interpolate', ['linear'], ['zoom'], 9, 0, 10, 1],
        'line-width': ['step', ['zoom'], 1, 9, 2, 15, 4],
      },
    },

    {
      id: 'admin-state-province',
      type: 'line',
      source: 'composite',
      'source-layer': 'admin',
      minzoom: 2,
      filter: [
        'all',
        ['==', ['get', 'admin_level'], 1],
        ['==', ['get', 'disputed'], 'false'],
        ['==', ['get', 'maritime'], 'false'],
        ['match', ['get', 'worldview'], ['US', 'all'], true, false],
      ],
      layout: { 'line-join': 'round', 'line-cap': 'round' },
      paint: {
        'line-dasharray': [
          'step',
          ['zoom'],
          ['literal', [2, 0]],
          7,
          ['literal', [2, 2, 6, 2]],
        ],
        'line-width': ['interpolate', ['linear'], ['zoom'], 7, 0.75, 12, 1.5],
        'line-opacity': ['interpolate', ['linear'], ['zoom'], 2, 0, 3, 1],
        'line-color': [
          'step',
          ['zoom'],
          'hsl(10, 90%, 62%)',
          4,
          'hsl(0, 0%, 65%)',
        ],
      },
    },
    {
      id: 'admin-country',
      type: 'line',
      source: 'composite',
      'source-layer': 'admin',
      minzoom: 1,
      filter: [
        'all',
        ['==', ['get', 'admin_level'], 0],
        ['==', ['get', 'disputed'], 'false'],
        ['==', ['get', 'maritime'], 'false'],
        ['match', ['get', 'worldview'], ['US', 'all'], true, false],
      ],
      layout: { 'line-join': 'round', 'line-cap': 'round' },
      paint: {
        'line-color': 'hsl(0, 0%, 50%)',
        'line-width': ['interpolate', ['linear'], ['zoom'], 3, 0.5, 10, 2],
      },
    },
    {
      id: 'admin-country-disputed',
      type: 'line',
      source: 'composite',
      'source-layer': 'admin',
      minzoom: 1,
      filter: [
        'all',
        ['==', ['get', 'admin_level'], 0],
        ['==', ['get', 'disputed'], 'true'],
        ['==', ['get', 'maritime'], 'false'],
        ['match', ['get', 'worldview'], ['US', 'all'], true, false],
      ],
      layout: { 'line-join': 'round' },
      paint: {
        'line-color': 'hsl(0, 0%, 50%)',
        'line-width': ['interpolate', ['linear'], ['zoom'], 3, 0.5, 10, 2],
        'line-dasharray': [1.5, 1.5],
      },
    },

    {
      id: 'cadastre_parcelles',
      type: 'line',
      source: 'static_parcelles',
      'source-layer': 'cadastre_parcelles',
      minzoom: 15,
      maxzoom: 24,
      paint: {
        'line-color': 'hsl(0, 0%, 20%)',
        'line-opacity': ['interpolate', ['linear'], ['zoom'], 14, 0, 15, 1],
      },
    },
    {
      id: 'scot_layer',
      type: 'line',
      source: 'static_data',
      'source-layer': 'scot_layer',
      minzoom: 2,
      maxzoom: 24,
      paint: {
        'line-color': [
          'interpolate',
          ['linear'],
          ['get', 'code_scot'],
          0,
          'hsl(0, 100%, 45%)',
          10,
          'hsl(40, 100%, 30%)',
          20,
          'hsl(70, 100%, 30%)',
          30,
          'hsl(170, 100%, 30%)',
          40,
          'hsl(200, 100%, 30%)',
          50,
          'hsl(250, 100%, 30%)',
          60,
          'hsl(300, 100%, 30%)',
          70,
          'hsl(350, 100%, 150%)',
        ],
        'line-width': ['step', ['zoom'], 2, 9, 6, 24, 9],
      },
    },
    {
      id: 'zac_layer',
      type: 'fill',
      source: 'static_data',
      'source-layer': 'zac_layer',
      minzoom: 2,
      maxzoom: 24,
      paint: {
        'fill-color': 'hsl(226, 100%, 31%)',
        'fill-opacity': 0.8,
      },
    },
    {
      id: 'isohypse_idf',
      type: 'line',
      source: 'static_isohypse_idf',
      'source-layer': 'isohypse_idf',
      minzoom: 12,
      maxzoom: 24,
      paint: {
        'line-color': 'rgb(188, 156, 120)',
        'line-width': 5,
        // 'line-dasharray': [1, 1],
      },
    },
    {
      id: 'isohypse_idf_label',
      type: 'symbol',
      source: 'static_isohypse_idf',
      'source-layer': 'isohypse_idf',
      minzoom: 12,
      maxzoom: 24,
      paint: {
        'text-color': 'rgb(188, 156, 120)',
        'text-halo-color': 'rgb(255,255,255)',
        'text-halo-width': 2,
      },
      layout: {
        'symbol-placement': 'line',
        'text-field': ['concat', ['get', 'elev'], 'm'],
        'text-anchor': 'center',
        // 'symbol-spacing': 800,
        'text-letter-spacing': 0,
        'text-max-angle': 10,

        'text-font': ['Arial Unicode MS Bold'],
      },
    },

    {
      id: 'projects_parcelles',
      type: 'line',
      source: 'projects_parcelles',
      minzoom: 15,
      layout: {},
      paint: { 'line-color': 'hsla(250, 100%, 50%, 0.6)', 'line-width': 4 },
    },
    {
      id: 'projects_parcelles_label',
      type: 'symbol',
      source: 'projects_parcelles',
      minzoom: 15,
      layout: {
        'symbol-placement': 'line',
        'text-field': ['concat', ['get', 'aire'], 'm²'],
        'text-anchor': 'bottom',
        'symbol-spacing': 800,
        'text-letter-spacing': 0.5,
        'text-max-angle': 10,
      },
    },
    {
      id: 'projects_buildings',
      type: 'line',
      source: 'projects_buildings',
      minzoom: 15,
      layout: {},
      paint: { 'line-color': 'hsl(24, 100%, 50%)', 'line-width': 2 },
    },

    {
      id: 'buildingMoveTarget',
      type: 'line',
      source: 'buildingMoveTarget',
      minzoom: 15,
      layout: {},
      paint: { 'line-color': 'hsl(0, 100%, 50%)', 'line-width': 3 },
    },

    {
      id: 'projects_buildings_elevation', //on affiche la partie inferieur du bati projet en bleu
      type: 'fill-extrusion',
      source: 'projects_buildings',
      minzoom: 15,
      layout: {},
      paint: {
        'fill-extrusion-height': [
          'case',
          ['>=', ['get', 'h_lim'], ['get', 'height']],
          ['get', 'height'],
          [
            'all',
            ['>', ['get', 'h_lim'], 0],
            ['<=', ['get', 'h_lim'], ['get', 'height']],
          ],
          ['get', 'h_lim'],
          ['get', 'height'],
        ],
        'fill-extrusion-color': 'hsl(219, 100%, 52%)',
      },
    },
    {
      id: 'projects_buildings_sup', //si exces on ajout une partie superieur rouge
      type: 'fill-extrusion',
      source: 'projects_buildings',
      minzoom: 15,
      layout: {},
      filter: [
        // Rappel: filtre => on affiche que les entités qui respect la/les conditions
        'all',
        ['<', ['get', 'h_lim'], ['get', 'height']],
        ['!=', ['get', 'h_lim'], 0], //a mettre que si on considere qu'un limite de 0 n'est pas a prendre en compte
      ],
      paint: {
        'fill-extrusion-height': ['get', 'height'],
        'fill-extrusion-color': 'hsl(0, 100%, 52%)',
        'fill-extrusion-base': ['get', 'h_lim'],
        'fill-extrusion-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          15,
          0.5,
          16,
          1,
        ],
      },
    },
    {
      id: 'projects_buildings_floors', //on affiche les etagements en noir
      type: 'fill-extrusion',
      source: 'projects_buildings_floors',
      minzoom: 15,
      layout: {},
      paint: {
        'fill-extrusion-base': ['get', 'floorLevelInf'],
        'fill-extrusion-height': ['get', 'floorLevelSup'],
        'fill-extrusion-color': 'hsl(0, 0, 0)',
        'fill-extrusion-opacity': 1,
      },
    },

    {
      id: 'projects_buildings_labels', //texte sur centré de chaque bati projet en bleu.
      type: 'symbol',
      source: 'projects_buildings',
      minzoom: 18,
      layout: {
        'text-size': 14,
        'text-field': [
          'concat',
          'Nombre de niveau: ',
          ['get', 'nbniv'],
          '\nHauteur: ',
          ['get', 'height'],
          ' m \nEmprise: ',
          ['get', 'emp'],
          '%\n',
          [
            'case',
            ['==', ['get', 'emp_lim'], 0],
            "Pas de limite d'emprise ",
            [
              'case',
              ['>', exes_emprise, 0],
              ['concat', 'EXCÉDENT:', exes_emprise, 'm²'],
              'Emprise valide',
            ],
          ],
          '\n',
          [
            'case',
            ['==', ['get', 'h_lim'], 0],
            "Pas de limite d'hauteur",
            [
              'case',
              ['>', exes_hauteur, 0],
              ['concat', 'EXCÉDENT:', exes_hauteur, 'm'],
              'hauteur valide',
            ],
          ],
        ],
        'text-pitch-alignment': 'viewport',
      },
      paint: {
        'text-halo-color': 'hsl(0, 100%, 100%)',
        'text-halo-width': 2,
      },
    },

    {
      id: 'road-label',
      type: 'symbol',
      source: 'composite',
      'source-layer': 'road',
      minzoom: 12,
      filter: [
        'match',
        ['get', 'class'],
        [
          'motorway',
          'trunk',
          'primary',
          'secondary',
          'tertiary',
          'street',
          'street_limited',
          'pedestrian',
        ],
        true,
        false,
      ],
      layout: {
        'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
        'text-size': [
          'interpolate',
          ['linear'],
          ['zoom'],
          9,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary', 'secondary', 'tertiary'],
            40,
            15,
          ],
          20,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary', 'secondary', 'tertiary'],
            15,
            14,
          ],
        ],
        'text-max-angle': 30,
        'text-font': ['Roboto Regular', 'Arial Unicode MS Regular'],
        'symbol-placement': 'line',
        'text-padding': 1,
        'text-rotation-alignment': 'map',
        'text-pitch-alignment': 'viewport',
      },
      paint: {
        'text-color': 'hsl(28, 86%, 20%)',
        'text-halo-color': 'hsla(0, 1%, 100%, 0.95)',
        'text-halo-width': 1,
      },
    },

    {
      id: 'place-neighborhood-suburb-label',
      type: 'symbol',
      source: 'composite',
      'source-layer': 'place_label',
      minzoom: 11,
      maxzoom: 15,
      filter: [
        'all',
        ['<=', ['get', 'filterrank'], 3],
        [
          'match',
          ['get', 'type'],
          ['suburb', 'quarter', 'neighbourhood'],
          true,
          false,
        ],
      ],
      layout: {
        'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
        'text-transform': 'uppercase',
        'text-letter-spacing': 0.15,
        'text-max-width': 8,
        'text-font': ['Roboto Regular', 'Arial Unicode MS Regular'],
        'text-padding': 3,
        'text-size': ['interpolate', ['linear'], ['zoom'], 12, 11, 16, 16],
      },
      paint: {
        'text-halo-color': 'hsla(0, 0%, 100%, 0.95)',
        'text-halo-width': 1,
        'text-color': 'hsl(19, 93%, 43%)',
      },
    },

    {
      id: 'place-town-village-hamlet-label',
      type: 'symbol',
      source: 'composite',
      'source-layer': 'place_label',
      minzoom: 6,
      maxzoom: 14,
      filter: [
        'all',
        ['match', ['get', 'type'], ['town', 'village', 'hamlet'], true, false],
        ['<=', ['get', 'filterrank'], 3],
      ],
      layout: {
        'text-size': [
          'interpolate',
          ['linear'],
          ['zoom'],
          5,
          ['match', ['get', 'type'], 'town', 9.5, 8],
          16,
          ['match', ['get', 'type'], 'town', 20, 16],
        ],
        'text-font': [
          'step',
          ['zoom'],
          ['literal', ['Roboto Regular', 'Arial Unicode MS Regular']],
          12,
          [
            'match',
            ['get', 'type'],
            'town',
            ['literal', ['Roboto Medium', 'Arial Unicode MS Regular']],
            ['literal', ['Roboto Regular', 'Arial Unicode MS Regular']],
          ],
        ],
        'text-max-width': 7,
        'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
      },
      paint: {
        'text-color': 'hsl(0, 76%, 34%)',
        'text-halo-blur': 0.5,
        'text-halo-color': 'hsla(0, 0%, 100%, 0.95)',
        'text-halo-width': 1,
      },
    },

    {
      id: 'place-city-label',
      type: 'symbol',
      source: 'composite',
      'source-layer': 'place_label',
      minzoom: 3,
      maxzoom: 14,
      filter: [
        'all',
        ['<=', ['get', 'filterrank'], 3],
        ['==', ['get', 'type'], 'city'],
      ],
      layout: {
        'text-size': [
          'interpolate',
          ['linear'],
          ['zoom'],
          3,
          ['step', ['get', 'symbolrank'], 12, 9, 11, 12, 10, 14, 6.5],
          14,
          ['step', ['get', 'symbolrank'], 27, 9, 23, 10, 21, 12, 20],
        ],
        'text-font': [
          'step',
          ['zoom'],
          ['literal', ['Roboto Medium', 'Arial Unicode MS Regular']],
          10,
          [
            'step',
            ['get', 'symbolrank'],
            ['literal', ['Roboto Bold', 'Arial Unicode MS Bold']],
            5,
            ['literal', ['Roboto Medium', 'Arial Unicode MS Regular']],
          ],
        ],
        'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
      },
      paint: {
        'text-color': [
          'interpolate',
          ['linear'],
          ['zoom'],
          5,
          'hsl(30, 83%, 44%)',
          6,
          'hsl(30, 94%, 30%)',
        ],
        'text-halo-blur': 0.5,
        'text-halo-color': 'hsla(0, 0%, 100%, 0.95)',
        'text-halo-width': 1.25,
      },
    },

    {
      id: 'state-label',
      type: 'symbol',
      source: 'composite',
      'source-layer': 'place_label',
      minzoom: 4,
      maxzoom: 8,
      filter: ['==', ['get', 'type'], 'state'],
      layout: {
        'text-size': ['interpolate', ['linear'], ['zoom'], 4, 9.5, 9, 18],
        'icon-image': 'circle-15',
        'text-transform': 'uppercase',
        'text-font': ['Roboto Black', 'Arial Unicode MS Bold'],
        'text-padding': 1,
        'icon-size': 2,
        'text-field': [
          'step',
          ['zoom'],
          ['get', 'abbr'],
          5,
          ['coalesce', ['get', 'name_en'], ['get', 'name']],
        ],
        'text-letter-spacing': 0.2,
        'text-max-width': 6,
      },
      paint: {
        'text-color': 'hsl(213, 71%, 51%)',
        'text-halo-width': 1,
        'text-halo-color': 'hsla(0, 0%, 100%, 0.95)',
      },
    },
    {
      id: 'country-label',
      type: 'symbol',
      source: 'composite',
      'source-layer': 'place_label',
      minzoom: 3,
      maxzoom: 8,
      filter: ['==', ['get', 'type'], 'country'],
      layout: {
        'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
        'text-max-width': ['interpolate', ['linear'], ['zoom'], 0, 5, 3, 6],
        'text-font': [
          'step',
          ['zoom'],
          ['literal', ['Roboto Medium', 'Arial Unicode MS Regular']],
          4,
          ['literal', ['Roboto Bold', 'Arial Unicode MS Bold']],
        ],
        'text-size': [
          'interpolate',
          ['linear'],
          ['zoom'],
          1,
          ['step', ['get', 'symbolrank'], 12, 3, 10, 5, 9],
          9,
          ['step', ['get', 'symbolrank'], 35, 3, 27, 5, 22],
        ],
        'icon-image': 'circle-stroked-15',
        'icon-size': 2,
        'text-letter-spacing': 0.1,
      },
      paint: {
        'text-halo-width': 1.5,
        'text-color': 'hsl(30, 89%, 56%)',
        'text-halo-color': 'hsla(0, 0%, 100%, 0.95)',
      },
    },
    {
      id: 'bati_idf',
      type: 'fill-extrusion',
      source: 'static_bati_idf',
      'source-layer': 'bati_idf',
      minzoom: 15,
      maxzoom: 24,
      filter: ['==', '$type', 'Polygon'],
      layout: {},
      paint: {
        'fill-extrusion-color': '#aaa',
        'fill-extrusion-height': [
          'interpolate',
          ['linear'],
          ['zoom'],
          15,
          0,
          15.05,
          ['get', 'hauteur'],
        ],
        'fill-extrusion-opacity': 1,
      },
    },
    {
      id: 'dvf_idf_2014',
      type: 'symbol',
      source: 'static_dvf',
      'source-layer': 'dvf_idf_2014',
      minzoom: 13,
      maxzoom: 24,
      layout: {
        'icon-size': 0.7,
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-radial-offset': 1,
        'text-justify': 'auto',
        'icon-image': 'dvf2014',
      },
    },

    {
      id: 'dvf_idf_2015',
      type: 'symbol',
      source: 'static_dvf',
      'source-layer': 'dvf_idf_2015',
      minzoom: 13,
      maxzoom: 24,
      layout: {
        'icon-size': 0.7,
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-radial-offset': 1,
        'text-justify': 'auto',
        'icon-image': 'dvf2015',
      },
    },
    {
      id: 'dvf_idf_2016',
      type: 'symbol',
      source: 'static_dvf',
      'source-layer': 'dvf_idf_2016',
      minzoom: 13,
      maxzoom: 24,
      layout: {
        'icon-size': 0.7,
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-radial-offset': 1,
        'text-justify': 'auto',
        'icon-image': 'dvf2016',
      },
    },
    {
      id: 'dvf_idf_2017',
      type: 'symbol',
      source: 'static_dvf',
      'source-layer': 'dvf_idf_2017',
      minzoom: 13,
      maxzoom: 24,
      layout: {
        'icon-size': 0.7,
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-radial-offset': 1,
        'text-justify': 'auto',
        'icon-image': 'dvf2017',
      },
    },
    {
      id: 'dvf_idf_2018',
      type: 'symbol',
      source: 'static_dvf',
      'source-layer': 'dvf_idf_2018',
      minzoom: 13,
      maxzoom: 24,
      layout: {
        'icon-size': 0.7,
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-radial-offset': 1,
        'text-justify': 'auto',
        'icon-image': 'dvf2018',
      },
    },

    {
      id: 'bpe',
      type: 'symbol',
      source: 'static_bpe',
      'source-layer': 'bpe',
      minzoom: 13,
      maxzoom: 24,
      layout: {
        'icon-image': [
          'match',
          ['get', 'categorie'],
          ['A'],
          'services',
          ['B'],
          'commerces',
          ['C'],
          'enseignement',
          ['D'],
          'sante',
          ['E'],
          'transports',
          'loisirs',
        ],
        'icon-size': 0.7,
        // 'circle-radius': 3,
        // 'circle-stroke-width': 2,
        // 'circle-stroke-color': 'rgb(0,0,0)',
      },
    },

    {
      id: 'metro_idf',
      type: 'symbol',
      source: 'static_reseaux_ferre_idf',
      'source-layer': 'reseaux_ferre_idf',
      minzoom: 9,
      maxzoom: 24,
      filter: ['get', 'metro'],
      layout: {
        'icon-size': 0.7,
        'icon-image': 'metro',
      },
    },

    {
      id: 'gare_idf',
      type: 'symbol',
      source: 'static_reseaux_ferre_idf',
      'source-layer': 'reseaux_ferre_idf',
      minzoom: 9,
      maxzoom: 24,
      filter: ['any', ['get', 'rer'], ['get', 'train']],
      layout: {
        'icon-size': 0.7,
        'icon-image': 'train',
      },
    },

    {
      id: 'permis_idf',
      type: 'circle',
      source: 'static_permis_idf',
      'source-layer': 'permis_idf',
      minzoom: 9,
      maxzoom: 24,
      paint: {
        // 'icon-size': 0.7,
        // 'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        // 'text-radial-offset': 1,
        // 'text-justify': 'auto',
        // 'icon-image': 'dvf2014',
        'circle-radius': 5,
        // 'circle-stroke-width': 2,
        'circle-color': [
          'match',
          ['get', 'type_projet'],
          'locaux',
          'rgb(0,0,255)',
          'logements',
          'rgb(255,0,160)',

          'rgb(0,0,0)',
        ],
      },
    },

    {
      id: 'idfContour',
      type: 'line',
      source: 'idfContour',
      // minzoom: 15,
      layout: {},
      paint: {
        'line-color': 'hsl(0, 100%, 0%)',
      },
    },

    {
      id: 'poi-label',
      type: 'symbol',
      source: 'composite',
      'source-layer': 'poi_label',
      minzoom: 6,
      filter: ['<=', ['get', 'filterrank'], 3],
      layout: {
        'text-line-height': 1.1,
        'text-size': ['interpolate', ['linear'], ['zoom'], 10, 11, 18, 13],
        'icon-image': ['concat', ['get', 'maki'], '-11'],
        'text-max-angle': 38,
        'text-font': ['Roboto Regular', 'Arial Unicode MS Regular'],
        'text-offset': [0, 0.75],
        'text-anchor': 'top',
        'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
        'text-max-width': 8,
      },
      paint: {
        'text-color': 'hsl(38, 19%, 29%)',
        'text-halo-color': 'hsla(0, 0%, 100%, 0.75)',
        'text-halo-width': 1,
        'text-halo-blur': 0.5,
      },
    },

    {
      id: 'airport-label',
      type: 'symbol',
      source: 'composite',
      'source-layer': 'airport_label',
      minzoom: 8,
      layout: {
        'text-line-height': 1.1,
        'text-size': ['interpolate', ['linear'], ['zoom'], 10, 12, 18, 18],
        'icon-image': [
          'step',
          ['zoom'],
          ['concat', ['get', 'maki'], '-11'],
          13,
          ['concat', ['get', 'maki'], '-15'],
        ],
        'text-font': ['Roboto Regular', 'Arial Unicode MS Regular'],
        'text-offset': [0, 0.75],
        'text-anchor': 'top',
        'text-field': [
          'step',
          ['zoom'],
          ['get', 'ref'],
          14,
          ['coalesce', ['get', 'name_en'], ['get', 'name']],
        ],
        'text-max-width': 9,
      },
      paint: {
        'text-color': 'hsl(38, 19%, 29%)',
        'text-halo-color': 'hsla(0, 0%, 100%, 0.95)',
        'text-halo-width': 1,
      },
    },

    //----

    {
      id: 'services_public',
      type: 'symbol',
      source: 'static_services_public',
      'source-layer': 'services_public',
      minzoom: 9,
      maxzoom: 24,

      layout: {
        'icon-size': 0.7,
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-radial-offset': 1,
        'text-justify': 'auto',
        'icon-image': 'services_publics',
      },
    },

    {
      id: 'patri_imm_bati',
      type: 'fill',
      source: 'static_patri_imm_bati',
      'source-layer': 'patri_imm_bati',
      minzoom: 9,
      maxzoom: 24,
      paint: {
        'fill-color': 'rgba(255,0,0,0.5)',
      },
    },

    {
      id: 'patri_imm_zone',
      type: 'fill',
      source: 'static_patri_imm_zone',
      'source-layer': 'patri_imm_zone',
      minzoom: 9,
      maxzoom: 24,
      paint: {
        'fill-color': 'rgba(0,255,0,0.3)',
      },
    },

    {
      id: 'patri_mondial_bati',
      type: 'fill',
      source: 'static_patri_mondial_bati',
      'source-layer': 'patri_mondial_bati',
      minzoom: 9,
      maxzoom: 24,

      paint: {
        'fill-color': 'rgba(0,0,255,0.5)',
      },
    },

    {
      id: 'patri_mondial_zone',
      type: 'fill',
      source: 'static_patri_mondial_zone',
      'source-layer': 'patri_mondial_zone',
      minzoom: 9,
      maxzoom: 24,
      paint: {
        'fill-color': 'rgba(255,255,0,0.5)',
      },
    },

    {
      id: 'zones_log_abc_123',
      type: 'fill',
      source: 'static_zones_log_abc_123',
      'source-layer': 'zones_log_abc_123',
      minzoom: 7,
      maxzoom: 24,
      paint: {
        'fill-color': [
          'match',
          ['get', 'zone_abc'],
          'Abis',
          'rgba(255,255,0,0.5)',
          'A',
          'rgba(255,0,0,0.5)',
          'B1',
          'rgba(0,0,255,0.5)',
          'B2',
          'rgba(255,50,255,0.5)',
          'C',
          'rgba(0,255,0,0.5)',
          'rgba(0,0,0,0)',
        ],
        'fill-outline-color': 'rgba(150,150,150,0)',
      },
    },
    {
      id: 'zones_log_abc_123_label',
      type: 'symbol',
      source: 'static_zones_log_abc_123',
      'source-layer': 'zones_log_abc_123',
      minzoom: 7,
      maxzoom: 24,
      layout: {
        'symbol-placement': 'point',
        'text-field': ['get', 'zone_123'],
        'text-padding': 50,
        'text-letter-spacing': 0.2,
        'text-size': 25,
      },
    },

    {
      id: 'perimetre_quartier_prioritaire',
      type: 'fill',
      source: 'static_perimetre_quartier_prioritaire',
      'source-layer': 'perimetre_quartier_prioritaire',
      minzoom: 7,
      maxzoom: 24,
      paint: {
        'fill-color': 'rgba(150,0,0,0.5)',
        'fill-outline-color': 'rgb(0,0,0)',
      },
    },

    {
      id: 'log_soc_idf',
      type: 'fill',
      source: 'static_log_soc_idf',
      'source-layer': 'log_soc_idf',
      minzoom: 7,
      maxzoom: 24,
      paint: {
        'fill-color': [
          'case',
          ['>', ['get', 'log_dens'], 10000],
          'rgba(1,108,89,0.8)',

          ['>=', ['get', 'log_dens'], 8000],
          'rgba(28,144,153,0.8)',
          ['>=', ['get', 'log_dens'], 4000],
          'rgba(103,169,207,0.8)',

          ['>=', ['get', 'log_dens'], 1000],
          'rgba(189,201,225,0.8)',
          ['>=', ['get', 'log_dens'], 0],
          'rgba(246,239,247,0.8)',

          'rgba(150,150,150,0)',
        ],
        'fill-outline-color': 'rgb(0,0,0)',
      },
    },

    {
      id: 'log_soc_idf_label',
      type: 'symbol',
      source: 'static_log_soc_idf',
      'source-layer': 'log_soc_idf',
      minzoom: 7,
      maxzoom: 24,
      layout: {
        'symbol-placement': 'point',
        'text-field': ['concat', ['get', 'log_dens'], ' par km²'],
        'text-padding': 50,
        'text-letter-spacing': 0.1,
        'text-size': 15,
        'text-font': ['Arial Unicode MS Bold'],
      },
    },

    {
      id: 'loyer_idf',
      type: 'fill',
      source: 'static_loyer_idf',
      'source-layer': 'loyer_idf',
      minzoom: 9,
      maxzoom: 24,
      paint: {
        'fill-color': [
          'case',
          ['>=', ['get', 'loyer_med_18'], 25],
          'rgba(255,0,0,0.8)',

          ['>=', ['get', 'loyer_med_18'], 20],
          'rgba(255,70,0,0.8)',

          ['>=', ['get', 'loyer_med_18'], 15],
          'rgba(255,120,0,0.8)',

          ['>=', ['get', 'loyer_med_18'], 10],
          'rgba(255,180,0,0.8)',

          ['>', ['get', 'loyer_med_18'], 0],
          'rgba(255,255,0,0.8)',

          'rgba(15,150,150,0)',
        ],
        'fill-outline-color': 'rgba(0,0,0,0.4)',
      },
    },

    {
      id: 'loyer_idf_label',
      type: 'symbol',
      source: 'static_loyer_idf',
      'source-layer': 'loyer_idf',
      minzoom: 9,
      maxzoom: 24,
      layout: {
        'symbol-placement': 'point',
        'text-field': ['concat', ['get', 'loyer_med_18'], '€/m²'],
        'text-padding': 50,
        'text-letter-spacing': 0.2,
        'text-size': 20,
        'text-font': ['Arial Unicode MS Bold'],
      },
      paint: {
        'text-color': 'rgb(255,255,255)',
        'text-halo-color': 'rgb(0,0,0)',
        'text-halo-width': 1,
      },
    },

    {
      id: 'plu_emprise_contour',
      type: 'fill',
      source: 'static_plu_emprise_type',
      'source-layer': 'plu_emprise_type',
      minzoom: 14,
      maxzoom: 24,
      layout: {},
      paint: {
        'fill-color': ['concat', 'hsl(', ['get', 'ordre'], '0, 100%, 50%)'],
        'fill-opacity': 0.4,
        'fill-outline-color': '#000000',
      },
    },
    {
      id: 'plu_emprise_label',
      type: 'symbol',
      source: 'static_plu_emprise_type',
      'source-layer': 'plu_emprise_type',
      minzoom: 14,
      maxzoom: 24,
      layout: {
        'symbol-placement': 'point',
        'text-field': ['get', 'emprise'],
        // 'text-anchor': 'bottom',
        'symbol-spacing': 2,
        // 'text-letter-spacing': 0.2,
        'text-size': 25,
        // 'text-max-angle': 10,
        // 'text-ignore-placement': true,
      },
    },

    {
      id: 'plu_hauteur_contour',
      type: 'fill',
      source: 'static_plu_hauteur_type',
      'source-layer': 'plu_hauteur_type',
      minzoom: 14,
      maxzoom: 24,
      layout: {},
      paint: {
        'fill-color': ['concat', 'hsl(', ['get', 'ordre'], '0, 100%, 50%)'],
        'fill-opacity': 0.4,
        'fill-outline-color': '#000000',
      },
    },
    {
      id: 'plu_hauteur_label',
      type: 'symbol',
      source: 'static_plu_hauteur_type',
      'source-layer': 'plu_hauteur_type',
      minzoom: 14,
      maxzoom: 24,
      layout: {
        'symbol-placement': 'point',
        'text-field': ['get', 'hauteur'],
        'symbol-spacing': 2,
        'text-size': 25,
      },
      paint: {
        'text-halo-width': 1.5,
      },
    },

    // Risques
    {
      id: 'pprn_zone_alea_idf',
      type: 'fill',
      source: 'static_pprn_zone_alea_idf',
      'source-layer': 'pprn_zone_alea_idf',
      minzoom: 9,
      maxzoom: 24,
      paint: {
        'fill-color': [
          'match',
          ['get', 'niv_alea'],
          '01',
          '#d8afd5',
          '02',
          '#a850ff',
          '04',
          '#a800e0',
          '06',
          '#4d0099',
          '99',
          '#c3c3c3',
          'rgba(0,0,0,0)',
        ],
        'fill-outline-color': [
          'match',
          ['get', 'coderisque'],
          '1100000',
          '#1e90ff',
          '1110000',
          '#d8bfd8',
          '1120000',
          '#e0ffff',
          '1140000',
          '#3cb371',
          '1200000',
          '#9932cc',
          '1210000',
          '#556b2f',
          '1211000',
          '#800080',
          '1223000',
          '#ffd700',
          '1230000',
          '#ff4500',
          '1240000',
          '#4169e1',
          '1270000',
          '#f08080',
          '1270001',
          '#32cd32',
          '1271000',
          '#a9a9a9',
          '1272000',
          '#e6e6fa',
          '9999999',
          '#708090',
          'rgba(0,0,0,0)',
        ],
      },
    },

    {
      id: 'pprn_perimetre_idf',
      type: 'fill',
      source: 'static_pprn_perimetre_idf',
      'source-layer': 'pprn_perimetre_idf',
      minzoom: 9,
      maxzoom: 24,

      paint: {
        'fill-color': [
          'match',
          ['get', 'cat'],
          '01',
          'rgba(0,0,0,0)',
          '02',
          'rgba(255,0,0,0.5)',
          '03',
          'rgba(0,0,0,0)',
          'rgba(0,0,0,0)',
        ],
        'fill-outline-color': [
          'match',
          ['get', 'cat'],
          '01',
          'rgba(255,69,0,1)',
          '02',
          'rgba(255,0,0,1)',
          '03',
          'rgba(0,0,255,1)',
          'rgba(0,0,0,0)',
        ],
      },
    },

    {
      id: 'pprn_zone_idf',
      type: 'fill',
      source: 'static_pprn_zone_idf',
      'source-layer': 'pprn_zone_idf',
      minzoom: 9,
      maxzoom: 24,

      paint: {
        'fill-color': [
          'match',
          ['get', 'code_reg'],
          '01',
          '#89d9e7',
          '02',
          '#0000ff',
          '03',
          '#ff6060',
          '04',
          '#e00000',
          '05',
          '#c993ff',
          '06',
          '#9a359b',
          '99',
          '#c3c3c3',
          'rgba(0,0,0,0)',
        ],
      },
    },

    {
      id: 'pprn_enjeu_idf',
      type: 'fill',
      source: 'static_pprn_enjeu_idf',
      'source-layer': 'pprn_enjeu_idf',
      minzoom: 9,
      maxzoom: 24,

      paint: {
        'fill-color': [
          'match',
          ['get', 'cat'],
          '01',
          '#bc1618',
          '02',
          '#ff7700',
          '03',
          '#b5d2ed',
          '04',
          '#94ca62',
          '05',
          '#793180',
          '06',
          '#374bb0',
          '07',
          '#aecf11',
          '99',
          '#454545',
          'rgba(0,0,0,0)',
        ],
      },
    },

    {
      id: 'pprt_zone_alea_idf',
      type: 'fill',
      source: 'static_pprt_zone_alea_idf',
      'source-layer': 'pprt_zone_alea_idf',
      minzoom: 9,
      maxzoom: 24,

      paint: {
        'fill-color': [
          'match',
          ['get', 'niv_alea'],
          '01',
          '#8fbc8f',
          '02',
          '#87cefa',
          '03',
          '#b0c4de',
          '04',
          '#f5deb3',
          '05',
          '#eedd82',
          '06',
          '#e9967a',
          '07',
          '#f08080',
          '99',
          '#c3c3c3',
          'rgba(0,0,0,0)',
        ],
        'fill-outline-color': [
          'match',
          ['get', 'coderisque'],
          '2100000',
          '#faebd7',
          '2110000',
          '#a52a2a',
          '2120000',
          '#ff8c00',
          '2130000',
          '#48d1cc',
          '9999999',
          '#708090',
          'rgba(0,0,0,0)',
        ],
      },
    },

    {
      id: 'pprt_perimetre_idf',
      type: 'fill',
      source: 'static_pprt_perimetre_idf',
      'source-layer': 'pprt_perimetre_idf',
      minzoom: 9,
      maxzoom: 24,

      paint: {
        'fill-color': [
          'match',
          ['get', 'cat'],
          '01',
          'rgba(0,0,0,0)',
          '02',
          'rgba(255,0,0,0.5)',
          '03',
          'rgba(0,0,0,0)',
          'rgba(0,0,0,0)',
        ],
        'fill-outline-color': [
          'match',
          ['get', 'cat'],
          '01',
          'rgba(255,69,0,1)',
          '02',
          'rgba(255,0,0,1)',
          '03',
          'rgba(0,0,255,1)',
          'rgba(0,0,0,0)',
        ],
      },
    },

    {
      id: 'pprt_zone_idf',
      type: 'fill',
      source: 'static_pprt_zone_idf',
      'source-layer': 'pprt_zone_idf',
      minzoom: 9,
      maxzoom: 24,

      paint: {
        'fill-color': [
          'match',
          ['get', 'code_reg'],
          '01',
          '#89d9e7',
          '02',
          '#0000ff',
          '03',
          '#ff6060',
          '04',
          '#e00000',
          '05',
          '#c993ff',
          '06',
          '#9a359b',
          '99',
          '#c3c3c3',
          'rgba(0,0,0,0)',
        ],
      },
    },

    {
      id: 'pprt_enjeu_idf',
      type: 'fill',
      source: 'static_pprt_enjeu_idf',
      'source-layer': 'pprt_enjeu_idf',
      minzoom: 9,
      maxzoom: 24,

      paint: {
        'fill-color': [
          'match',
          ['get', 'cat'],
          '01',
          '#bc1618',
          '02',
          '#ff7700',
          '03',
          '#b5d2ed',
          '04',
          '#94ca62',
          '05',
          '#793180',
          '06',
          '#374bb0',
          '07',
          '#aecf11',
          '99',
          '#454545',
          'rgba(0,0,0,0)',
        ],
      },
    },
    // Prix des terres agricoles
    {
      id: 'prix_terre_agricole_idf',
      type: 'fill',
      source: 'static_prix_terre_agricole_idf',
      'source-layer': 'prix_terre_agricole_idf',
      minzoom: 7,
      maxzoom: 24,
      paint: {
        'fill-color': 'rgba(255,112,0,0.7)',
      },
    },
    {
      id: 'prix_terre_agricole_idf_contour',
      type: 'line',
      source: 'static_prix_terre_agricole_idf',
      'source-layer': 'prix_terre_agricole_idf',
      minzoom: 7,
      maxzoom: 24,
      paint: {
        'line-width': 4,
        'line-color': 'hsl(0, 100%, 0%)',
      },
    },
    {
      id: 'prix_terre_agricole_idf_label',
      type: 'symbol',
      source: 'static_prix_terre_agricole_idf',
      'source-layer': 'prix_terre_agricole_idf',
      minzoom: 7,
      maxzoom: 24,
      layout: {
        'symbol-placement': 'point',
        'text-field': [
          'concat',
          ['get', 'lib'],
          '\nPrix Moyen: ',
          ['get', 'prix_moy'],
          ' €\nPrix Maximum: ',
          ['get', 'prix_max'],
          ' €\nPrix Minimun: ',
          ['get', 'prix_min'],
          ' €',
        ],
        // 'symbol-spacing': 300,
        'text-padding': 100,
        'text-size': 20,
      },
      paint: {
        'text-color': 'rgb(0,0,0)',
        'text-halo-color': 'rgb(255,255,255)',
        'text-halo-width': 2.5,
      },
    },

    // Secteur operationel

    {
      id: 'projet_secteur_operationnel_idf',
      type: 'fill',
      source: 'static_projet_secteur_operationnel_idf',
      'source-layer': 'projet_secteur_operationnel_idf',
      minzoom: 7,
      maxzoom: 24,
      paint: {
        'fill-color': [
          'match',
          ['get', 'etat_secteur'],
          "A l'étude",
          'rgba(233,143,90,0.7)',
          'Achevé',
          'rgba(32,188,79,0.7)',
          'En cours',
          'rgba(181,210,237,0.7)',
          'Sans objet',
          'rgba(150,150,150,0.7)',
          'rgba(0,0,0,0)',
        ],

        'fill-outline-color': 'rgba(150,150,150,0)',
      },
    },

    {
      id: 'projet_secteur_operationnel_idf_contour',
      type: 'line',
      source: 'static_projet_secteur_operationnel_idf',
      'source-layer': 'projet_secteur_operationnel_idf',
      minzoom: 7,
      maxzoom: 24,
      paint: {
        'line-width': 2.5,
        'line-color': 'hsl(0, 100%, 0%)',
      },
    },

    {
      id: 'projet_secteur_operationnel_idf_label',
      type: 'symbol',
      source: 'static_projet_secteur_operationnel_idf',
      'source-layer': 'projet_secteur_operationnel_idf',
      minzoom: 7,
      maxzoom: 24,
      layout: {
        'symbol-placement': 'point',
        'text-field': [
          'concat',
          ['get', 'lib'],
          '\nStatut : ',
          ['get', 'etat_secteur'],
        ],
        'symbol-spacing': 2,
        'text-size': 15,
      },
      paint: {
        'text-color': 'rgb(0,0,0)',
        'text-halo-color': 'rgb(255,255,255)',
        'text-halo-width': 2.5,
      },
    },

    // projets d'espaces bâti
    {
      id: 'projet_espace_bati_idf',
      type: 'fill',
      source: 'static_projet_espace_bati_idf',
      'source-layer': 'projet_espace_bati_idf',
      minzoom: 7,
      maxzoom: 24,
      paint: {
        'fill-color': [
          'match',
          ['get', 'etat_bati'],
          "A l'étude",
          'rgba(221,185,69,0.7)',
          'En travaux',
          'rgba(62,153,237,0.7)',
          'rgba(0,0,0,0)',
        ],
        'fill-outline-color': 'rgba(0,0,0,1)',
      },
    },

    {
      id: 'projet_espace_bati_idf_label',
      type: 'symbol',
      source: 'static_projet_espace_bati_idf',
      'source-layer': 'projet_espace_bati_idf',
      minzoom: 7,
      maxzoom: 24,
      layout: {
        'symbol-placement': 'point',
        'text-field': [
          'concat',
          ['get', 'nature_projet'],
          ' ',
          ['get', 'type_occupation'],
          ' ',
          ['get', 'etat_bati'],
          ' (achèvement ',
          ['get', 'annee_achevement'],
          ')',
        ],
        'symbol-spacing': 2,
        'text-size': 15,
      },
      paint: {
        'text-color': 'rgb(0,0,0)',
        'text-halo-color': 'rgb(255,255,255)',
        'text-halo-width': 2.5,
      },
    },

    // OIN
    {
      id: 'oin_idf',
      type: 'line',
      source: 'static_oin_idf',
      'source-layer': 'oin_idf',
      minzoom: 7,
      maxzoom: 24,
      paint: {
        'line-width': 5,
        'line-color': 'rgba(255, 0, 131,1)',
      },
    },

    {
      id: 'oin_idf_label',
      type: 'symbol',
      source: 'static_oin_idf',
      'source-layer': 'oin_idf',
      minzoom: 7,
      maxzoom: 14,
      layout: {
        'symbol-placement': 'point',
        'text-field': ['get', 'lib'],
        'symbol-spacing': 5000,
        'text-size': 25,
      },
      paint: {
        'text-color': 'rgb(255,255,255)',
        'text-halo-color': 'rgba(255, 0, 131,1)',
        'text-halo-width': 1.5,
      },
    },

    // SDRIF

    {
      id: 'sdrif_espace_urba',
      type: 'fill',
      source: 'static_sdrif_espace_urba',
      'source-layer': 'sdrif_espace_urba',
      minzoom: 7,
      maxzoom: 24,
      paint: {
        'fill-color': 'rgba(255, 193, 139,0.7)',
        'fill-outline-color': 'rgba(0,0,0,1)',
      },
    },
    {
      id: 'sdrif_n_espace_urba',
      type: 'fill',
      source: 'static_sdrif_n_espace_urba',
      'source-layer': 'sdrif_n_espace_urba',
      minzoom: 7,
      maxzoom: 24,
      paint: {
        'fill-color': 'rgba(146, 171, 215,0.7)',
        'fill-outline-color': 'rgba(0,0,0,1)',
      },
    },

    // RISQUE
    {
      id: 'r_argile',
      type: 'fill',
      source: 'static_r_argile',
      'source-layer': 'r_argile',
      minzoom: 7,
      maxzoom: 24,
      paint: {
        'fill-color': [
          'match',
          ['get', 'lib_niv_alea'],
          'Fort',
          'rgba(188,22,24,0.7)',
          'Moyen',
          'rgba(255,119,0,0.7)',
          'Faible',
          'rgba(255,211,39,0.7)',
          'rgba(0,0,0,0)',
        ],
        'fill-outline-color': 'rgba(0,0,0,1)',
      },
    },

    {
      id: 'r_inondation',
      type: 'fill',
      source: 'static_r_inondation',
      'source-layer': 'r_inondation',
      minzoom: 7,
      maxzoom: 24,
      paint: {
        'fill-color': 'rgba(0, 0, 235,0.7)',
        'fill-outline-color': 'rgba(150,150,150,0)',
      },
    },

    {
      id: 'r_nappe',
      type: 'fill',
      source: 'static_r_nappe',
      'source-layer': 'r_nappe',
      minzoom: 7,
      maxzoom: 24,
      paint: {
        'fill-color': [
          'match',
          ['get', 'lib_id'],
          'pot_deb_nappe',
          'rgba(227,26,28,0.7)',
          'pot_inon_cave',
          'rgba(255,127,0,0.7)',
          // 'pas_deb_inon',
          // '#454545',
          'rgba(0,0,0,0)',
        ],
        'fill-outline-color': 'rgba(0,0,0,1)',
      },
    },

    {
      id: 'r_cavite',
      type: 'circle',
      source: 'static_r_cavite',
      'source-layer': 'r_cavite',
      minzoom: 7,
      maxzoom: 24,
      paint: {
        'circle-radius': 5,
        'circle-color': 'rgb(0,0,0)',
      },
      // layout: {
      //   'icon-size': 0.7,
      //   'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      //   'text-radial-offset': 1,
      //   'text-justify': 'auto',
      //   'icon-image': 'dvf2014',
      // },
    },

    {
      id: 'r_basias',
      type: 'circle',
      source: 'static_r_basias',
      'source-layer': 'r_basias',
      minzoom: 7,
      maxzoom: 24,
      paint: {
        'circle-radius': 7,
        'circle-color': 'rgb(50,50,50)',
      },
      // layout: {
      //   'icon-size': 0.7,
      //   'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      //   'text-radial-offset': 1,
      //   'text-justify': 'auto',
      //   'icon-image': 'dvf2014',
      // },
    },

    {
      id: 'r_basias_label',
      type: 'symbol',
      source: 'static_r_basias',
      'source-layer': 'r_basias',
      minzoom: 7,
      maxzoom: 24,
      layout: {
        'symbol-placement': 'point',
        'text-field': ['get', 'id'],

        'symbol-spacing': 2,
        'text-size': 15,
        'text-anchor': 'left',
        'text-offset': [0.5, 0],
      },
      paint: {
        'text-color': 'rgb(0,0,0)',
        'text-halo-color': 'rgb(255,255,255)',
        'text-halo-width': 1.5,
      },
    },

    {
      id: 'r_icpe',
      type: 'circle',
      source: 'static_r_icpe',
      'source-layer': 'r_icpe',
      minzoom: 7,
      maxzoom: 24,
      paint: {
        'circle-radius': 7,
        'circle-color': [
          'match',
          ['get', 'type_icpe'],
          'Usine non Seveso',
          'rgb(63,119,223)',
          'Usine Seveso',
          'rgb(63,119,223)',
          'Carrières',
          'rgb(32,163,125)',
          'Elevage',
          'rgb(217,61,165)',
          'rgba(0,0,0,0)',
        ],
        'circle-stroke-color': [
          'match',
          ['get', 'type_icpe'],
          'Usine Seveso',
          'rgb(255,0,0)',
          'rgba(0,0,0,0)',
        ],
        'circle-stroke-width': 3,
      },
      // layout: {
      //   'icon-size': 0.7,
      //   'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      //   'text-radial-offset': 1,
      //   'text-justify': 'auto',
      //   'icon-image': 'dvf2014',
      // },
    },

    {
      id: 'ban_idf',
      type: 'symbol',
      source: 'static_ban_idf',
      'source-layer': 'ban_idf',
      minzoom: 15,
      maxzoom: 24,
      layout: {
        'symbol-placement': 'point',
        'text-field': ['get', 'num_ind_rep'],
        'symbol-spacing': 2,
        'text-size': 15,
      },
      paint: {
        'text-color': 'rgb(0,0,0)',
        'text-halo-color': 'rgb(255,255,255)',
        'text-halo-width': 1.5,
      },
    },

    //----------
    {
      id: 'lineaire_comm',
      type: 'line',
      source: 'static_lineaire_comm',
      'source-layer': 'lineaire_comm',
      minzoom: 9,
      maxzoom: 24,
      paint: {
        'line-color': 'rgb(255,0,255)',
        'line-width': 5,
        'line-dasharray': [1, 1],
      },
    },
  ],
  created: '2019-11-22T13:54:23.260Z',
  id: 'ck3a7hh1p0uzy1cmzny8y4522',
  modified: '2019-11-25T13:01:16.995Z',
  owner: 'batir',
  visibility: 'private',
  draft: false,
}

/*
      
    
    },*/
