// sources de données
import vt_datas from './sources/vt_data'

import mapboxSources from './sources/mapboxSources'

// couches de données
import defaultMapStyle from './layers'

// ajout des sources
defaultMapStyle.sources = {
  ...mapboxSources,
  ...vt_datas,
}

export default defaultMapStyle
