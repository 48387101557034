const SESSION_VALIDATION = 'batir/user/SESSION_VALIDATION'
// const DESTROY_SESSION = 'batir/user/DESTROY_SESSION'

export default function(state = {}, action) {
  switch (action.type) {
    case SESSION_VALIDATION:
      return { ...state, session: { ...action.payload } }

    default:
      return state
  }
}

export function validSession({ isValid, message = undefined }) {
  // return (dispatch, getState) => {
  //   if (!isValid) {
  //     dispatch({ type: DESTROY_SESSION })
  //   }
  return { type: SESSION_VALIDATION, payload: { isValid, message } }
  // }
}
