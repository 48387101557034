import WebMercatorViewport from 'viewport-mercator-project'

export function snapLocation(mouseLocation, locations, tolerance, viewport) {
  /* fonction renvoyant le point le plus proche de mouseLocation
    Le tableau locations inclut tous les points à tester.
    Si aucun point à tester est en dessous de la tolérance, on renvoie les coordonnées de mouseLocation
  */

  const wmViewport = new WebMercatorViewport(viewport)
  const pixels = locations.map(location => wmViewport.project(location))

  const mousePixel = wmViewport.project(mouseLocation)

  const { snapped, selectedIndex } = snap(mousePixel, pixels, tolerance)

  return snapped
    ? { snapped, location: locations[selectedIndex] }
    : { snapped, location: mouseLocation }
}

export function snap(mousePixel, pixels, tolerance) {
  /* fonction renvoyant le pixel le plus proche de mousePixel
    Le tableau pixels inclut tous les points à tester.
    Si aucun point à tester est en dessous de la tolérance, on renvoie les coordonnées de mousePixel
  */

  let minDist = tolerance
  let selectedIndex, distance

  pixels.forEach((pixel, index) => {
    let a = mousePixel[0] - pixel[0]
    let b = mousePixel[1] - pixel[1]
    distance = Math.sqrt(a * a + b * b)

    if (distance < minDist) {
      minDist = distance
      selectedIndex = index
    }
  })

  return minDist < tolerance
    ? { pixel: pixels[selectedIndex], snapped: true, selectedIndex }
    : { pixel: mousePixel, snapped: false }
}
