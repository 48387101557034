import React from 'react'
// import PropTypes from 'prop-types'
import { NumberFormatCustom } from '../../../common/components/NumberFormatter'

import {
  Button,
  TextField,
  Card,
  CardContent,
  Grid,
  CardHeader,
  CardActions,
  withStyles,
  Dialog,
  Typography,
  Divider,
  InputAdornment,
  // FormLabel,
  Switch,
  MenuItem,
} from '@material-ui/core'
// import Autocomplete from 'common/components/Autocomplete'

const styles = theme => ({
  dialogPaper: { overflow: 'visible', maxWidth: '90vw', maxHeight: '100vh' },
  card: {
    overflow: 'visible',
  },
  cardHeader: {
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  cardHeaderTitle: {
    color: theme.palette.primary.contrastText,
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  errorMessage: {
    marginTop: theme.spacing(2),
  },
  switchDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'rgb(0,0,0)',
  },
})

const CalibrationForm = ({
  classes,
  inputs,
  updateGabarit,
  handleClose,
  handleLogeEntryChange,
  handleBureEntryChange,
  switchGabaritType,
  errorMessage,
  ...otherProps
}) => {
  return (
    <Dialog
      {...otherProps}
      fullWidth
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <Card className={classes.card}>
        <CardHeader
          color="primary"
          classes={{
            root: classes.cardHeader,
            title: 'w3-light-grey w3-center w3-border-bottom w3-padding',
          }}
          title="Configuration du gabarit de l'actif"
        />
        <form onSubmit={updateGabarit}>
          <div className={classes.switchDiv}>
            <Typography className="w3-text-deep-orange">Bureaux</Typography>
            <Switch
              checked={inputs.isBatiLogement}
              onChange={switchGabaritType}
              color="default"
              value={inputs.isBatiLogement}
            />
            <Typography className="w3-text-indigo">Logements</Typography>
          </div>

          {inputs && (
            <CardContent style={{ maxHeight: '50vh', overflow: 'auto' }}>
              {inputs.isBatiLogement ? (
                <>
                  {/* <Typography align="center">Logements</Typography> */}
                  <Divider style={{ marginBottom: 5 }} />
                  <Grid container alignItems="flex-end" spacing={2}>
                    {Object.entries(inputs.logements).map(
                      ([entryName, entry], idx) => (
                        <Grid key={idx} item sm={4} xs={12}>
                          <TextField
                            color="secondary"
                            fullWidth
                            select={entryName === 'epaisseurDuBatiment'}
                            label={entry.label}
                            value={entry.value}
                            name={entryName}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {entry.unit}
                                </InputAdornment>
                              ),
                              inputComponent:
                                entryName !== 'epaisseurDuBatiment' &&
                                NumberFormatCustom,
                            }}
                            SelectProps={{
                              IconComponent: 'div',
                            }}
                            onChange={handleLogeEntryChange(
                              entryName,
                              entry.defVal
                            )}
                          >
                            {entry.choices &&
                              entry.choices.map(choice => (
                                <MenuItem
                                  key={choice.value}
                                  value={choice.value}
                                >
                                  {choice.label}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                      )
                    )}
                  </Grid>
                </>
              ) : (
                <>
                  {/* <Typography align="center">Bureaux</Typography> */}
                  <Divider style={{ marginBottom: 5 }} />
                  <Grid container alignItems="flex-end" spacing={2}>
                    {inputs.bureaux &&
                      Object.entries(inputs.bureaux).map(
                        ([entryName, entry], idx) => (
                          <Grid key={idx} item sm={4} xs={12}>
                            <TextField
                              color="secondary"
                              fullWidth
                              label={entry.label}
                              value={entry.value}
                              name={entryName}
                              select={entryName === 'commerceProportion'}
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {entry.unit}
                                  </InputAdornment>
                                ),
                                inputComponent: NumberFormatCustom,
                              }}
                              onChange={handleBureEntryChange(
                                entryName,
                                entry.defVal
                              )}
                            >
                              {entry.choices &&
                                entry.choices.map(choice => (
                                  <MenuItem
                                    key={choice.value}
                                    value={choice.value}
                                  >
                                    {choice.label}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Grid>
                        )
                      )}
                  </Grid>
                </>
              )}

              {errorMessage ? (
                <Typography
                  className={classes.errorMessage}
                  align="center"
                  color="error"
                >
                  {errorMessage}
                </Typography>
              ) : null}
            </CardContent>
          )}
          <CardActions classes={{ root: classes.cardActions }}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.button}
              disabled={false}
            >
              Valider les changements
            </Button>
          </CardActions>
        </form>
      </Card>
    </Dialog>
  )
}

export default withStyles(styles)(CalibrationForm)
