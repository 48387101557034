import React from 'react'
import {
  //   NavigationControl,
  Typography,
  Fab,
  withStyles,
} from '@material-ui/core'

import Geocoder from 'react-map-gl-geocoder'

import ThreeDRotation from '@material-ui/icons/ThreeDRotation'
// import NorthIcon from '../../../assets/NorthIcon'
import SatelliteSwitcher from './SatelliteSwitcher'
import StreetView from './StreetView'

import {
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
} from 'react-map-gl'

import 'mapbox-gl/dist/mapbox-gl.css'
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'

const styles = (theme) => ({
  // zoomButton: {
  //   borderRadius: '15%',
  //   border: '1px black solid',
  //   backgroundColor: 'white',
  //   fontWeight: 'bold',
  //   fontSize: '1em',
  // },
  buttonDiv: {
    marginBottom: '0.5em',
  },

  'mapboxgl-ctrl-top-right': {
    top: '0 !important',
    left: '43% !important',
  },
})

const CustomMapControls = ({
  viewport,
  set3DView,
  classes,
  mapboxAccessToken,
  mapRef,
  onViewportChange,
  handleOnResult,
  measuredDistance,
  measuredSurface,
}) => {
  return (
    <React.Fragment>
      <div
        style={{
          position: 'absolute',
          top: '0',
          padding: '2em',
          right: 0,
          pointerEvents: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className={classes.buttonDiv}>
          <NavigationControl />
        </div>
        <div className={classes.buttonDiv}>
          <FullscreenControl />
        </div>
        <div className={classes.buttonDiv}>
          <GeolocateControl
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
          />
        </div>

        <Fab
          color="primary"
          size="small"
          title="Vue 3D"
          onClick={() => {
            set3DView()
          }}
          className={classes.buttonDiv}
        >
          {!(viewport && viewport.pitch === 0) ? (
            <Typography color="secondary">2D</Typography>
          ) : (
            <ThreeDRotation color="secondary" />
          )}
        </Fab>

        <div
          className={classes.buttonDiv}
          style={{ width: 76.19, height: 76.19 }}
        >
          {viewport && viewport.zoom < 19 && <SatelliteSwitcher />}
        </div>
      </div>
      <StreetView />
      <Geocoder
        placeholder="Rechercher une adresse..."
        mapRef={mapRef}
        onViewportChange={onViewportChange}
        mapboxApiAccessToken={mapboxAccessToken}
        position={'top-left'}
        countries={'fr'}
        onResult={handleOnResult}
      />
      {(measuredDistance || measuredSurface) && (
        <div
          style={{
            position: 'absolute',
            top: '15px',
            left: '270px',
            zIndex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: '#fff',
            fontSize: '11px',
            lineHeight: '18px',
            display: 'block',
            margin: 0,
            padding: '5px 10px',
            borderRadius: '3px',
          }}
        >
          {measuredDistance} <br />
          {measuredSurface}
        </div>
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(CustomMapControls)
