import React, { Component } from 'react'
import { connect } from 'react-redux'
import SimulationResultForm from './SimulationResultForm'

import { simulateProjectImplentation } from 'store/project/simulationReducer'

// const styles = theme => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper,
//   },
// })

class SimulationResult extends Component {
  state = { results: [] }

  setDefaultConfig = () => {
    this.setState({})
  }

  handleClose = e => {
    e.preventDefault()
    this.props.onClose()
  }

  render() {
    const {
      classes,
      simulateProjectImplentation,
      project,
      ...otherProps
    } = this.props
    return (
      <>
        {project.projectImplentation && (
          <SimulationResultForm
            {...otherProps}
            inputs={project.projectImplentation}
            handleClose={this.handleClose}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = ({ project }) => ({
  project,
})

const mapDispatchToProps = {
  simulateProjectImplentation,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimulationResult)
