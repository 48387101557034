import React from 'react'
import classNames from 'classnames'

import { withStyles, Paper, Typography, Grid, Zoom } from '@material-ui/core'

const styles = theme => ({
  paper: {
    zIndex: 1,
    padding: theme.spacing(2),
    position: 'absolute',
    bottom: '2em',
    left: 0,
    right: 0,
    margin: 'auto',
    width: '60%',
    backgroundColor: '#FF5522',
    color: '#FFFFFF',
  },
  typography: {
    textAlign: 'center',
  },
  layerPaneLeftShift: {
    bottom: '5em',
    marginLeft: `calc((10%) + ${280}px)`,
  },
})

const MapToolTipsComponent = ({
  visible,
  classes,
  layerPanelsDisplayed,
  interaction,
}) => {
  return (
    <Zoom in={visible} direction="up">
      <Paper
        className={classNames(classes.paper, {
          [classes.layerPaneLeftShift]: layerPanelsDisplayed,
        })}
      >
        <Typography variant="body1" className={classes.typography} gutterBottom>
          {interaction === 'create' &&
            `Cliquez sur la carte pour indiquer les sommets du bâtiment que vous
            souhaitez créer. Un marqueur vert apparaît lorsque vous déplacez le
            curseur à proximité d'un sommet d'un bâtiment ou d'une parcelle du
            projet, cela indique que vous pouvez vous accrocher à ce sommet en
            cliquant. Pour quitter la saisie, appuyez sur la touche Echap ou
            Clic droit.`}

          {interaction === 'measure' &&
            `Cliquez sur la carte pour tracer une ligne. La distance de cette derniere apparaîtra en haut de l'écran.
            Pour quitter la saisie, appuyez sur la touche Echap ou Clic droit.`}
        </Typography>
        <Grid container justify="space-around">
          {/* <Grid item container justify="center" xs>
            <Button variant="contained" onClick={handleImplantation}>
              Valider
            </Button>
          </Grid>
          <Grid item container justify="center" xs>
            <Button variant="contained" onClick={handleEditPosition}>
              Déplacer
            </Button>
          </Grid>
          <Grid item container justify="center" xs>
            <Button variant="contained" onClick={handleAnnulation}>
              Annuler
            </Button>
          </Grid> */}
        </Grid>
      </Paper>
    </Zoom>
  )
}

export default withStyles(styles)(MapToolTipsComponent)
