import getHoveredElmts from './hoveredElmts'
import { customMapControlsEventBlocker } from './eventBlock'

export default function() {
  const {
    getParcelleInfo,
    // getParcelleRules,
    handleGenerateWindow,
    // projectId,
    cleanHoveredInfo,
  } = this.props

  return {
    onClick: e => {
      const parcelle = e.features
        ? e.features.find(f => f.layer.id === 'cadastre_parcelles_fill')
        : null

      if (parcelle && customMapControlsEventBlocker(e.target.className)) {
        getParcelleInfo({
          parcelleId: parcelle.properties.parcelleId,
        })
        // getParcelleRules({ parcelle_id: parcelle.properties.parcelleId })
        handleGenerateWindow()
      }
    },
    onHover: e => {
      const parcelle = e.features
        ? e.features.find(f => f.layer.id === 'cadastre_parcelles_fill')
        : null

      if (parcelle) {
        this.setState({
          hoveredParcelleId: parcelle.properties.parcelleId,
        })
      } else {
        this.setState({
          hoveredParcelleId: null,
        })
      }

      getHoveredElmts(e, this.props)
    },
    onMouseOut: e => {
      // lorsque la souris sort du canevas de la carte
      this.setState({
        hoveredParcelleId: null,
      })
    },
    onMouseLeave: e => {
      cleanHoveredInfo()
    },
  }
}
