import React from 'react'
import { NumberFormatCustom } from '../../../common/components/NumberFormatter'

import { TextField, Grid, Typography, InputAdornment } from '@material-ui/core'

const SurfaceCalculatorBureaux = ({
  handleChange,
  inputs,
  surfParEtage,
  sommeSurfBur,
  isSommeSurfBurValid,
  aireParcel,
}) => {
  const sdpTotFerme = parseFloat(
    (inputs.nbEffectifBurFerme * inputs.surfBurFerme * 100) / inputs.rendBur
  ).toFixed(1)
  const sdpTotPart = parseFloat(
    (inputs.nbEffectifBurPart * inputs.surfBurPart * 100) / inputs.rendBur
  ).toFixed(1)
  const sdptotOuv = parseFloat(
    (inputs.nbEffectifBurOuv * inputs.surfBurOuv * 100) / inputs.rendBur
  ).toFixed(1)

  const sdpTotal = (
    parseFloat(sdpTotFerme) +
    parseFloat(sdpTotPart) +
    parseFloat(sdptotOuv)
  ).toFixed(1)
  return (
    <>
      <Grid // ----------------------------------------------------BUREAU FERME
        container
        spacing={2}
        alignItems="center"
        style={{ paddingBottom: '0.4em' }}
      >
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Nombre de poste necessaires "
            value={inputs.nbEffectifBur}
            name="nbEffectifBur"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: (
                <InputAdornment position="end">poste(s)</InputAdornment>
              ),
            }}
            onChange={handleChange('nbEffectifBur')}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Dont nombre bureaux fermé"
            value={inputs.nbEffectifBurFerme}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleChange('nbEffectifBurFerme')}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Surface d'un poste en bureau fermé"
            value={inputs.surfBurFerme}
            name="surfBurFerme"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: (
                <InputAdornment position="end">m²/poste</InputAdornment>
              ),
            }}
            onChange={handleChange('surfBurFerme')}
          />
        </Grid>
        <Grid item xs={1}>
          <Typography align="center" variant="h6">
            =>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            disabled
            label="Surface totale en bureaux fermé"
            value={parseFloat(
              inputs.nbEffectifBurFerme * inputs.surfBurFerme
            ).toFixed(1)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
            helperText={'en SUB, soit ' + sdpTotFerme + 'm² Sdp'}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Rendement"
            value={inputs.rendBur}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onChange={handleChange('rendBur')}
            helperText="(SUB/Sdp)"
          />
        </Grid>
      </Grid>

      <Grid // ---------------------------------------------------- BUREAUX PARTAGE
        container
        spacing={2}
        alignItems="center"
        style={{ paddingBottom: '0.4em' }}
      >
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Dont nombre bureaux partagés"
            value={inputs.nbEffectifBurPart}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleChange('nbEffectifBurPart')}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Surface d'un poste en bureau partagé "
            value={inputs.surfBurPart}
            name="surfBurPart"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: (
                <InputAdornment position="end">m²/poste</InputAdornment>
              ),
            }}
            onChange={handleChange('surfBurPart')}
          />
        </Grid>
        <Grid item xs={1}>
          <Typography align="center" variant="h6">
            =>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            disabled
            label="Surface totale en bureaux partagé"
            value={parseFloat(
              inputs.nbEffectifBurPart * inputs.surfBurPart
            ).toFixed(1)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
            helperText={'en SUB, soit ' + sdpTotPart + 'm² Sdp'}
          />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>

      <Grid // ----------------------------------------------------BUREAUX ESPACE OUVERT
        container
        spacing={2}
        alignItems="center"
        style={{ paddingBottom: '0.4em' }}
      >
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Dont nombre postes en espace ouvert"
            value={inputs.nbEffectifBurOuv}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleChange('nbEffectifBurOuv')}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Surface d'un poste en bureau ouvert "
            value={inputs.surfBurOuv}
            name="surfBurOuv"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: (
                <InputAdornment position="end">m²/poste</InputAdornment>
              ),
            }}
            onChange={handleChange('surfBurOuv')}
          />
        </Grid>
        <Grid item xs={1}>
          <Typography align="center" variant="h6">
            =>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            disabled
            label="Surface totale en bureaux ouvert"
            value={parseFloat(
              inputs.nbEffectifBurOuv * inputs.surfBurOuv
            ).toFixed(1)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
            helperText={'en SUB, soit ' + sdptotOuv + 'm² Sdp'}
          />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>

      <Grid // ---------------------------------------------------- SALLE DE REUNION
        container
        spacing={2}
        alignItems="center"
        style={{ paddingBottom: '0.4em' }}
      >
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Nombre de salles de réunions"
            value={inputs.nbSalleReunionBur}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleChange('nbSalleReunionBur')}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Surface d'un poste en salle de réunion"
            value={inputs.surfBurReunion}
            name="surfBurReunion"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: (
                <InputAdornment position="end">m²/poste</InputAdornment>
              ),
            }}
            onChange={handleChange('surfBurReunion')}
          />
        </Grid>
        <Grid item xs={1}>
          <Typography align="center" variant="h6">
            =>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            disabled
            label="Surface totale en salle de réunion"
            value={parseFloat(
              inputs.nbSalleReunionBur * inputs.surfBurReunion
            ).toFixed(1)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
            helperText={
              'en SUB, soit ' +
              parseFloat(
                (inputs.nbEffectifBurOuv * inputs.surfBurOuv * 100) /
                  inputs.rendBur
              ).toFixed(1) +
              'm² Sdp'
            }
          />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ paddingBottom: '0.4em' }}
      >
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Circulation/sanitaire/service et technique"
            value={inputs.surfCsstLog}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onChange={handleChange('surfCsstLog')}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" align="center">
            {surfParEtage + ' m²'}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography align="center" variant="h6">
            =
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            disabled
            helperText={'Circulation Sanitaire Service et Technique'}
            label="Surface totale C.S.S.T."
            value={(surfParEtage * inputs.surfCsstLog) / 100}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Tranche de surface Sdp"
            value={inputs.surfTranchSdp}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
            onChange={handleChange('surfTranchSdp')}
            helperText={'pour une Sdp total de ' + sdpTotal + 'm²'}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Surface d'un stationnement"
            value={inputs.surfStationn}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
            onChange={handleChange('surfStationn')}
            helperText={
              'pour un total de ' +
              (sdpTotal / inputs.surfTranchSdp).toFixed(1) +
              ' places soit ' +
              ((sdpTotal * inputs.surfStationn) / inputs.surfTranchSdp).toFixed(
                1
              ) +
              'm²'
            }
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ paddingBottom: '0.4em' }}
      >
        <Grid item xs={2} />

        <Grid item xs={8}>
          <Typography
            variant="h6"
            align="center"
            className="w3-indigo w3-padding-small"
            color={!isSommeSurfBurValid ? 'error' : 'initial'}
          >
            {'Somme des surfaces restantes à ' +
              (isSommeSurfBurValid ? 'attribuer ' : 'retirer ') +
              ': ' +
              (surfParEtage - sommeSurfBur).toFixed(2) +
              ' m², nombre de sous-sols à prevoir : ' +
              Math.ceil(
                aireParcel /
                  ((sdpTotal / inputs.surfTranchSdp).toFixed(1) *
                    inputs.surfStationn)
              )}
          </Typography>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </>
  )
}

export default SurfaceCalculatorBureaux
