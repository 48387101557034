import React, { Component } from 'react'
import { connect } from 'react-redux'

import { updateLayers } from '../../../store/map/mapReducer'

// import classNames from 'classnames'

import {
  // Drawer,
  withStyles /*IconButton, Typography */,
} from '@material-ui/core'
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import LegendsContainer from './LegendsContainer'

const styles = theme => ({
  bottomPanel: {
    // height: '10%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  bottomPanelShift: {
    width: `calc(100% - ${280}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerPaper: {
    height: '4%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.secondary.main,
  },
  drawerPaperShift: {
    marginLeft: 280,
    // width: `calc(100% - ${280}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
})

class LegendPanel extends Component {
  render() {
    const { thematics, open /* classes, handleDrawerToggle*/ } = this.props
    return (
      // <Drawer
      //   open={open}
      //   variant="persistent"
      //   // onClose={() => handleDrawerToggle('openedLegendDrawer')}
      //   classes={{
      //     paper: classNames(classes.drawerPaper, {
      //       [classes.drawerPaperShift]: open,
      //     }),
      //   }}
      //   PaperProps={{ elevation: 4 }}
      //   anchor="bottom"
      //   style={{ zIndex: 1100 }}
      //   // className={classNames(classes.bottomPanel, {
      //   //   [classes.bottomPanelShift]: open,
      //   // })}
      // >
      //   {/* <div
      //     className={classNames(classes.bottomPanel, {
      //       [classes.bottomPanelShift]: open,
      //     })}
      //   > */}
      //   {/* </div> */}
      // </Drawer>
      open && (
        <LegendsContainer
          // classes={classNames(classes.drawerPaper, {
          //   [classes.drawerPaperShift]: open,
          // })}
          data={thematics}
        />
      )
    )
  }
}

const mapStateToProps = store => ({
  thematics: store.map.thematics || [],
})
const mapDispatchToProps = { updateLayers }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LegendPanel))
