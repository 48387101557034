import createDuck from '../createDuck'
// import { communeQueryApi } from '../project/projectReducer'
// from '../queryBatirApi/queryBatirApiReducer'

const { reducer: simulationReducer, simulateProjectImplentation } = createDuck({
  namespace: 'batir/project',
  actionName: 'simulateProjectImplentation',
  payloadName: 'projectImplentation',
  path:
    process.env.REACT_APP_API_PATH +
    'project/projectImplentation/simulateProjectImplentation.php',
})

export default simulationReducer
export { simulateProjectImplentation }
