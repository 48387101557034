import createDuck from '../createDuck'
import DEFAULT_STATE from '../DEFAULT_STATE'
import reduceReducer from 'reduce-reducers'

import { changeInteraction } from '../map/mapReducer'

const RESET_FILTER = 'batir/filter/RESET_FILTER'

const setFilterReducer = function(state, action) {
  switch (action.type) {
    case 'batir/user/LOG_OUT_REJECTED':
      return { ...DEFAULT_STATE.filter }
    case RESET_FILTER:
      return { ...DEFAULT_STATE.filter }
    default:
      return state
  }
}

// Action Creators
function resetFilter() {
  return {
    type: RESET_FILTER,
  }
}

//Duck
const { reducer: updateFilterReducer, updateFilter } = createDuck({
  namespace: 'batir/filter',
  actionName: 'updateFilter',
  path: process.env.REACT_APP_API_PATH + 'search/updateFilter.php',
  callback: function() {
    return (dispatch, getState) => {
      if (getState().map.changeInteraction !== 'info') {
        dispatch(changeInteraction('info'))
      }
    }
  },
})

const filterReducer = reduceReducer(setFilterReducer, updateFilterReducer)

export default filterReducer
export { updateFilter, resetFilter }
