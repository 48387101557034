import reduceReducers from 'reduce-reducers'
import communeReducer from './communeReducer'
import sectionReducer from './sectionReducer'
import numeroReducer from './numeroReducer'
import departementReducer from './departementReducer'
import exportPluReducer from './exportPluReducer'

export default reduceReducers(
  communeReducer,
  sectionReducer,
  numeroReducer,
  departementReducer,
  exportPluReducer
)
