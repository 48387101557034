import createDuck from '../createDuck'

const { reducer: numeroReducer, searchNumero } = createDuck({
  namespace: 'batir/search',
  actionName: 'searchNumero',
  payloadName: 'numeros',
  path: process.env.REACT_APP_API_PATH + 'search/searchNumero.php',
})

export default numeroReducer
export { searchNumero }
