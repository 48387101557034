import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TopBar from 'common/components/TopBar'
import {
  Grid,
  withStyles,
  Typography,
  Divider,
  Button,
  Paper,
} from '@material-ui/core'

import homeBackground from 'assets/homeBackground.jpg'

import ProjectCard from './ProjectCard'
import NewProject from './NewProject'
import DemoVideo from './DemoVideo'
import CollabWindow from './CollabWindow'

const styles = (theme) => ({
  '@global': {
    body: {
      backgroundImage: `url(${homeBackground})`,
    },
  },
  scrollingGrid: {
    backgroundImage: `url(${homeBackground})`,
    backgroundRepeat: 'no-repeat',
  },
  projectsHeader: {
    height: '8vh',
    marginTop: theme.spacing(14),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
    },
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: { marginLeft: '150px', backgroundColor: 'orange' },
  lastButton: { marginRight: '150px', backgroundColor: 'orange' },

  noProjectPaper: { backgroundColor: 'rgba(255,255,255,0.9)', width: '100%' },
})

class HomeLayout extends Component {
  state = {
    newProject: false,
    titleSearch: '',
    isOpenedDemoVideo: false,
    isCollabWindowOpened: false,
  }

  setNewProject = (bool) => this.setState({ newProject: bool })

  handleCollabWindow = (e) => {
    this.setState({ isCollabWindowOpened: !this.state.isCollabWindowOpened })
  }

  handleSearchInput = (e) => {
    this.setState({ titleSearch: e.target.value })
  }
  handleDemoVideo = (e) => {
    this.setState({ isOpenedDemoVideo: !this.state.isOpenedDemoVideo })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data && this.props.data.length === 0) {
      this.setNewProject(true)
    }
  }

  render() {
    const { classes, data, logOut, deleteProject } = this.props

    const { newProject, isOpenedDemoVideo, isCollabWindowOpened } = this.state

    const filteredProjects = (data, word) => {
      return data.filter((project) =>
        project.title.toLowerCase().includes(word)
      )
    }

    const projectCards = filteredProjects(data, this.state.titleSearch).map(
      (project) => (
        <Grid item key={project.projectId} xs={12} sm={5} md={4} lg={3} xl={2}>
          <ProjectCard deleteProject={deleteProject} {...project} />
        </Grid>
      )
    )

    return (
      <>
        <TopBar
          setNewProject={this.setNewProject}
          handleSearchInput={this.handleSearchInput}
          logOut={() => logOut()}
        />
        <div className={classes.projectsHeader}>
          <Button
            variant="contained"
            onClick={this.handleDemoVideo}
            className={`${classes.button} w3-lime`}
          >
            Découvrir UrbaScope »
          </Button>

          <Button
            variant="contained"
            onClick={this.handleCollabWindow}
            className={`${classes.lastButton} w3-lime`}
          >
            Mes interlocuteurs »
          </Button>
        </div>
        <Divider></Divider>
        <div className="w3-container" style={{ minHeight: '74vh' }}>
          <Grid container spacing={2}>
            {projectCards.length > 0 ? (
              projectCards
            ) : (
              <Paper className={`${classes.noProjectPaper} w3-margin-large`}>
                <Typography align="center">Aucun Projet</Typography>
                {data.length === 0 && (
                  <Typography align="center">
                    Pour créer un projet, cliquer sur le bouton en haut à droite
                    de l'écran
                  </Typography>
                )}
              </Paper>
            )}
          </Grid>
        </div>
        <NewProject
          disableScrollLock={true}
          open={newProject}
          onClose={() => this.setNewProject(false)}
        />
        <CollabWindow
          disableScrollLock={true}
          open={isCollabWindowOpened}
          onClose={this.handleCollabWindow}
        />
        <DemoVideo
          disableScrollLock={true}
          open={isOpenedDemoVideo}
          onClose={this.handleDemoVideo}
        />
      </>
    )
  }
}

HomeLayout.propTypes = {
  data: PropTypes.array.isRequired,
}

export default withStyles(styles)(HomeLayout)
