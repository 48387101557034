import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Card,
  CardContent,
  Grid,
  CardHeader,
  CardActions,
  withStyles,
  Dialog,
} from '@material-ui/core'

import Autocomplete from 'common/components/Autocomplete'

const styles = (theme) => ({
  dialogPaper: { overflow: 'visible' },
  card: {
    overflow: 'visible',
  },
  cardHeader: {
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  cardHeaderTitle: {
    color: theme.palette.primary.contrastText,
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  errorMessage: {
    marginTop: theme.spacing(2),
  },
  button: {
    backgroundColor: 'rgb(0,0,0)',
  },
  formControl: {
    minWidth: '100%',
  },
})

const ChartePromForm = ({
  classes,
  inputs,
  handleSubmit,
  handleCommuneInputChange,
  handleCommuneChange,
  communeOptions,
  errorMessage,
  ...otherProps
}) => {
  return (
    <Dialog {...otherProps} fullWidth classes={{ paper: classes.dialogPaper }}>
      <Card className={classes.card}>
        <CardHeader
          color="primary"
          classes={{
            root: classes.cardHeader,
            title: 'w3-light-grey w3-center w3-border-bottom w3-padding',
          }}
          title="Télécharger une charte promoteur"
        />
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Autocomplete
                onInputChange={handleCommuneInputChange}
                onChange={handleCommuneChange}
                options={communeOptions || []}
                name="commune"
                noOptionsMessage={() => 'Aucune commune ne possede de charte'}
                placeholder="Chercher une commune..."
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions classes={{ root: classes.cardActions }}>
          <Button
            disabled={!inputs.commune}
            onClick={handleSubmit}
            variant="contained"
            color="secondary"
            className={classes.button}
          >
            Télecharger
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  )
}

ChartePromForm.defaultProps = {
  inputs: {},
}

ChartePromForm.propTypes = {
  inputs: PropTypes.shape({
    departement: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func,
}

export default withStyles(styles)(ChartePromForm)
