export default {
  'mapbox://mapbox.satellite': {
    url: 'mapbox://mapbox.satellite',
    type: 'raster',
    tileSize: 256,
  },
  composite: {
    url: 'mapbox://mapbox.mapbox-streets-v8',
    type: 'vector',
  },
  // test: {
  //   type: 'geojson',
  //   data: {
  //     type: 'FeatureCollection',
  //     features: [
  //       {
  //         type: 'Feature',
  //         geometry: {
  //           type: 'Point',
  //           coordinates: [2.412, 48.7887],
  //         },
  //         properties: {
  //           prop0: 'value0',
  //           prop1: 'value1',
  //           prop2: 'value2',
  //         },
  //       },
  //     ],
  //   },
  // },
}
