import React, { Component } from 'react'

import { withStyles } from '@material-ui/core'

import {
  // Button,
  // TextField,
  // Card,
  // CardContent,
  // Grid,
  // CardHeader,
  // CardActions,
  // withStyles,
  Dialog,
  // Typography,
  // Divider,
  // InputAdornment,
  // // FormLabel,
  // // Switch,
  // MenuItem,
} from '@material-ui/core'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  dialogPaper: { overflow: 'visible', maxWidth: '90vw', maxHeight: '100vh' },
})

class ChargeFonciere extends Component {
  handleClose = () => {
    this.props.onClose()
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        fullWidth
        onClose={this.handleClose}
        classes={{ paper: this.props.classes.dialogPaper }}
      >
        <iframe
          id="Urbascope Charge Fonciere"
          title="Urbascope Charge Fonciere"
          height="500"
          src={process.env.REACT_APP_ABS_API_PATH + 'vues/chargesFoncieres.php'}
        ></iframe>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ChargeFonciere)
