import DEFAULT_STATE from '../DEFAULT_STATE'

// Actions
const UPDATE_POPUP_MESSAGE = 'batir/popupMessage/UPDATE_POPUP_MESSAGE'

export default function(state = DEFAULT_STATE.popupMessage, action) {
  switch (action.type) {
    case UPDATE_POPUP_MESSAGE:
      return { ...state, text: action.text, instant: new Date().getTime() }

    default:
      return state
  }
}

// Action creator
export const updatePopupMessage = text => ({
  type: UPDATE_POPUP_MESSAGE,
  text,
})
