import React from 'react'
import PropTypes from 'prop-types'
import {
  List,
  ListItem,
  ListItemText,
  withStyles,
  Checkbox,
} from '@material-ui/core'

import LayerItem from './LayerItem'

const styles = theme => ({
  list: {
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0,
    color: 'inherit',
  },
})

const LayersList = ({
  idx,
  layers,
  addCheckAll,
  toggleLayer,
  checkAllDvfLayers,
  classes,
}) => (
  <List dense className={classes.list}>
    {addCheckAll && (
      <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Checkbox
          checked={
            !layers.some(layer => {
              return Boolean(
                layer.visible === false && layer.layerId.startsWith('dvf_idf')
              )
            })
          }
          value={'checkAll'}
          onClick={checkAllDvfLayers(idx)}
          // style={{ color: 'inherit' }}
          color="secondary"
        />
        <ListItemText>Ensemble des années</ListItemText>
      </ListItem>
    )}
    {layers.map(layer => (
      <ListItem key={layer.layerId} style={{ paddingTop: 0, paddingBottom: 0 }}>
        <LayerItem layer={layer} toggleLayer={toggleLayer} idx={idx} />
      </ListItem>
    ))}
  </List>
)

LayersList.propTypes = {
  layers: PropTypes.arrayOf(
    PropTypes.shape({
      layerId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      visible: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
  toggleLayer: PropTypes.func,
}

export default withStyles(styles)(LayersList)
