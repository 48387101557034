import React from 'react'
import PropTypes from 'prop-types'
// import classNames from 'classnames'
import { IconButton, withStyles, Tooltip } from '@material-ui/core'

import MeasureIcon from '../../../assets/MeasureIcon'
import InfoIcon from '../../../assets/InfoIcon'
import SelectIcon from '../../../assets/SelectIcon'
import ImplanterIcon from '../../../assets/ImplanterIcon'
import GenererBatiIcon from '../../../assets/GenererBatiIcon'
import EditerIcon from '../../../assets/EditerIcon'
// import StraightenIcon from '@material-ui/icons/Straighten'

const styles = theme => ({
  disabledButtons: {
    backgroundColor: 'rgb(255,0,0)',
  },

  typography: { textAlign: 'center' },
})

const InteractionButtonsComponent = ({
  interaction,
  changeInteraction,
  classes,
}) => {
  const handleClick = type => e => {
    changeInteraction(type)
  }

  return (
    <React.Fragment>
      <Tooltip title="Mesurer des distances" placement="bottom">
        <div className="  w3-border w3-border-deep-orange">
          <IconButton
            disabled={interaction === 'measure'}
            onClick={handleClick('measure')}
            style={{
              backgroundColor:
                interaction === 'measure' ? 'rgb(225,225,225)' : 'inherit',
            }}
          >
            <MeasureIcon />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip
        title="Obtenir des informations sur une parcelle"
        placement="bottom"
      >
        <div className="  w3-border w3-border-deep-orange">
          <IconButton
            disabled={interaction === 'info'}
            onClick={handleClick('info')}
            style={{
              backgroundColor:
                interaction === 'info' ? 'rgb(225,225,225)' : 'inherit',
            }}
          >
            <InfoIcon />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title="Sélectionner/retirer des parcelles" placement="bottom">
        <div className="  w3-border w3-border-deep-orange">
          <IconButton
            disabled={interaction === 'select'}
            style={{
              backgroundColor:
                interaction === 'select' ? 'rgb(225,225,225)' : 'inherit',
            }}
            // classes={{ disabled: classes.disabledButtons }}
            onClick={handleClick('select')}
          >
            <SelectIcon />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title="Tracer l'implantation du bâti" placement="bottom">
        <div className="  w3-border w3-border-deep-orange">
          <IconButton
            disabled={interaction === 'create'}
            onClick={handleClick('create')}
            style={{
              backgroundColor:
                interaction === 'create' ? 'rgb(225,225,225)' : 'inherit',
            }}
          >
            <ImplanterIcon />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title="Generer l'implantation d'un bâti" placement="bottom">
        <div className="  w3-border w3-border-deep-orange">
          <IconButton
            disabled={interaction === 'generate'}
            onClick={handleClick('generate')}
            style={{
              backgroundColor:
                interaction === 'generate' ? 'rgb(225,225,225)' : 'inherit',
            }}
          >
            <GenererBatiIcon />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title="Editer/Supprimer le bâti du projet" placement="bottom">
        <div className="  w3-border w3-border-deep-orange w3-margin-right">
          <IconButton
            disabled={interaction === 'edit'}
            onClick={handleClick('edit')}
            style={{
              backgroundColor:
                interaction === 'edit' ? 'rgb(225,225,225)' : 'inherit',
            }}
          >
            <EditerIcon />
          </IconButton>
        </div>
      </Tooltip>
    </React.Fragment>
  )
}

InteractionButtonsComponent.propTypes = {
  handleInfo: PropTypes.func,
}

export default withStyles(styles)(InteractionButtonsComponent)
