import React from 'react'
import { withStyles /*Typography*/ } from '@material-ui/core'

import LegendContent from './LegendContent'

const styles = theme => ({
  root: { padding: 0 },
})

const LayerLegend = ({ layer, idx }) => (
  <LegendContent title={layer.title} legend={layer.legend} />
)

export default withStyles(styles)(LayerLegend)
