import React, { Component } from 'react'
import { connect } from 'react-redux'

import { NumberFormatCustom } from '../../../common/components/NumberFormatter'

import {
  withStyles,
  Dialog,
  DialogTitle,
  TextField,
  Button,
  DialogContent,
  DialogActions,
} from '@material-ui/core'

import {
  minusBuildingPolygon,
  cleanBuilding,
} from '../../../store/drawing/drawingReducer'

const styles = theme => ({
  button: {
    backgroundColor: 'rgb(0,0,0)',
  },
})

class BuildingBufferDialogContainer extends Component {
  state = {
    buffer: 1,
  }

  handleChange = e => {
    this.setState({ buffer: Number(e.target.value) })
  }

  render() {
    const buffer = this.state.buffer
    const { cleanBuilding, minusBuildingPolygon, open, classes } = this.props
    return (
      <Dialog open={open}>
        <DialogTitle>Indiquez la distance de réduction</DialogTitle>
        <DialogContent>
          <TextField
            color="secondary"
            autoFocus
            fullWidth
            margin="dense"
            error={!/^([1-9][0-9.]*)$/.test(buffer)}
            label="distance (m)"
            value={buffer}
            onChange={this.handleChange}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={cleanBuilding}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={e => minusBuildingPolygon(buffer)}
          >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ drawing }) => {
  return {
    open: drawing.action && drawing.action === 'reduce1m' ? true : false,
  }
}

export default connect(mapStateToProps, {
  minusBuildingPolygon,
  cleanBuilding,
})(withStyles(styles)(BuildingBufferDialogContainer))
