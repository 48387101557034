import Axios from 'axios'
import {
  concatAireBatis,
  concatHauteurBatis,
  sommeAireBatis,
} from '../calc/calc'

import pdf_logo from '../../assets/pdf_logo.jpg'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

function dataRequest(url, callback) {
  // effectue une requete puis applique une fct avec les données recupéré
  var xhr = new XMLHttpRequest()
  xhr.onload = function() {
    var reader = new FileReader()
    reader.onloadend = function() {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

const makePdf = projectImplentation => {
  const {
    projectZones,
    batiments,
    h_max,
    zonagePLU,
    extent: { reference, centroid },
  } = projectImplentation

  const emprisesAuSolDuBati = concatAireBatis(batiments)
  const hauteurDuBatis = concatHauteurBatis(batiments)
  const superficieBatiEstim = sommeAireBatis(batiments)

  const docdefinition = ({ mapImg, logo, adress }) => {
    return {
      images: {
        situation: mapImg,
        logo,
      },
      content: [
        {
          columns: [
            {
              table: {
                heights: [20],
                widths: ['*'],
                body: [
                  [
                    {
                      alignment: 'center',
                      text: 'Fiche projet',
                      fontSize: 15,
                      bold: true,
                    },
                  ],
                ],
              },
            },
            {
              table: {
                heights: [20],
                widths: ['*'],
                body: [
                  [
                    {
                      alignment: 'center',
                      bold: true,
                      fontSize: 15,
                      text: new Date().toLocaleDateString(),
                    },
                  ],
                ],
              },
            },
          ],
        },
        '\n',
        {
          columns: [
            {
              table: {
                heights: [250],
                widths: ['auto', '*'],
                body: [
                  [
                    {
                      image: 'situation',
                      width: 370,
                      height: 250,
                    },
                    {
                      type: 'none',
                      fontSize: 11,
                      ol: [
                        { text: 'ADRESSE', bold: true },

                        { text: adress || 'non identifié' },
                        '\n\n',
                        { text: 'REFERENCE CADASTRAL', bold: true },

                        { text: reference },
                      ],
                    },
                  ],
                ],
              },
            },
          ],
        },
        '\n',
        {
          columns: [
            [
              {
                table: {
                  widths: ['*'],
                  heights: [20, 200],
                  body: [
                    [
                      {
                        alignment: 'center',
                        bold: true,
                        fontSize: 15,
                        text: 'Terrain',
                      },
                    ],
                    [
                      {
                        type: 'none',
                        fontSize: 11,
                        ol: [
                          { text: 'Superficie', bold: true },
                          {
                            text: projectZones
                              ? Math.round(projectZones.superficie) + ' m²'
                              : 'Aucune parcelle selectioné',
                          },
                          '\n',
                          { text: 'Emprise au sol du bati', bold: true },
                          { text: emprisesAuSolDuBati + ' m²' },
                          '\n',
                          { text: 'Hauteur du bati', bold: true },
                          { text: hauteurDuBatis + ' m' },
                          '\n',
                          { text: "Nombre d'éléments batis", bold: true },
                          { text: batiments ? batiments.length : '0' },
                          '\n',
                          { text: 'Superficie bâtie estimée', bold: true },
                          { text: superficieBatiEstim + ' m²' },
                        ],
                      },
                    ],
                  ],
                },
              },
              '\n',
              {
                table: {
                  widths: ['*'],
                  heights: [20, 160],
                  body: [
                    [
                      {
                        alignment: 'center',
                        bold: true,
                        fontSize: 15,
                        text: 'Sols et risques',
                      },
                    ],
                    [
                      {
                        type: 'none',
                        fontSize: 11,
                        ol: [
                          { text: 'PPRI', bold: true },
                          {
                            text: projectZones
                              ? projectZones.ppri
                              : 'Aucune parcelle selectioné',
                          },
                          '\n',
                          // { text: 'BASOL', bold: true },
                          // {
                          //   text: projectZones
                          //     ? projectZones.basol
                          //     : 'Aucune parcelle selectioné',
                          // },
                          // '\n',
                          { text: 'BASIAS', bold: true },
                          {
                            text: projectZones
                              ? projectZones.basias
                              : 'Aucune parcelle selectioné',
                          },
                          '\n',
                          {
                            text: 'CARRIERES (département 77 uniquement)',
                            bold: true,
                          },
                          {
                            text: projectZones
                              ? projectZones.carrieres
                              : 'Aucune parcelle selectioné',
                          },
                        ],
                      },
                    ],
                  ],
                },
              },
            ],
            {
              table: {
                widths: ['*'],
                heights: [20, 405],

                body: [
                  [
                    {
                      alignment: 'center',
                      bold: true,
                      fontSize: 15,
                      text: 'Règlementation',
                    },
                  ],
                  [
                    {
                      type: 'none',
                      fontSize: 11,
                      ol: [
                        { text: 'Zone PLU', bold: true },
                        {
                          text: zonagePLU
                            ? zonagePLU.typezone
                            : 'Aucune donnée',
                        },
                        '\n',
                        { text: 'Destinations autorisées', bold: true },
                        { text: '...' },
                        '\n',
                        { text: 'Hauteurs autorisées', bold: true },
                        {
                          text: h_max ? h_max + ' m' : 'Aucune donnée',
                        },
                        '\n',
                      ],
                    },
                  ],
                ],
              },
            },
          ],
        },
        // {
        //   image: 'logo',
        //   // width: 122,
        //   // height: 10,
        // },
      ],
      footer: {
        columns: [
          {
            image: 'logo',
            // width: 150,
            // height: 100,
            alignment: 'center',
          },
        ],
      },
      styles: {},
    }
  }
  const [lon, lat] = centroid
  dataRequest(
    process.env.REACT_APP_ABS_EXPORT_PATH +
      'exportJpg.php?lon=' +
      lon +
      '&lat=' +
      lat +
      '&zoom=' +
      16,
    function(mapResult) {
      Axios.get(
        `https://api-adresse.data.gouv.fr/reverse/?lon=${encodeURI(
          lon
        )}&lat=${encodeURI(lat)}&type=housenumber`
      ).then(adressResult => {
        pdfMake
          .createPdf(
            docdefinition({
              mapImg: mapResult,
              logo: pdf_logo,
              adress: adressResult.data.features[0].properties.label,
            })
          )
          .open()
      })
    }
  )
}

export default makePdf
