import React, { Component } from 'react'
import { connect } from 'react-redux'
import PluAvancementForm from './PluAvancementForm'

import { searchDepartement } from 'store/search/departementReducer'
import { exportPlu } from 'store/search/exportPluReducer'

class Search extends Component {
  state = {
    departement: '75',
  }

  handleDepartementChange = (e) => {
    const value = e.target.value
    this.setState({ departement: value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.exportPlu({ dep: this.state.departement })
  }

  onClose = (e) => {
    this.setState({ departement: '' })
    e.preventDefault()
    this.props.onClose()
  }

  render() {
    const { searchDepartement, exportPlu, ...otherProps } = this.props
    return (
      <>
        <PluAvancementForm
          {...otherProps}
          inputs={this.state}
          handleDepartementChange={this.handleDepartementChange}
          handleSubmit={this.handleSubmit}
          onClose={this.onClose}
        />
      </>
    )
  }
}

export default connect(null, { searchDepartement, exportPlu })(Search)
