import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getMapInfo } from '../../store/map/mapReducer'
import { openProject } from '../../store/project/openProjectReducer'
import { closeProject } from 'store/project/projectReducer'
import { logOut } from 'store/user/logOutReducer'
import { simulateProjectImplentation } from 'store/project/simulationReducer'
import { exportPdf } from 'store/project/pdfReducer'
// import { exportDxf } from 'store/project/dxfReducer'

import { getParcelleRules } from 'store/map/infoInteraction/infoInteractionReducer'

import ProjectComponent from './ProjectComponent'
import Loading from '../../common/components/Loading'
import { withRouter } from 'react-router'

class Project extends Component {
  state = {
    openedLayerDrawer: true,
    openedLegendDrawer: false,
    // expandedInteractionButtons: false,
    isMenuOpen: false,
    isSearchWindowOpen: false,
    isPluAvancementWindowOpen: false,
    isPluReglementWindowOpen: false,
    isCalibrationConfigWindowOpen: false,
    isSimulationResultWindowOpen: false,
    isRuleConfigWindowOpen: false,
    isGenerateWindowOpen: false,
    isChargeFonciereWindowOpen: false,
    isBilanPromoteurWindowOpen: false,
    isPluShpWindowOpen: false,
    isChartePromWindowOpen: false,
    anchorMenuEl: null,
    isPopupOpened: false,
  }

  handleMenuOpen = (e) => {
    this.setState({ isMenuOpen: true, anchorMenuEl: e.currentTarget })
  }

  handleMenuClose = (e) => {
    this.setState({ isMenuOpen: false, anchorMenuEl: null })
  }

  handleDrawerToggle = (drawer) => {
    // if (drawer === 'openedLayerDrawer' && this.state.openedLegendDrawer) {
    //   this.setState({ openedLegendDrawer: false })
    // }
    // if (drawer === 'openedLegendDrawer' && this.state.openedLayerDrawer) {
    //   this.setState({ openedLayerDrawer: false })
    // }
    this.setState({ [drawer]: !this.state[drawer] })
  }

  handleArrowBack = (e) => {
    this.props.closeProject()
    this.props.history.push(`/app/home`)
  }

  // handleInteractionButtonsToggle = e => {
  //   this.setState({
  //     expandedInteractionButtons: !this.state.expandedInteractionButtons,
  //   })
  // }

  handleGenerateWindow = (e) => {
    this.setState({ isGenerateWindowOpen: !this.state.isGenerateWindowOpen })
  }

  handleSearchWindow = (e) => {
    this.setState({ isSearchWindowOpen: !this.state.isSearchWindowOpen })
  }
  handlePluAvancementWindow = (e) => {
    this.setState({
      isPluAvancementWindowOpen: !this.state.isPluAvancementWindowOpen,
    })
  }

  handlePluReglementWindow = (e) => {
    this.setState({
      isPluReglementWindowOpen: !this.state.isPluReglementWindowOpen,
    })
  }

  handleChargeFonciereWindow = (e) => {
    this.setState({
      isChargeFonciereWindowOpen: !this.state.isChargeFonciereWindowOpen,
    })
  }

  handleBilanPromoteurWindow = (e) => {
    this.setState({
      isBilanPromoteurWindowOpen: !this.state.isBilanPromoteurWindowOpen,
    })
  }

  handleCalibrationConfigWindow = (e) => {
    this.handleMenuClose()
    this.setState({
      isCalibrationConfigWindowOpen: !this.state.isCalibrationConfigWindowOpen,
    })
  }

  handleSimulationResultWindow = (e) => {
    this.handleMenuClose()
    this.props.simulateProjectImplentation({
      projectId: parseInt(this.props.match.params.projectId, 10),
    })
    setTimeout(() => {
      this.setState({
        isSimulationResultWindowOpen: !this.state.isSimulationResultWindowOpen,
      })
    }, 500)
  }

  handleRuleConfigWindow = (e) => {
    this.handleMenuClose()
    this.setState({
      isRuleConfigWindowOpen: !this.state.isRuleConfigWindowOpen,
    })
  }

  exportToPdf = (e) => {
    this.handleMenuClose()
    this.props.exportPdf({
      projectId: parseInt(this.props.match.params.projectId, 10),
    })
  }

  exportToDXF = (e) => {
    this.handleMenuClose()
    window.open(
      process.env.REACT_APP_SERVER_API_PATH +
        'project/projectImplentation/dxfExport.php?projectId=' +
        parseInt(this.props.match.params.projectId, 10)
    )
  }

  handlePluToSHP = (e) => {
    this.setState({
      isPluShpWindowOpen: !this.state.isPluShpWindowOpen,
    })
  }

  handleCommuneCharte = (e) => {
    this.setState({
      isChartePromWindowOpen: !this.state.isChartePromWindowOpen,
    })
  }

  handleClosePopup = (e) => {
    this.setState({ isPopupOpened: false })
  }

  componentDidUpdate(prevProps) {
    if (this.props.lastPopupChange !== prevProps.lastPopupChange) {
      this.setState({ isPopupOpened: true }) // réinitialisation de l'ouverture du popup au changement de message
    }
  }

  componentDidMount() {
    // chargement des metadonnées du projet si celles-ci ne sont pas déjà chargées
    // l'ID est de type string car il provient de l'URL
    this.props.openProject({
      projectId: parseInt(this.props.match.params.projectId, 10),
    })

    // chargement des données concernant la carte (couches affichées)
    this.props.getMapInfo()
  }

  isValidProject = () =>
    // verifie l'existance de zone et de batiments
    Boolean(
      this.props.project.projectBuildings.features.length > 0 &&
        this.props.project.projectArea.features.length > 0
    )

  // componentDidMount() {
  //   console.log('componentDidMount')
  //   if (this.props.project.loaded) {
  //     console.log('loaded')
  //   }
  // }

  // componentWillUnmount() {
  //   console.log('componentwillUnmount')
  // }

  render() {
    const {
      project,
      mapLoaded,
      interaction,
      projectLoaded,
      logOut,
      popupText,
      getParcelleRules,
      isUserProject,
    } = this.props

    if (!isUserProject) {
      this.props.closeProject()
      this.props.history.push(`/app/home`)
    }

    if (projectLoaded && mapLoaded) {
      // dès que les données du projet et de la carte sont chargées, on affiche le composant

      return (
        <ProjectComponent
          {...project}
          interaction={interaction}
          {...this.state}
          logOut={logOut}
          handleMenuOpen={this.handleMenuOpen}
          handleMenuClose={this.handleMenuClose}
          handleArrowBack={this.handleArrowBack}
          handleDrawerToggle={this.handleDrawerToggle}
          handleSearchWindow={this.handleSearchWindow}
          handlePluAvancementWindow={this.handlePluAvancementWindow}
          handlePluReglementWindow={this.handlePluReglementWindow}
          handleCalibrationConfigWindow={this.handleCalibrationConfigWindow}
          handleSimulationResultWindow={this.handleSimulationResultWindow}
          handleRuleConfigWindow={this.handleRuleConfigWindow}
          handleGenerateWindow={this.handleGenerateWindow}
          handleChargeFonciereWindow={this.handleChargeFonciereWindow}
          handleBilanPromoteurWindow={this.handleBilanPromoteurWindow}
          getParcelleRules={getParcelleRules}
          exportToPdf={this.exportToPdf}
          exportToDXF={this.exportToDXF}
          handlePluToSHP={this.handlePluToSHP}
          handleCommuneCharte={this.handleCommuneCharte}
          isValidProject={this.isValidProject}
          isPopupOpened={this.state.isPopupOpened}
          handleClosePopup={this.handleClosePopup}
          popupMessage={popupText}
        />
      )
    } else {
      // en attente de récupération des données
      return <Loading />
    }
  }
}

const mapStateToProps = ({
  project,
  map,
  featureInfo,
  interaction,
  popupMessage: { text, instant },
}) => ({
  project,
  projectLoaded:
    project.openProject &&
    project.openProject.status &&
    project.openProject.status === 200,
  mapLoaded:
    map.getMapInfo && map.getMapInfo.status && map.getMapInfo.status === 200,
  interaction: map.interaction,
  popupText: text,
  lastPopupChange: instant,
  isUserProject: project.isUserProject,
})
const mapDispatchToProps = {
  getMapInfo,
  openProject,
  logOut,
  closeProject,
  simulateProjectImplentation,
  exportPdf,
  // exportDxf,
  getParcelleRules,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Project))
