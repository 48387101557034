import React from 'react'
import { withStyles, Grid } from '@material-ui/core'

import LayerLegend from './LayerLegend'

const styles = theme => ({})

const LegendContainer = ({ classes, data, ...layersListProps }) => {
  return (
    <Grid
      container
      style={{
        position: 'absolute',
        bottom: 0,
        backgroundColor: 'black',
        paddingLeft: 280,
        zIndex: 1100,
      }}
    >
      {[...data]
        .reverse()
        .map((thematic, idx) =>
          thematic.layers.map((layer, l_idx) =>
            layer.legend ? (
              <LayerLegend idx={l_idx} key={layer.layerId} layer={layer} />
            ) : null
          )
        )}
    </Grid>
  )
}

export default withStyles(styles)(LegendContainer)
