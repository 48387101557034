// permet de récupérer les entités à la localisation du pointeur et de les afficher
import { hoverableLayers } from 'common/mapStyle/toolTipRenderer'

const getHoveredElmts = (
  { features, srcEvent: { offsetX, offsetY } },
  props
) => {
  const hoveredElmt =
    features && features.find(f => hoverableLayers.includes(f.layer.id))
  if (hoveredElmt) {
    const {
      properties,
      layer: { id },
    } = hoveredElmt

    props.showHoveredInfo({ properties, x: offsetX, y: offsetY, layerId: id })
  }
}

export default getHoveredElmts
