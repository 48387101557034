import { getViewportFromBbox } from '../map/mapReducer'
import createDuck from '../createDuck'

const { reducer: findCommuneReducer, findCommune } = createDuck({
  namespace: 'batir/filter',
  actionName: 'findCommune',
  path: process.env.REACT_APP_API_PATH + 'map/findCommune.php',
  callback: function({ bbox }) {
    return (dispatch, getState) => {
      dispatch(getViewportFromBbox(bbox, 60, 15))
    }
  },
})

export default findCommuneReducer
export { findCommune }
