import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditDialogComponent from './EditDialogComponent'

class EditDialogContainer extends Component {
  render() {
    const { action, selectingNode } = this.props
    return (
      <EditDialogComponent
        open={action === 'changePolygon' && selectingNode}
        applyEdition={this.props.handlePolygonEdition('apply')}
        cancelEdition={this.props.handlePolygonEdition('cancel')}
      />
    )
  }
}

const mapStateToProps = ({ drawing: { action } }) => ({
  action,
})

export default connect(mapStateToProps)(EditDialogContainer)
