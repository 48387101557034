import createDuck from '../createDuck'

import { getParcelleInfo } from './infoInteraction/infoInteractionReducer'
import { getViewportFromBbox } from './mapReducer'
import { changeInteraction } from './mapReducer'

const { reducer: findParcelleReducer, findParcelle } = createDuck({
  namespace: 'batir/map',
  actionName: 'findParcelle',
  payloadName: 'search',
  path: process.env.REACT_APP_API_PATH + 'map/findParcelle.php',
  callback: function({ parcelleId, bbox }) {
    return (dispatch, getState) => {
      dispatch(changeInteraction('info'))
      dispatch(getViewportFromBbox(bbox, 60, 19))
      dispatch(getParcelleInfo({ parcelleId }))
    }
  },
})

export default findParcelleReducer
export { findParcelle }
