import createDuck from '../../createDuck'
import reduceReducers from 'reduce-reducers'
import DEFAULT_STATE from '../../DEFAULT_STATE'
import { refreshBuildings } from '../../project/refreshBuildingsReducer'

const { reducer: getParcelleInfoReducer, getParcelleInfo } = createDuck({
  namespace: 'batir/map/infoInteraction',
  actionName: 'getParcelleInfo',
  payloadName: 'geojson',
  path: process.env.REACT_APP_API_PATH + 'map/getParcelleInfo.php',
})

const { reducer: getParcelleRulesReducer, getParcelleRules } = createDuck({
  namespace: 'batir/map/rulesConfig',
  actionName: 'getParcelleRules',
  payloadName: 'rulesConfig',
  path: process.env.REACT_APP_API_PATH + 'map/getRulesParcelleInfo.php',
})

const { reducer: updateRulesReducer, updateRules } = createDuck({
  namespace: 'batir/map/rulesConfig',
  actionName: 'updateRules',
  path: process.env.REACT_APP_API_PATH + 'map/updateRulesParcelle.php',
  callback: function() {
    return (dispatch, getState) => {
      const { projectId } = getState().project
      dispatch(refreshBuildings({ projectId: projectId }))
    }
  },
})

const { reducer: updateRulesCommuneReducer, updateRulesCommune } = createDuck({
  namespace: 'batir/map/rulesConfig',
  actionName: 'updateRulesCommune',
  path: process.env.REACT_APP_API_PATH + 'map/updateRulesCommune.php',
  callback: function() {
    return (dispatch, getState) => {
      const { projectId } = getState().project
      dispatch(refreshBuildings({ projectId: projectId }))
    }
  },
})

const CLEAN_PARCELLE_INFO = 'batir/map/infoInteraction/CLEAN_PARCELLE_INFO'
const SHOW_HOVERED_INFO = 'batir/map/infoInteraction/SHOW_HOVERED_INFO'
const CLEAN_HOVERED_INFO = 'batir/map/infoInteraction/CLEAN_HOVERED_INFO'

const cleanParcelleReducer = function(
  state = DEFAULT_STATE.map.infoInteraction,
  action
) {
  switch (action.type) {
    case CLEAN_PARCELLE_INFO:
      return {}

    default:
      return state
  }
}

const showHoveredInfoReducer = function(
  state = DEFAULT_STATE.map.infoInteraction,
  action
) {
  switch (action.type) {
    case SHOW_HOVERED_INFO:
      return {
        ...state,
        hoveredInfo: action.payload,
      }

    case CLEAN_HOVERED_INFO:
      return {
        ...state,
        hoveredInfo: {},
      }

    default:
      return state
  }
}

const showHoveredInfo = ({ properties, x, y, layerId }) => ({
  type: SHOW_HOVERED_INFO,
  payload: { properties, x, y, layerId },
})

const cleanHoveredInfo = () => ({
  type: CLEAN_HOVERED_INFO,
})

const cleanParcelleInfo = () => ({
  type: CLEAN_PARCELLE_INFO,
})

export default reduceReducers(
  getParcelleInfoReducer,
  cleanParcelleReducer,
  showHoveredInfoReducer,
  getParcelleRulesReducer,
  updateRulesReducer,
  updateRulesCommuneReducer
)
export {
  cleanParcelleInfo,
  getParcelleInfo,
  showHoveredInfo,
  cleanHoveredInfo,
  getParcelleRules,
  updateRules,
  updateRulesCommune,
}
