import React, { Component } from 'react'

import { withStyles } from '@material-ui/core'

import { Dialog } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  dialogPaper: { overflow: 'visible', maxWidth: '90vw', maxHeight: '100vh' },
})

class BilanPromoteur extends Component {
  handleClose = () => {
    this.props.onClose()
  }

  render() {
    const { classes, ...otherProps } = this.props
    return (
      <Dialog
        {...otherProps}
        fullWidth
        onClose={this.handleClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <iframe
          id="Urbascope_Bilan_Promoteur"
          title="Urbascope Bilan Promoteur"
          height="900"
          src={process.env.REACT_APP_ABS_API_PATH + 'vues/bilanPromoteur.php'}
        ></iframe>
      </Dialog>
    )
    // <BilanPromoteurForm {...otherProps} handleClose={this.handleClose} />
  }
}

export default withStyles(styles)(BilanPromoteur)
