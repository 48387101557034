import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  TextField,
  Card,
  CardContent,
  Grid,
  CardHeader,
  CardActions,
  withStyles,
  Dialog,
  Typography,
} from '@material-ui/core'
import Autocomplete from 'common/components/Autocomplete'

const styles = theme => ({
  dialogPaper: { overflow: 'visible' },
  card: {
    overflow: 'visible',
  },
  cardHeader: {
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  cardHeaderTitle: {
    color: theme.palette.primary.contrastText,
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  errorMessage: {
    marginTop: theme.spacing(2),
  },
})

const ProjectForm = ({
  classes,
  inputs,
  handleChange,
  handleSubmit,
  handleAutocompleteChange,
  handleAutocompleteInputChange,
  autoCompleteOptions,
  isValidTitle,
  isValidDescription,
  isValidInputs,
  errorMessage,
  ...otherProps
}) => {
  const disabled = !inputs.commune
  return (
    <Dialog {...otherProps} fullWidth classes={{ paper: classes.dialogPaper }}>
      <Card className={classes.card}>
        <CardHeader
          color="primary"
          classes={{
            root: classes.cardHeader,
            title: classes.cardHeaderTitle,
          }}
          title="Créer un nouveau projet"
        />
        <form onSubmit={handleSubmit}>
          <CardContent>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={11}>
                <Autocomplete
                  onInputChange={handleAutocompleteInputChange}
                  onChange={handleAutocompleteChange}
                  options={autoCompleteOptions}
                  name="commune"
                  noOptionsMessage={() => 'aucune commune correspondante'}
                  placeholder="Chercher une commune..."
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end" spacing={2}>
              <Grid item xs={11}>
                <TextField
                  color="secondary"
                  fullWidth
                  required
                  disabled={disabled}
                  label="Titre"
                  value={inputs.title}
                  name="title"
                  onChange={handleChange}
                  error={!disabled && !isValidTitle}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end" spacing={2}>
              <Grid item xs={11}>
                <TextField
                  color="secondary"
                  fullWidth
                  multiline
                  disabled={disabled}
                  rows={4}
                  rowsMax={10}
                  label="Description"
                  value={inputs.descr}
                  name="descr"
                  onChange={handleChange}
                  error={!disabled && !isValidDescription}
                />
              </Grid>
            </Grid>

            <Typography
              className={classes.errorMessage}
              align="center"
              color="error"
            >
              {disabled && 'Pour commencer, choisissez une commune'}
              {!disabled &&
                !isValidTitle &&
                'Saisissez un titre inférieur à 25 caractères '}
              {!disabled &&
                !isValidDescription &&
                'La description doit inférieur à 40 caractères'}
            </Typography>

            {!disabled && errorMessage && (
              <Typography
                className={classes.errorMessage}
                align="center"
                color="error"
              >
                {errorMessage}
              </Typography>
            )}
          </CardContent>
          <CardActions classes={{ root: classes.cardActions }}>
            <Button
              type="submit"
              color="secondary"
              disabled={isValidInputs ? false : true}
            >
              Créer le projet
            </Button>
          </CardActions>
        </form>
      </Card>
    </Dialog>
  )
}

ProjectForm.defaultProps = {
  inputs: {},
}

ProjectForm.propTypes = {
  inputs: PropTypes.shape({
    commune: PropTypes.string,
    title: PropTypes.string,
    descr: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
}

export default withStyles(styles)(ProjectForm)
