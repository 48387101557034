import React, { Component } from 'react'
import { connect } from 'react-redux'
import GenerateForm from './GenerateForm'
import { GenerateBuilding } from '../../../store/drawing/drawingReducer'
import { calculSurfaceParEtage } from '../../../common/calc/calc.js'

class GenerateWindow extends Component {
  state = {
    hauteur: '10',
    emprise: '100',
    hParNiveau: 2.8,
    // --------logements
    nbStu: 12,
    nb2p: 7,
    nb3p: 5,
    nb4p: 5,
    nb5p: 4,
    nbComm: 1,
    rendLog: 94,
    surfLogStu: 28.4,
    surfLog2p: 42.9,
    surfLog3p: 63.3,
    surfLog4p: 84.3,
    surfLog5p: 106.1,
    surfCsstLog: 6,
    stStu: 1,
    st2p: 1,
    st3p: 2,
    st4p: 2,
    st5p: 2,
    surfStationn: 25,
    // -----------Bureaux
    rendBur: 97.5,
    nbEffectifBur: 100,
    nbEffectifBurFerme: 50,
    nbEffectifBurPart: 30,
    nbEffectifBurOuv: 20,
    nbSalleReunionBur: 0,
    surfBurFerme: 12,
    surfBurPart: 10,
    surfBurOuv: 7,
    surfBurReunion: 2,
    surfTranchSdp: 70,
    surfCsstBur: 20,
    errorMessage: null,
    generateBuildMsg: null,
    bati_is_log: true,
  }

  switchLogementBureaux = () => {
    this.setState({ bati_is_log: !this.state.bati_is_log })
  }

  getRuleValue = ruleName => {
    return this.props.rulesConfig[ruleName].value || null
  }

  setTheoricValues = () => {
    this.setState({
      hauteur: this.getRuleValue('h_max') ? this.getRuleValue('h_max') : 10,
      emprise: this.getRuleValue('ces') ? this.getRuleValue('ces') : 100,
    })
  }

  helperTextRuleValues = () => {
    const hmax = this.getRuleValue('h_max')
      ? this.getRuleValue('h_max') + ' m'
      : 'aucune'
    const emax = this.getRuleValue('ces')
      ? this.getRuleValue('ces') + ' %'
      : 'aucune'
    return {
      helpHauteur: 'Réglementation : ' + hmax,
      helpEmprise: 'Réglementation : ' + emax,
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  isCoherentHauteurs = () => {
    const { hauteur, hParNiveau } = this.state

    //verif si hauteur du bati >= hauteur d'un niveau
    return Number(hauteur) >= Number(hParNiveau)
  }
  isValidHauteur = () => {
    const { rulesConfig } = this.props
    const { hauteur } = this.state
    //verif si hauteur max autorisé >= hauteur du bati
    if (rulesConfig && !rulesConfig.h_max.value) {
      //si pas de restriction d'emprise: limite a 500m
      return Number('500') >= Number(hauteur)
    } else {
      return rulesConfig
        ? Number(rulesConfig.h_max.value) >= Number(hauteur)
        : false
    }
  }

  isValidHParNiveau = () => {
    const { hParNiveau } = this.state
    return hParNiveau >= 2 && hParNiveau <= 6
  }
  isValidEmprise = () => {
    const { rulesConfig } = this.props
    const { emprise } = this.state
    //verif si hauteur max autorisé >= hauteur du bati
    if (rulesConfig && !rulesConfig.ces.value) {
      //si pas de restriction d'emprise: limite a 100%
      return 100 >= Number(emprise)
    } else {
      return rulesConfig
        ? Number(rulesConfig.ces.value) >= Number(emprise)
        : false
    }
  }

  isValidForm = () => {
    return (
      this.isValidHParNiveau() &&
      this.isCoherentHauteurs() &&
      this.isValidHauteur() &&
      this.isValidEmprise()
    )
  }

  isSommeSurfLogValid = () => {
    // verifie si la somme de toute les logement on une surface inferieur a la surface totale
    return this.sommeSurfLog() <= this.surfParEtage()
  }

  isSommeSurfBurValid = () => {
    // verifie si la somme de toute les logement on une surface inferieur a la surface totale
    return this.sommeSurfBur() <= this.surfParEtage()
  }

  setErrorMsg = () => {
    let msg = ''
    if (!this.isCoherentHauteurs()) {
      msg += 'Hauteur de niveau supérieur a celle du bati \n'
    }
    if (!this.isValidEmprise()) {
      msg += "Limite d'emprise dépassée \n"
    }
    if (!this.isValidHauteur()) {
      msg += 'Limite de hauteur dépassée'
    }
    if (!this.isValidHParNiveau()) {
      msg += 'Hauteur par niveaux incorrect'
    }

    return msg === '' ? null : msg
  }

  handleChange = name => e => {
    let newValue = e.target.value
    if (name === 'emprise' || name === 'surfCsstLog') {
      if (newValue >= 100) {
        newValue = 100
      }
    }
    if (name.startsWith('nb') || name.startsWith('st')) {
      newValue = Math.trunc(newValue)
    }
    this.setState({ [name]: newValue })
  }

  handleSubmit = e => {
    const { hauteur, emprise } = this.state
    const { parcelGeom } = this.props
    e.preventDefault()
    this.props.onClose()

    this.setState({ generateBuildMsg: 'Chargement...' })
    this.props.GenerateBuilding(parcelGeom, hauteur, emprise)
    this.setState({ generateBuildMsg: null })
  }

  onClose = e => {
    e.preventDefault()
    this.props.onClose()
  }

  surfParEtage = () => {
    const { hauteur, emprise, hParNiveau } = this.state
    const { parcelProp } = this.props
    return calculSurfaceParEtage(hauteur, emprise, hParNiveau, parcelProp.aire)
  }

  sommeSurfLog = () => {
    const {
      surfLog5p,
      nb5p,
      surfLog4p,
      nb4p,
      surfLog3p,
      nb3p,
      surfLog2p,
      nb2p,
      surfLogStu,
      nbStu,
      surfCsstLog,
    } = this.state
    return (
      surfLog5p * nb5p +
      surfLog4p * nb4p +
      surfLog3p * nb3p +
      surfLog2p * nb2p +
      surfLogStu * nbStu +
      (this.surfParEtage() * surfCsstLog) / 100
    )
  }

  sommeNbStation = () => {
    const {
      st5p,
      st4p,
      st3p,
      st2p,
      stStu,
      nb5p,
      nb4p,
      nb3p,
      nb2p,
      nbStu,
    } = this.state
    return st5p * nb5p + st4p * nb4p + st3p * nb3p + st2p * nb2p + stStu * nbStu
  }

  sommeSurfBur = () => {
    const {
      nbEffectifBurFerme,
      nbEffectifBurPart,
      nbEffectifBurOuv,
      nbSalleReunionBur,
      surfBurFerme,
      surfBurPart,
      surfBurOuv,
      surfBurReunion,
      surfCsstBur,
    } = this.state
    return (
      nbEffectifBurFerme * surfBurFerme +
      nbEffectifBurPart * surfBurPart +
      nbEffectifBurOuv * surfBurOuv +
      nbSalleReunionBur * surfBurReunion +
      (this.surfParEtage() * surfCsstBur) / 100
    )
  }
  render() {
    const {
      gabaritConfig,
      rulesConfig,
      parcelProp,
      parcelGeom,
      loading,
      isSommeSurfLogValid,
      GenerateBuilding,
      generateBuildMsg,
      ...otherProps
    } = this.props
    return (
      <>
        {gabaritConfig && (
          <GenerateForm
            {...otherProps}
            loading={loading}
            isValidHauteur={this.isValidHauteur}
            isValidEmprise={this.isValidEmprise}
            isValidHParNiveau={this.isValidHParNiveau}
            isCoherentHauteurs={this.isCoherentHauteurs}
            isValidForm={this.isValidForm}
            isSommeSurfLogValid={this.isSommeSurfLogValid}
            isSommeSurfBurValid={this.isSommeSurfBurValid}
            inputs={this.state}
            surfParEtage={this.surfParEtage}
            sommeSurfLog={this.sommeSurfLog}
            sommeSurfBur={this.sommeSurfBur}
            sommeNbStation={this.sommeNbStation}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            onClose={this.onClose}
            gabaritConfig={gabaritConfig}
            aireParcel={!loading && parcelProp.aire}
            errorMessage={this.setErrorMsg()}
            generateBuildMsg={generateBuildMsg}
            switchLogementBureaux={this.switchLogementBureaux}
            bati_is_log={this.state.bati_is_log}
            ruleHelpers={this.helperTextRuleValues}
            setTheoricValues={this.setTheoricValues}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = ({
  project: { gabaritConfig },
  map: {
    infoInteraction: {
      rulesConfig,
      geojson,
      getParcelleRules,
      getParcelleInfo,
    },
  },
}) => ({
  gabaritConfig: gabaritConfig || null, // TODO faire un objet defaut pour les valeur par default
  rulesConfig: rulesConfig || null,
  parcelProp: geojson
    ? geojson.features
      ? geojson.features[0].properties
      : null
    : null,
  parcelGeom: geojson
    ? geojson.features
      ? geojson.features[0].geometry.coordinates
      : null
    : null,
  loading: !(
    getParcelleRules &&
    getParcelleInfo &&
    getParcelleRules.status === 200 &&
    getParcelleInfo.status === 200
  ),
})

export default connect(mapStateToProps, { GenerateBuilding })(GenerateWindow)
