import DEFAULT_STATE from '../DEFAULT_STATE'
import reduceReducers from 'reduce-reducers'

import projectAreaReducer from './projectAreaReducer'
import openProjectReducer from './openProjectReducer'
import buildingEditionReducer from './buildingEditionReducer'
import createProjectReducer from './createProjectReducer'
import deleteProjectReducer from './deleteProjectReducer'
import updateGabaritReducer from './updateGabaritReducer'
import simulationReducer from './simulationReducer'
import pdfReducer from './pdfReducer'
import refreshBuildingsReducer from './refreshBuildingsReducer'

const CLOSE_PROJECT = 'batir/project/CLOSE_PROJECT'

const projectReducer = (state = {}, action) => {
  switch (action.type) {
    case 'batir/user/LOG_OUT_REJECTED':
      return {}
    case CLOSE_PROJECT:
      return {}

    default:
      return state
  }
}

export const closeProject = () => ({
  type: CLOSE_PROJECT,
})

export default reduceReducers(
  projectReducer,
  openProjectReducer,
  projectAreaReducer,
  buildingEditionReducer,
  createProjectReducer,
  deleteProjectReducer,
  updateGabaritReducer,
  simulationReducer,
  pdfReducer,
  refreshBuildingsReducer,
  DEFAULT_STATE.project
)
