import React from 'react'
import ReactStreetview from 'react-streetview'

import { connect } from 'react-redux'

import Modal from '@material-ui/core/Modal'
import { withStyles } from '@material-ui/core'

import { closeStreetView } from 'store/map/mapReducer'

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: '60%',
    height: '70%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
})

class StreetView extends React.Component {
  handleClose = () => {
    this.props.closeStreetView()
  }

  render() {
    const { streetView, classes } = this.props
    // see https://developers.google.com/maps/documentation/javascript
    const googleMapsApiKey = process.env.REACT_APP_STREETVIEW_KEY // 'YOUR_API_KEY';

    // see https://developers.google.com/maps/documentation/javascript/3.exp/reference#StreetViewPanoramaOptions
    const streetViewPanoramaOptions = {
      position: {
        lat: streetView.lat || 48.8756483,
        lng: streetView.lng || 2.2994439,
      },
      pov: { heading: 100, pitch: 0 },
      zoom: 1,
    }

    // const svurl = 'http://maps.googleapis.com/maps/api/streetview?size=300x300&location='+streetView.lat+','+streetView.lng+'&key='+googleMapsApiKey
    // console.log(svurl)
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={streetView.openedStreetView}
        onClose={this.handleClose}
      >
        <div
          style={{
            top: '50%',
            left: '50%',
            transform: `translate(-50%, -50%)`,
            backgroundColor: '#eeeeee',
          }}
          className={classes.paper}
        >
          <ReactStreetview
            apiKey={googleMapsApiKey}
            streetViewPanoramaOptions={streetViewPanoramaOptions}
          />
        </div>
      </Modal>
    )
  }
}

export default withStyles(styles)(
  connect(({ map: { streetView } }) => ({ streetView }), { closeStreetView })(
    StreetView
  )
)
