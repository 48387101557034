import createDuck from '../createDuck'

const { reducer: createProjectReducer, createProject } = createDuck({
  namespace: 'batir/project',
  actionName: 'createProject',
  path: process.env.REACT_APP_API_PATH + 'project/createProject.php',
})

export default createProjectReducer
export { createProject }
