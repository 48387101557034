import React from 'react'
import InfoIcon from '@material-ui/icons/Info'

import {
  Button,
  TextField,
  Card,
  CardContent,
  Grid,
  CardHeader,
  CardActions,
  withStyles,
  Dialog,
  Typography,
  Tooltip,
  InputAdornment,
  Checkbox,
  MenuItem,
} from '@material-ui/core'

import Select from 'react-dropdown-select'

import { NumberFormatCustom } from '../../../common/components/NumberFormatter'

// import ChipInput from 'material-ui-chip-input'
// import TagSelector from './TagSelector'
//
const styles = theme => ({
  dialogPaper: {
    overflow: 'visible',
    maxWidth: '90vw',
    maxHeight: '100vh',
  },
  card: {
    overflow: 'visible',
    minHeight: '80vh',
  },
  cardHeader: {
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  cardHeaderTitle: {
    color: theme.palette.primary.contrastText,
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  errorMessage: {
    marginTop: theme.spacing(2),
  },
  switchDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'rgb(0,0,0)',
  },
})
// ----------- Mode de saisie en fonction du type d'entrée
const InputMode = (
  entryName,
  entry,
  handleRuleEntryChange,
  handleRuleSelectionChange,
  // destValue,
  options = []
) => {
  switch (entry.type) {
    case 'list': // Liste Simple a choix unique
      return (
        <TextField
          color="secondary"
          style={{ marginLeft: '15px' }}
          select
          value={entry.value}
          name={entryName}
          InputProps={{
            endAdornment: entry.unit && (
              <InputAdornment position="end">{entry.unit}</InputAdornment>
            ),
          }}
          onChange={handleRuleEntryChange(entry.type, entryName)}
          SelectProps={{
            IconComponent: 'div',
          }} // pour enlever la fleche de selection qui deplie la liste
        >
          {entry.options &&
            entry.options.map(choice => (
              <MenuItem key={choice.value} value={choice.value}>
                {choice.label}
              </MenuItem>
            ))}
        </TextField>
      )

    case 'bool': // Booléen => case a cocher
      return (
        <Checkbox
          checked={entry.value}
          onChange={handleRuleEntryChange(entry.type, entryName)}
          value={entryName}
          color="secondary"
        />
      )

    case 'text': // Champ de texte
      return (
        <TextField
          color="secondary"
          style={{ marginLeft: '15px' }}
          value={entry.value}
          name={entryName}
          onChange={handleRuleEntryChange(entry.type, entryName)}
        />
      )

    case 'float': // Nombre
      return (
        <TextField
          color="secondary"
          style={{ marginLeft: '15px' }}
          value={entry.value}
          name={entryName}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{entry.unit}</InputAdornment>
            ),
            inputComponent: NumberFormatCustom,
          }}
          onChange={handleRuleEntryChange(entry.type, entryName)}
          helperText={entry.helperText || ''}
        />
      )
    case 'selection': // Liste a choix multiple editable
      return (
        <Select
          fullWidth
          style={{ marginLeft: '15px', width: '100%' }}
          createNewLabel={'Ajouter {search}'}
          values={entry.value}
          multi
          create
          options={options}
          onChange={values =>
            handleRuleSelectionChange(entry.type, entryName, values)
          }
          placeholder={'Saisir une réglementation'}
          noDataRenderer={() => {
            return (
              <Typography style={{ textAlign: 'center' }}>
                Aucune option
              </Typography>
            )
          }}
        />
      )

    default:
      return null
  }
}

// ---------Formulaire de saisie----------------
const RulesForm = ({
  classes,
  parcelleId,
  inputs,
  updateRules,
  updateRulesCommune,
  handleClose,
  handleRuleEntryChange,
  handleRuleSelectionChange,
  switchGabaritType,
  errorMessage,
  ...otherProps
}) => {
  const distinctSections = [
    ...new Set(Object.entries(inputs).map(rule => rule[1].section)),
  ]
  // Object.values(inputs).forEach(rule => console.log(rule))
  return (
    <Dialog
      {...otherProps}
      fullWidth
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <Card className={classes.card}>
        <CardHeader
          className="w3-light-grey w3-center w3-padding-small w3-center w3-margin "
          color="primary"
          classes={{
            root: classes.cardHeader,
            title: classes.cardHeaderTitle,
          }}
          title={'Saisie de la réglementation de la parcelle n° ' + parcelleId}
        />

        <CardContent style={{ maxHeight: '70vh', overflow: 'auto' }}>
          <Grid container direction="row" alignItems="flex-start" spacing={2}>
            {distinctSections.map(section => {
              return (
                <Grid container item xs={12} key={section}>
                  <Grid item xs={12}>
                    <Typography variant="h6" align="left">
                      {section}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    {Object.entries(inputs)
                      .filter(rule => rule[1].section === section)
                      .map((
                        [entryName, entry],
                        idx // pour chaque reglementation (clé,valeur & index)
                      ) => (
                        <Grid
                          container
                          item
                          xs={6}
                          direction="row"
                          justify="flex-start"
                          alignItems="center"
                          key={idx}
                        >
                          <Grid item xs={8}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                              }}
                            >
                              <Typography style={{ textAlign: 'right' }}>
                                {entry.label}
                              </Typography>
                              {entry.aide && (
                                <Tooltip
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  title={entry.aide}
                                  placement="bottom"
                                >
                                  <InfoIcon style={{ marginLeft: '0.5em' }} />
                                </Tooltip>
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <div style={{ display: 'flex' }}>
                              {InputMode(
                                entryName,
                                entry,
                                handleRuleEntryChange,
                                handleRuleSelectionChange,
                                entry.options || []
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              )
            })}

            {/* 
            <Grid
              container
              item
              xs={6}
              direction="column"
              alignItems="flex-start"
              spacing={2}
            >
              <Typography align="center">Reglementation</Typography>
              {Object.entries(inputs).map(
                (
                  [entryName, entry],
                  idx // pour chaque reglementation (clé,valeur & index)
                ) =>
                  entry.type !== 'bool' && (
                    <Grid
                      container
                      key={idx}
                      item
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      xs={12}
                    >
                      <Grid item xs={6}>
                        <Typography style={{ textAlign: 'right' }}>
                          {entry.label}
                        </Typography>
                      </Grid>
                      <Grid
                        xs={6}
                        item
                        container
                        direction="row"
                        alignItems="flex-start"
                      >
                        {InputMode(
                          entryName,
                          entry,
                          handleRuleEntryChange,
                          handleRuleSelectionChange,
                          // inputs.destination.value ,
                          entry.options || []
                        )}
                      </Grid>
                    </Grid>
                  )
              )}
            </Grid>

            <Grid // ------------------------ Secteurs -----------------------
              container
              item
              xs={6}
              direction="column"
              alignItems="flex-start"
              spacing={1}
            >
              <Typography align="center">Prescriptions/Secteurs</Typography>
              {Object.entries(inputs).map(
                ([entryName, entry], idx) =>
                  entry.type === 'bool' && (
                    <Grid
                      container
                      key={idx}
                      item
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      xs={12}
                    >
                      <Grid item xs={10}>
                        <Typography style={{ textAlign: 'right' }}>
                          {entry.label}
                        </Typography>
                      </Grid>
                      <Grid
                        xs={2}
                        item
                        container
                        direction="row"
                        alignItems="flex-start"
                      >
                        {InputMode(
                          entryName,
                          entry,
                          handleRuleEntryChange,
                          handleRuleSelectionChange,
                          // inputs.destination.value ,
                          entry.options || []
                        )}
                      </Grid>
                    </Grid>
                  )
              )}
            </Grid> */}

            {errorMessage ? (
              <Typography
                className={classes.errorMessage}
                align="center"
                color="error"
              >
                {errorMessage}
              </Typography>
            ) : null}
          </Grid>
        </CardContent>
        <CardActions classes={{ root: classes.cardActions }}>
          <Button
            onClick={updateRules}
            variant="contained"
            color="secondary"
            className={classes.button}
          >
            Appliquer les changements sur la parcelle
          </Button>
          <Button
            onClick={updateRulesCommune}
            variant="contained"
            color="secondary"
            className={classes.button}
          >
            Appliquer les changements sur le zonage communal
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  )
}

export default withStyles(styles)(RulesForm)
