import createDuck from '../createDuck'
import reduceReducers from 'reduce-reducers'
import { updatePopupMessage } from '../popup/popupReducer'
// import { refreshBuildings } from '../project/refreshBuildingsReducer'

const namespace = 'batir/project'
const payloadName = 'projectBuildings'

const { reducer: insertBuildingReducer, insertBuilding } = createDuck({
  namespace,
  actionName: 'insertBuilding',
  payloadName,
  path: process.env.REACT_APP_API_PATH + 'project/building/insertBuilding.php',
  callback: function(data) {
    return (dispatch, getState) => {
      console.log(data)
      if (data.warning) {
        dispatch(updatePopupMessage(data.warning))
      }
      if (data.error) {
        dispatch(updatePopupMessage(data.error))
      }
      // const { projectId } = getState().project
      // dispatch(refreshBuildings({ projectId: projectId }))
    }
  },
})

const { reducer: updateBuildingReducer, updateBuilding } = createDuck({
  namespace,
  actionName: 'updateBuilding',
  payloadName,
  path: process.env.REACT_APP_API_PATH + 'project/building/updateBuilding.php',
  callback: function(data) {
    return (dispatch, getState) => {
      if (data.warning) {
        dispatch(updatePopupMessage(data.warning))
      }
      if (data.error) {
        dispatch(updatePopupMessage(data.error))
      }
      // const { projectId } = getState().project
      // dispatch(refreshBuildings({ projectId: projectId }))
    }
  },
})

const { reducer: deleteBuildingReducer, deleteBuilding } = createDuck({
  namespace,
  actionName: 'deleteBuilding',
  payloadName,
  path: process.env.REACT_APP_API_PATH + 'project/building/deleteBuilding.php',
})

export default reduceReducers(
  insertBuildingReducer,
  updateBuildingReducer,
  deleteBuildingReducer
)
export { insertBuilding, updateBuilding, deleteBuilding }
