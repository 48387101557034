import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  //TextField,
  Card,
  CardContent,
  Grid,
  CardHeader,
  CardActions,
  withStyles,
  Dialog,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core'

import Autocomplete from 'common/components/Autocomplete'

import FilterContent from '../LeftPanel/FilterContent'

const styles = (theme) => ({
  dialogPaper: { overflow: 'visible' },
  card: {
    overflow: 'visible',
  },
  cardHeader: {
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  cardHeaderTitle: {
    color: theme.palette.primary.contrastText,
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  errorMessage: {
    marginTop: theme.spacing(2),
  },
  button: {
    backgroundColor: 'rgb(0,0,0)',
  },
})

const SearchForm = ({
  classes,
  inputs,
  handleSubmit,
  handleCommuneChange,
  handleSectionChange,
  handleNumeroChange,
  handleCommuneInputChange,
  handleSectionInputChange,
  handleNumeroInputChange,
  handleViewOnCom,
  communeOptions,
  sectionOptions,
  numeroOptions,
  errorMessage,
  handleTabChange,
  // isLoadingSections,
  // isLoadingNumeros,
  ...otherProps
}) => {
  return (
    <Dialog {...otherProps} fullWidth classes={{ paper: classes.dialogPaper }}>
      <Card className={classes.card}>
        <CardHeader
          color="primary"
          classes={{
            root: classes.cardHeader,
            title: 'w3-light-grey w3-center w3-border-bottom w3-padding',
          }}
          title="Rechercher une parcelle"
        />
        <CardContent>
          <Tabs
            value={inputs.tabValue}
            onChange={handleTabChange}
            indicatorColor="secondary"
            // textColor="primary"
            variant="fullWidth"
            style={{ marginBottom: 20 }}
          >
            <Tab label="Simple" />
            <Tab label="Avancée" />
          </Tabs>
          <Grid container spacing={8} alignItems="center">
            <Grid item xs={12}>
              <Autocomplete
                onInputChange={handleCommuneInputChange}
                onChange={handleCommuneChange}
                options={communeOptions}
                name="commune"
                noOptionsMessage={() => 'aucune commune correspondante'}
                placeholder="Chercher une commune..."
              />
            </Grid>
          </Grid>
          {inputs.tabValue === 0 && (
            <div>
              <Grid container alignItems="flex-end" spacing={4}>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    onInputChange={handleSectionInputChange}
                    onChange={handleSectionChange}
                    options={sectionOptions}
                    name="section"
                    noOptionsMessage={() =>
                      /*isLoadingSections ? 'chargement...' :*/ 'aucune section'
                    }
                    placeholder="Section cadastral"
                    isDisabled={inputs.commune.length === 0}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    onInputChange={handleNumeroInputChange}
                    onChange={handleNumeroChange}
                    options={numeroOptions}
                    name="numero"
                    noOptionsMessage={() => 'aucun numero'}
                    placeholder="Numéro cadastral"
                    isDisabled={inputs.section.length === 0}
                  />
                </Grid>
              </Grid>
              {errorMessage ? (
                <Typography
                  className={classes.errorMessage}
                  align="center"
                  color="error"
                >
                  {errorMessage}
                </Typography>
              ) : null}
            </div>
          )}
          {inputs.tabValue === 1 &&
            (!inputs.commune ? (
              <Typography
                className="w3-padding-small w3-blue"
                align="center"
                style={{ marginTop: 20 }}
              >
                Veuillez selectionner une commune
              </Typography>
            ) : (
              <FilterContent
                handleViewOnCom={handleViewOnCom}
                commune={inputs.commune}
              />
            ))}
        </CardContent>
        <CardActions classes={{ root: classes.cardActions }}>
          {inputs.tabValue === 0 && (
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="secondary"
              className={classes.button}
              disabled={!(inputs.commune && inputs.section && inputs.numero)}
            >
              Rechercher la parcelle
            </Button>
          )}
        </CardActions>
      </Card>
    </Dialog>
  )
}

SearchForm.defaultProps = {
  inputs: {},
}

SearchForm.propTypes = {
  inputs: PropTypes.shape({
    commune: PropTypes.string,
    section: PropTypes.string,
    numero: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  handleSubmit: PropTypes.func,
}

export default withStyles(styles)(SearchForm)
