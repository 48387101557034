// permet de bloquer les interaction lorsqu'on clique sur un element du geocodeur

function geocoderEventBlock(className) {
  return Boolean(
    className !== 'mapboxgl-ctrl-icon mapboxgl-ctrl-fullscreen' &&
      className !== 'mapboxgl-ctrl-geocoder--suggestion-address' &&
      className !== 'mapboxgl-ctrl-geocoder--input' &&
      className !== 'mapboxgl-ctrl-geocoder--suggestion-title' &&
      className.constructor.name !== 'SVGAnimatedString'
  )
}

function fullScreenEventBlock(className) {
  return Boolean(
    className !== 'mapboxgl-ctrl-icon mapboxgl-ctrl-fullscreen' &&
      className !== 'mapboxgl-ctrl-icon mapboxgl-ctrl-shrink'
  )
}

function view2d3dEventBlock(className) {
  return Boolean(
    className !==
      'MuiTypography-root MuiTypography-body1 MuiTypography-colorSecondary' &&
      className.constructor.name !== 'SVGAnimatedString'
  )
}

function SatelliteSwitcherEventBlock(className) {
  return Boolean(
    !className.startsWith('Connect(SatelliteSwitcher)-miniature') &&
      className.constructor.name !== 'SVGAnimatedString'
  )
}

function GeolocateControlEventBlock(className) {
  return Boolean(className !== 'mapboxgl-ctrl-icon mapboxgl-ctrl-geolocate')
}

export function customMapControlsEventBlocker(className) {
  return Boolean(
    geocoderEventBlock(className) &&
      fullScreenEventBlock(className) &&
      view2d3dEventBlock(className) &&
      GeolocateControlEventBlock(className) &&
      SatelliteSwitcherEventBlock(className)
  )
}
