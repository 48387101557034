import createDuck from '../createDuck'

const { reducer: getUserContactsReducer, getUserContacts } = createDuck({
  namespace: 'batir/user',
  actionName: 'getUserContacts',
  payloadName: 'userContacts',
  path: process.env.REACT_APP_API_PATH + 'user/getUserContacts.php',
})

export default getUserContactsReducer
export { getUserContacts }
