import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function BilanPromoteurIcon(props) {
  return (
    <SvgIcon {...props} style={{ fontSize: '1.7rem' }} viewBox="0 0 64 64">
      <image
        width={64}
        height={64}
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAw1BMVEUAAAAkJCRjY2M8PDwy MjJtbW3BwcEwMDC7u7v16N3ux6PrpGT2zan64cv99e7pjTn54Mv////43sb////42b3////20bD/ 9+/txaP67OcqKip0dHSGhoa6urrOysbtuo7suo7cz8X669rwr3fwrXL66tr55tP///9/f3+7u7t3 d3fyvpD87+P307P2z6zmfiL++fX65NDup2j76tzpjj7759fohzHnhS754MrnginngSf//v742r/m fyTmfyP+/Pv20rFLDMysAAAAJ3RSTlMA5N/z0N/+1e/9/f7f6Jr+tQLCB84R1yD7NeDb3+zx/fxg ZuztbYX1vmseAAAAAWJLR0QR4rU9ugAAAAd0SU1FB+QBHBUFO7A/TlkAAAC/SURBVDjL1dDXDoIw FIDhKuLEvSdOcNe91/s/lSUiMe0pNGnQ+F9x8eUcThHyq0CQTgmBUDXphuGIIDRBCUFQghCSMAQk B7KSBxnJhbRk4Whsp3hAJ/UrcEL1o9V/dXVUBtJrpzNSTGDiHJPiMpC+Wn6ir3CxJC+zsmBC07Rk ij9xvcFO6UzWZfV293b7XN71Hw/HlzsViu7HnC9Xy91KZa+r7w8LVqqfV9fqbI1mS8e43UECdXv9 gYhDyDDsjyfBJI4iMuUBEgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wMS0yOVQwNDowNTo1OS0w NzowMGJfSXIAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDEtMjlUMDQ6MDU6NTktMDc6MDATAvHO AAAAAElFTkSuQmCC"
      />
    </SvgIcon>
  )
}
