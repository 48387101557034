import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Paper, Zoom } from '@material-ui/core'

const styles = theme => ({
  paper: {
    zIndex: 1101,
    padding: theme.spacing(2),
    position: 'absolute',
    bottom: '12%',
    left: 0,
    right: 0,
    margin: 'auto',
    width: '60%',
  },
})

const HelpBox = ({ open, classes, children }) => {
  return (
    <Zoom in={open} direction="up">
      <Paper elevation={4} className={classes.paper}>
        {children}
      </Paper>
    </Zoom>
  )
}

HelpBox.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default withStyles(styles)(HelpBox)
