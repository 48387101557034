import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function ParcelCouchesIcon(props) {
  return (
    <SvgIcon {...props} style={{ fontSize: '1.7rem' }} viewBox="0 0 64 64">
      <image
        width={64}
        height={64}
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAQAAAC0NkA6AAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN RQfkAhASEjFdUPA8AAAAP0lEQVRYw+3UwQ0AIAzDwIAYHCanI/BqBcS3gH+WgKc1zYrIzo/0/MRP kaFVkQFwBVZvGmH1gBNWbxph9cBZAIm2BiWn69/bAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTAy LTE3VDAxOjE4OjQ5LTA3OjAwV+SFCQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wMi0xN1QwMTox ODo0OS0wNzowMCa5PbUAAAAASUVORK5CYII="
      />
    </SvgIcon>
  )
}

export function ParcellaireIcon(props) {
  return (
    <SvgIcon {...props} style={{ fontSize: '1.7rem' }} viewBox="0 0 64 64">
      <image
        width={64}
        height={64}
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwAgMAAAAqbBEUAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAADFBMVEUAAADmXyKwsLD///+/ IHXBAAAAAXRSTlMAQObYZgAAAAFiS0dEAxEMTPIAAAAHdElNRQfjChcTIi6+fuLNAAAAV0lEQVQo z2NgoBiIhoDI0AAsnLBVQICVg1MPUwOI5GIgkgPWi42zaiouDm49EJqGHJhPsXCgoYOFE8KwAFkP ggMECxBhgYfDGooIZnQOyKapWDm49FAEAMZRPAv86YXDAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE5 LTEwLTI0VDAyOjM0OjQ2LTA3OjAw7TlpXQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOS0xMC0yNFQw MjozNDo0Ni0wNzowMJxk0eEAAAAASUVORK5CYII="
      />
    </SvgIcon>
  )
}
