import React from 'react'

export const hoverableLayers = [
  'bati_idf',
  'dvf_idf_2014',
  'dvf_idf_2015',
  'dvf_idf_2016',
  'dvf_idf_2017',
  'dvf_idf_2018',
  'permis_idf',
  'bpe',
  'metro_idf',
  'gare_idf',
  'services_public',
  'projet_secteur_operationnel_idf',
  /* 
    'pprn_zone_alea_idf',
  'pprn_perimetre_idf',
  'pprn_zone_idf',
  'pprn_enjeu_idf',
  'pprt_zone_alea_idf',
  'pprt_perimetre_idf',
  'pprt_zone_idf',
  'pprt_enjeu_idf',
  */
]

const toolTipRenderer = (props) => {
  const { hoveredInfo } = props
  if (hoveredInfo) {
    const { properties, x, y, layerId } = hoveredInfo

    return (
      properties && (
        <div
          style={{
            position: 'absolute',
            margin: '8px',
            padding: '4px',
            borderRadius: '4px',
            background: 'rgb(33, 150, 243)',
            color: '#fff',
            maxWidth: '600px',
            fontSize: '10px',
            zIndex: 9,
            pointerEvents: 'none',
            left: x,
            top: y,
          }}
        >
          {layerId.startsWith('dvf_idf_') && (
            <React.Fragment>
              <div>
                Valeur fonciere :{' '}
                {properties.valeur_fonciere
                  ? Math.round(properties.valeur_fonciere)
                      .toString()
                      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')
                  : 'Pas de donnée'}
              </div>
              <div>
                Type local :{' '}
                {properties.type_local
                  ? properties.type_local
                  : 'Pas de donnée'}
              </div>
              <div>
                Nature de la mutation :{' '}
                {properties.nature_mutation
                  ? properties.nature_mutation
                  : 'Pas de donnée'}
              </div>
              <div>
                Date de la mutation :{' '}
                {properties.date_mutation
                  ? properties.date_mutation
                  : 'Pas de donnée'}
              </div>
              <div>
                Surface du terrain :{' '}
                {properties.surface_terrain
                  ? new Intl.NumberFormat('fr-FR').format(
                      properties.surface_terrain
                    ) + 'm²'
                  : 'Pas de donnée'}
              </div>
            </React.Fragment>
          )}

          {layerId === 'permis_idf' && (
            <React.Fragment>
              <div>
                Type de projet :{' '}
                {properties.type_projet
                  ? properties.type_projet
                  : 'Pas de donnée'}
              </div>
              <div>
                Année de depôt :{' '}
                {properties.annee_depot
                  ? properties.annee_depot
                  : 'Pas de donnée'}
              </div>
            </React.Fragment>
          )}

          {layerId === 'bpe' && (
            <React.Fragment>
              <div>
                {properties.modlibelle ? properties.modlibelle : 'Non défini'}
              </div>
            </React.Fragment>
          )}

          {layerId === 'bati_idf' && (
            <React.Fragment>
              <div>
                Catégorie du bâti :{' '}
                {properties.cat_bati ? properties.cat_bati : 'Pas de donnée'}
              </div>
              <div>
                Hauteur du bâti :{' '}
                {properties.hauteur
                  ? properties.hauteur + ' m'
                  : 'Pas de donnée'}
              </div>
              <div>
                Hauteur minimale :{' '}
                {properties.h_min ? properties.h_min + ' m' : 'Pas de donnée'}
              </div>
              <div>
                Hauteur maximale :{' '}
                {properties.h_max ? properties.h_max + ' m' : 'Pas de donnée'}
              </div>
              <div>
                Hauteur médiane :{' '}
                {properties.h_med ? properties.h_med + ' m' : 'Pas de donnée'}
              </div>
              <div>
                Hauteur moyenne :{' '}
                {properties.h_moy ? properties.h_moy + ' m' : 'Pas de donnée'}
              </div>
              <div>
                Type de bâti :{' '}
                {properties.type_bati ? properties.type_bati : 'Pas de donnée'}
              </div>
            </React.Fragment>
          )}

          {(layerId === 'metro_idf' || layerId === 'gare_idf') && (
            <React.Fragment>
              <div>
                Station : {properties.lib ? properties.lib : 'Pas de donnée'}
              </div>
            </React.Fragment>
          )}

          {layerId === 'services_public' && (
            <React.Fragment>
              <div>
                Nom : {properties.nom ? properties.nom : 'Pas de donnée'}
              </div>
              <div>
                Adresse :{' '}
                {properties.adresse ? properties.adresse : 'Pas de donnée'}
              </div>
              <div>
                Telephone : {properties.tel ? properties.tel : 'Pas de donnée'}
              </div>
              <div>
                Mail : {properties.mail ? properties.mail : 'Pas de donnée'}
              </div>
            </React.Fragment>
          )}

          {/* {layerId === 'projet_secteur_operationnel_idf' && (
            <React.Fragment>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '7px',
                  // width: '200%',
                }}
              >
                <div
                  style={{
                    width: '50%',
                  }}
                >
                  <div>
                    {'Etat avancement du secteur : ' +
                      (properties.etat_secteur
                        ? properties.etat_secteur
                        : 'pas de donnée')}
                  </div>
                  <div>
                    {'Nature du secteur : ' +
                      (properties.type_secteur
                        ? properties.type_secteur
                        : 'pas de donnée')}
                  </div>
                  <div>
                    {'Nom du secteur : ' +
                      (properties.lib ? properties.lib : 'pas de donnée')}
                  </div>
                  <div>
                    {'Année achèvement du projet : ' +
                      (properties.annee_achevement
                        ? properties.annee_achevement
                        : 'pas de donnée')}
                  </div>
                  <div>
                    {'Surface dédiée aux logements : ' +
                      (properties.m2_log
                        ? properties.m2_log + 'm²'
                        : 'pas de donnée')}
                  </div>
                  <div>
                    {'Surface dédiée aux logements sociaux : ' +
                      (properties.m2_log_soc
                        ? properties.m2_log_soc + 'm²'
                        : 'pas de donnée')}
                  </div>
                  <div>
                    {'Surface dédiée aux bureaux : ' +
                      (properties.m2_bur
                        ? properties.m2_bur + 'm²'
                        : 'pas de donnée')}
                  </div>
                  <div>
                    {'Surface dédiée aux locaux activité : ' +
                      (properties.m2_act
                        ? properties.m2_act + 'm²'
                        : 'pas de donnée')}
                  </div>
                  <div>
                    {'Surface dédiée aux équipements : ' +
                      (properties.m2_eq
                        ? properties.m2_eq + 'm²'
                        : 'pas de donnée')}
                  </div>
                  <div>
                    {'Surface dédiée aux commerces : ' +
                      (properties.m2_com
                        ? properties.m2_com + 'm²'
                        : 'pas de donnée')}
                  </div>
                </div>

                <div
                  style={{
                    width: '50%',
                    marginLeft: '5px',
                  }}
                >
                  <div>
                    {'Surface dédiée aux hôtels : ' +
                      (properties.m2_hot
                        ? properties.m2_hot + 'm²'
                        : 'pas de donnée')}
                  </div>
                  <div>
                    {'Surface dédiée aux espaces verts : ' +
                      (properties.m2_evp
                        ? properties.m2_evp + 'm²'
                        : 'pas de donnée')}
                  </div>
                  <div>
                    {'Surface totale : ' +
                      (properties.m2_tot
                        ? properties.m2_tot + 'm²'
                        : 'pas de donnée')}
                  </div>
                  <div>
                    {'Nombre de logements : ' +
                      (properties.nb_log ? properties.nb_log : 'pas de donnée')}
                  </div>
                  <div>
                    {'Nombre dédié de logements sociaux : ' +
                      (properties.nb_log_soc
                        ? properties.nb_log_soc
                        : 'pas de donnée')}
                  </div>
                  <div>
                    {'Nombre de logements résiduels : ' +
                      (properties.nb_log_r_1
                        ? properties.nb_log_r_1
                        : 'pas de donnée')}
                  </div>
                  <div>
                    {'Nombre de logement calculée : ' +
                      (properties.nb_log_cal
                        ? properties.nb_log_cal
                        : 'pas de donnée')}
                  </div>
                  <div>
                    {'Surface dédiée aux logements calculée : ' +
                      (properties.m2_log_cal
                        ? properties.m2_log_cal + 'm²'
                        : 'pas de donnée')}
                  </div>
                  <div>
                    {'Surface activité calculée (bureau, commerce, activité) : ' +
                      (properties.m2_act_cal
                        ? properties.m2_act_cal + 'm²'
                        : 'pas de donnée')}
                  </div>
                  <div>
                    {'Surface totale calculée : ' +
                      (properties.m2_tot_cal
                        ? properties.m2_tot_cal + 'm²'
                        : 'pas de donnée')}
                  </div>
                </div>

              </div>
            </React.Fragment>
          )} */}
        </div>
      )
    )
  }
}

export default toolTipRenderer
