import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { Provider } from 'react-redux'

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
// import indigo from '@material-ui/core/colors/indigo'
// import orange from '@material-ui/core/colors/orange'
import red from '@material-ui/core/colors/red'

import store from './store'

import Login from './routes/Login'
import Home from './routes/Home/Home'
import PrivateRoute from './common/components/PrivateRoute'
import Project from './routes/Project/Project'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ['"Raleway"', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      light: 'rgb(255,255,255)',
      main: 'rgb(255,255,255)',
      dark: 'rgb(50,50,50)',
      contrastText: 'rgb(150,150,150)',
    },
    secondary: {
      main: '#000000',
    },
    error: red,
  },
})

const App = () => (
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <Router basename="/app">
        <Switch>
          <Route exact path="/login" component={Login} />
          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute exact path="/project/:projectId" component={Project} />
          <Route>
            <Redirect to="/login" />
          </Route>
        </Switch>
      </Router>
    </MuiThemeProvider>
  </Provider>
)

export default App
