import DEFAULT_STATE from '../DEFAULT_STATE'
import {
  insertBuilding,
  updateBuilding,
  // deleteBuilding,
} from '../project/buildingEditionReducer'
import { updatePopupMessage } from '../popup/popupReducer'
import bufferParcelToBati from './bufferAlgorithm'
import {
  area,
  buffer,
  simplify,
  polygon as newTurfPolygon,
  toWgs84,
} from '@turf/turf'

// Actions
const SET_BUILDING = 'batir/drawing/SET_BUILDING'
const SET_BUILDING_POLYGON = 'batir/drawing/SET_BUILDING_POLYGON'
const BUFFER_BUILDING_MINUS = 'batir/drawing/BUFFER_BUILDING_MINUS'
const SET_BUILDING_HEIGHT = 'batir/drawing/SET_BUILDING_HEIGHT'
const CLEAN_BUILDING = 'batir/drawing/CLEAN_BUILDING'
const GENERATE_BUILDING = 'batir/drawing/GENERATE_BUILDING'
const SET_MEASURE_DISTANCE_VALUE = 'batir/drawing/SET_MEASURE_DISTANCE_VALUE'
const SET_MEASURE_SURFACE_VALUE = 'batir/drawing/SET_MEASURE_SURFACE_VALUE'

const CHOOSE_ACTION = 'batir/drawing/CHOOSE_ACTION'

// Reducer
export default function(state = DEFAULT_STATE.drawing, action) {
  switch (action.type) {
    case 'batir/user/LOG_OUT_REJECTED':
      return { ...DEFAULT_STATE.drawing }

    case SET_BUILDING:
      return { ...state, ...action.payload }

    case SET_BUILDING_POLYGON:
      return { ...state, polygon: action.payload.polygon }

    case BUFFER_BUILDING_MINUS:
      return { ...state }

    case SET_BUILDING_HEIGHT:
      return { ...state, height: action.payload.height }

    case CLEAN_BUILDING:
      return {}

    case GENERATE_BUILDING:
      return { ...state, ...action.payload }

    case CHOOSE_ACTION:
      return { ...state, action: action.payload.action }

    case SET_MEASURE_DISTANCE_VALUE:
      return { ...state, measureDistance: action.payload }
    case SET_MEASURE_SURFACE_VALUE:
      return { ...state, measureSurface: action.payload }

    default:
      return state
  }
}

// Action creator
export const setBuilding = ({ buildingId, polygon, height }) => ({
  type: SET_BUILDING,
  payload: { buildingId, polygon, height },
})

export const setDistanceValue = value => ({
  type: SET_MEASURE_DISTANCE_VALUE,
  payload: value,
})

export const setSurfaceValue = value => ({
  type: SET_MEASURE_SURFACE_VALUE,
  payload: value,
})

export const setBuildingPolygon = polygon => {
  return (dispatch, getState) => {
    dispatch({ type: SET_BUILDING_POLYGON, payload: { polygon } })

    if (getState().drawing.buildingId) {
      // édition du bâti
      const {
        drawing: { height, buildingId },
        project: { projectId },
      } = getState()
      dispatch(
        updateBuilding({
          projectId,
          polygon,
          height,
          buildingId,
        })
      )
      dispatch(cleanBuilding())
    }
  }
}

export const setBuildingHeight = height => {
  return (dispatch, getState) => {
    dispatch({ type: SET_BUILDING_HEIGHT, payload: { height } })

    const {
      drawing: { polygon, buildingId },
      project: { projectId },
    } = getState()

    if (getState().drawing.buildingId) {
      dispatch(
        updateBuilding({
          projectId,
          polygon,
          height,
          buildingId,
        })
      )
    } else {
      dispatch(
        insertBuilding({
          height,
          polygon,
          projectId,
        })
      )
    }
    dispatch(cleanBuilding())
  }
}

export const minusBuildingPolygon = bufferValue => {
  return (dispatch, getState) => {
    dispatch({ type: BUFFER_BUILDING_MINUS, payload: { bufferValue } })

    const {
      drawing: { height, polygon, buildingId },
      project: { projectId },
    } = getState()

    const simpoly = simplify(newTurfPolygon([polygon], {}), {
      tolerance: 0.00001,
      highQuality: false,
      mutate: false,
    })
    let newPoly = buffer(simpoly, (-1 * bufferValue) / 1000, {
      units: 'kilometers',
    })
    const newPolygon =
      newPoly && area(newPoly) > 2 && newPoly.geometry.coordinates[0].length > 2
        ? newPoly.geometry.coordinates[0]
        : null

    if (newPolygon) {
      // console.log(newPolygon===newPolygon)

      dispatch(
        updateBuilding({
          projectId,
          polygon: newPolygon ? newPolygon : polygon,
          height,
          buildingId,
        })
      )
    } else {
      dispatch(updatePopupMessage("Reduction de l'emprise impossible"))
    }
    dispatch(cleanBuilding())
  }
}

export const GenerateBuilding = (parcelGeom, hauteur, emprise) => {
  return (dispatch, getState) => {
    dispatch({ type: GENERATE_BUILDING, payload: { parcelGeom } })
    let polygon = newTurfPolygon(parcelGeom[0], {})
    console.log('emprise%: ', emprise)
    console.log('emprise: ', emprise / 100)
    polygon = toWgs84(polygon)
    const targetArea = (emprise / 100) * area(polygon)

    if (polygon && targetArea) {
      const generatedPoly = polygon
      if (emprise < 100) {
        const bufferedPoly = bufferParcelToBati(generatedPoly, targetArea)
        if (bufferedPoly) {
          polygon = simplify(bufferedPoly, {
            tolerance: 0.00001,
            highQuality: false,
            mutate: true,
          })
        } else {
          polygon = null //TODO dispatch error impossible
        }
      }
    } else {
      //TODO Dispatch errormsg si besoin (aucune parcelle ou hauteur)
    }
    if (polygon) {
      console.log(polygon)
      const {
        project: { projectId },
      } = getState()
      dispatch(
        insertBuilding({
          height: hauteur,
          polygon: polygon.geometry.coordinates[0],
          projectId,
        })
      )
    } else {
      dispatch(updatePopupMessage('Echec de la génération du bâti'))
    }
  }
}

export const chooseAction = action => ({
  type: CHOOSE_ACTION,
  payload: { action },
})

export const cleanBuilding = () => ({ type: CLEAN_BUILDING })
