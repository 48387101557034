import React from 'react'
import PropTypes from 'prop-types'
// import classNames from 'classnames'

import {
  withStyles,
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  Tooltip,
  Button,
  TextField,
  // Fab,
  Grid,
} from '@material-ui/core'

// import TextSearch from './TextSearch'
import cardImg from '../../assets/cardImg.png'

import LogoutIcon from '../../assets/LogoutIcon'
import logoUrbascope from '../../assets/logoUrbascope.png'
import logoUrbascope2 from '../../assets/logoUrbascope2.png'
import logoUrbaBlack from '../../assets/logoUrbaBlack.png'
// import logoUrbaMapCut from '../../assets/logoUrbaMapCut.png'
// import LegendsIcon from "../../assets/LegendsIcon";
// import AideIcon from '../../assets/AideIcon'
import HomeIcon from '../../assets/HomeIcon'
import MenuListIcon from '../../assets/MenuListIcon'
import BilanPromoteurIcon from '../../assets/BilanPromoteurIcon'
import ChargeFonciereIcon from '../../assets/ChargeFonciereIcon'
import ParcelCouchesIcon from '../../assets/ParcelCouchesIcon'

import SearchParcelIcon from '../../assets/SearchParcelIcon'

import InteractionButtonsContainer from '../../routes/Project/InteractionButtons/InteractionButtonsContainer'

const styles = (theme) => ({
  // appBar: {
  //   transition: theme.transitions.create(['margin', 'width'], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen,
  //   }),
  // },
  // appBarShift: {
  //   width: `calc(100% - ${315}px)`,
  //   marginLeft: 315,
  //   transition: theme.transitions.create(['margin', 'width'], {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // },
  fixedGrid: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    backgroundColor: 'rgb(0,0,0)',
  },

  textField: {
    [`& fieldset`]: {
      borderRadius: 30,
      bordercolor: 'rgb(0,0,0)',
    },
  },
})

const TopBar = ({
  title,
  handleDrawerToggle,
  LeftPanelIsOpened,
  handleMenuOpen,
  handleArrowBack,
  handleSearchWindow,
  handleChargeFonciereWindow,
  handleBilanPromoteurWindow,
  children,
  logOut,
  classes,
  interaction,
  changeInteraction,
  expandedInteractionButtons,
  setNewProject,
  handleSearchInput,
  ...otherProps
}) => {
  return (
    <AppBar
      {...otherProps}
      // className={classNames(classes.appBar, {
      //   [classes.appBarShift]: LeftPanelIsOpened,
      // })}
    >
      <Toolbar /*style={{ minHeight: '80px' }}*/>
        <Grid container className={classes.fixedGrid}>
          <Grid item container justify="flex-start" alignItems="center" xs={4}>
            {handleArrowBack && (
              <Tooltip title="Afficher les couches">
                <div className="w3-border w3-border-black w3-margin-right">
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={() => handleDrawerToggle('openedLayerDrawer')}
                  >
                    <ParcelCouchesIcon />
                  </IconButton>
                </div>
              </Tooltip>
            )}

            <img
              style={{ maxWidth: 24, marginRight: 10 }}
              src={logoUrbaBlack}
              alt="logoblack"
            />
            <img
              style={{ maxWidth: 100, marginRight: 100 }}
              src={handleArrowBack ? logoUrbascope : logoUrbascope2}
              alt="logo"
            />
            {handleArrowBack && (
              <>
                <img
                  style={{ width: 80, marginRight: 10 }}
                  src={cardImg}
                  alt="terrain"
                />
                <Typography
                  className="w3-small"
                  align="center"
                  variant="h6"
                  color="inherit"
                  style={{ flexGrow: 0.7 }}
                >
                  {title}
                </Typography>
              </>
            )}
          </Grid>

          {!handleArrowBack && (
            <>
              <Grid
                item
                container
                justify="flex-end"
                alignItems="center"
                xs={9}
                sm={3}
              >
                <TextField
                  color="secondary"
                  onChange={handleSearchInput}
                  style={{ width: '80%' }}
                  className={classes.textField}
                  label="Rechercher un projet"
                  variant="outlined"
                />
              </Grid>
              <Grid item container sm={4} justify="flex-end">
                <Button
                  variant="contained"
                  className={`${classes.button} w3-deep-orange w3-center w3-border-bottom`}
                  color="secondary"
                  onClick={() => setNewProject(true)}
                >
                  Créer un nouveau projet
                </Button>
              </Grid>
            </>
          )}

          {handleArrowBack ? (
            <>
              <Tooltip title="Rechercher une parcelle">
                <div className="  w3-border w3-border-deep-orange w3-margin-left">
                  <IconButton color="inherit" onClick={handleSearchWindow}>
                    <SearchParcelIcon />
                  </IconButton>
                </div>
              </Tooltip>

              <InteractionButtonsContainer
                expanded={expandedInteractionButtons}
              />
              <Tooltip title="Autres...">
                <div className="w3-light-grey   w3-border w3-border-grey w3-margin-left">
                  <IconButton color="inherit" onClick={handleMenuOpen}>
                    <MenuListIcon />
                  </IconButton>
                </div>
              </Tooltip>

              <Tooltip title="Bilan Promoteur">
                <div className="w3-light-grey   w3-border w3-border-grey ">
                  <IconButton
                    color="inherit"
                    onClick={handleBilanPromoteurWindow}
                  >
                    <BilanPromoteurIcon />
                  </IconButton>
                </div>
              </Tooltip>

              <Tooltip title="Charge Fonciere">
                <div className="w3-light-grey   w3-border w3-border-grey ">
                  <IconButton
                    color="inherit"
                    onClick={handleChargeFonciereWindow}
                  >
                    <ChargeFonciereIcon />
                  </IconButton>
                </div>
              </Tooltip>

              {/* <Tooltip title="Aide">
                <div className="w3-light-grey   w3-border w3-border-grey w3-margin-right">
                  <IconButton
                    disabled
                    color="inherit"
                    onClick={handleArrowBack}
                  >
                    <AideIcon />
                  </IconButton>
                </div>
              </Tooltip> */}
              <Tooltip title="Retour">
                <div className="w3-lime w3-border w3-border-black   w3-margin-left">
                  <IconButton color="inherit" onClick={handleArrowBack}>
                    <HomeIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </>
          ) : null}

          <Tooltip title="Déconnexion" placement="top-start">
            <div className="w3-black  ">
              <IconButton onClick={() => logOut()} color="inherit">
                <LogoutIcon />
              </IconButton>
            </div>
          </Tooltip>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  // title: PropTypes.string.isRequired,
  handleDrawerToggle: PropTypes.func,
  handleArrowBack: PropTypes.func,
  position: PropTypes.string,
}

export default withStyles(styles)(TopBar)
