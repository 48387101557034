import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Tooltip from '@material-ui/core/Tooltip'

import cardImg from '../../assets/cardImg.png'

import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  withStyles,
  CardActionArea,
  Divider,
  CardMedia,
} from '@material-ui/core'

const styles = theme => ({
  media: {
    height: 91,
    width: 150,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    // width: 50%
  },

  card: {
    maxWidth: 240,
    height: 300,
    backgroundColor: 'rgba(255,255,255,1)',
  },
  cardFoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    wordBreak: 'break-word',
  },
})

const ProjectCard = ({
  projectId,
  deleteProject,
  title,
  lastUpdateDate,
  // creationDate,
  // favorite,
  descr,
  libCom,
  // image,
  classes,
}) => {
  return (
    <Card
      className={`${classes.card} w3-hover-lime`}
      elevation={5}
      style={{ padding: 0 }}
    >
      <CardActionArea component={Link} to={`/project/${projectId}`}>
        <CardMedia className={classes.media} image={cardImg} title="Terrain" />
        <CardHeader
          classes={{
            title: 'w3-medium',
            subheader: 'w3-medium',
          }}
          style={{
            padding: '4px 10px',
            textAlign: 'center',
            wordBreak: 'break-word',
          }}
          title={title}
          subheader={libCom}
        />
        <CardContent
          className="w3-topbar w3-border-lime"
          style={{
            wordBreak: 'break-word',
          }}
        >
          {/* <img
            style={{ maxWidth: 145, marginRight: 10 }}
            src={cardImg}
            alt="terrain"
          /> */}
          <Typography>{descr ? descr : 'Aucune description.'}</Typography>
        </CardContent>
      </CardActionArea>
      <Divider />
      <CardContent className={classes.cardFoot} style={{ padding: '0px 10px' }}>
        <Typography variant="overline" style={{ fontSize: '0.7rem' }}>
          {'Dernière modification : ' + lastUpdateDate}
        </Typography>
        <Tooltip title="Supprimer ce projet" aria-label="Supprimer">
          <IconButton
            className="w3-red"
            style={{ padding: 0 }}
            onClick={() => {
              deleteProject(projectId)
            }}
          >
            <Clear />
          </IconButton>
        </Tooltip>
      </CardContent>
    </Card>
  )
}

ProjectCard.propTypes = {
  projectId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  lastUpdateDate: PropTypes.string.isRequired,
  descr: PropTypes.string,
  image: PropTypes.string,
}

export default withStyles(styles)(ProjectCard)
