import createDuck from '../createDuck'

const { reducer: communeReducer, searchCommune } = createDuck({
  namespace: 'batir/search',
  actionName: 'searchCommune',
  payloadName: 'communes',
  path: process.env.REACT_APP_API_PATH + 'search/searchCommune.php',
})

export default communeReducer
export { searchCommune }
