import { snapLocation } from './snapLocation'
import getHoveredElmts from './hoveredElmts'

import { lineString, lineDistance /*, polygon, area*/ } from '@turf/turf'

export default function () {
  const { viewport, setDistanceValue /*, setSurfaceValue*/ } = this.props
  const { clickLocations, creatingNode } = this.state
  const cleanHoveredInfo = this.props.cleanHoveredInfo

  let isLineClosed = (clickLocationArray) => {
    //fonction qui renvoie true si le tracé forme un polygon fermé
    const verticesNumber = clickLocationArray.length
    if (verticesNumber >= 3) {
      const [firstPtX, firstPtY] = clickLocationArray[0]
      const [lastPtX, lastPtY] = clickLocationArray[verticesNumber - 1]
      return Boolean(firstPtX === lastPtX && firstPtY === lastPtY)
    } else {
      return false
    }
  }

  return {
    onClick: (e) => {
      // mise à jour de la ligne dessinée avec un nouveau sommet
      if (creatingNode) {
        const location = snapLocation(
          e.lngLat,
          this.getSnappingLocation(),
          10,
          viewport
        ).location

        // ajout d'un nouveau point à la ligne
        this.setState(
          {
            clickLocations: [
              ...(isLineClosed(clickLocations) ? [] : clickLocations), // (on vide le tracé si il est bouclé)
              location,
            ],
          },
          () => {
            const { clickLocations } = this.state // il est important de récupérer le dernier état !
            const verticesNumber = clickLocations.length
            // ------Mesure de Distance
            if (verticesNumber >= 2) {
              // si il y a au moins 2 points, on peut donner la disance
              const line = lineString(clickLocations, {})
              let distanceValue = lineDistance(line)
              if (distanceValue < 2) {
                distanceValue =
                  'Distance Total: ' +
                  (distanceValue * 1000).toLocaleString() +
                  ' m'
              } else {
                distanceValue =
                  'Distance Total: ' + distanceValue.toLocaleString() + ' km'
              }
              setDistanceValue(distanceValue)
            }

            // // ------Mesure de Surface
            // if (isLineClosed(clickLocations)) {
            //   // il faut au moins 3 points pour mesurer une surface
            //   const contour = polygon([clickLocations], {})
            //   let areaValue = area(contour)

            //   if (areaValue > 10000) {
            //     areaValue =
            //       'Surface Total: ' +
            //       (areaValue / 10000).toLocaleString() +
            //       ' ha'
            //   } else {
            //     areaValue =
            //       'Surface Total: ' + areaValue.toLocaleString() + ' m²'
            //   }
            //   setSurfaceValue(areaValue)
            // } else {
            //   setSurfaceValue(null)
            // }
          }
        )
      }
    },
    onMouseDown: (e) => {
      // lorsqu'on appuie sur le curseur on considère que l'on est en train de créer un point
      this.setState({ creatingNode: true })
    },
    onHover: (e) => {
      const { location, snapped } = snapLocation(
        e.lngLat,
        this.getSnappingLocation(),
        10,
        viewport
      )

      if (snapped) {
        this.setState({ mouseLocation: location, snappedPoint: location })
      } else {
        this.setState({ mouseLocation: location, snappedPoint: [] })
      }
      getHoveredElmts(e, this.props)
    },
    onMouseLeave: (e) => {
      cleanHoveredInfo()
    },
  }
}
