import React from 'react'
// import ReactDOM from 'react-dom'
// import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import Axios from 'axios'

import XLSX from 'xlsx'

import { updateFilter, resetFilter } from 'store/filter/updateFilterReducer'

import {
  withStyles,
  InputAdornment,
  Checkbox,
  FormLabel,
  FormControlLabel,
  FormControl,
  Button,
  TextField,
  Typography,
  CircularProgress,
  // IconButton,
} from '@material-ui/core'

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    // width: '100%',
    // minWidth: 120,
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 100,
  },
  menu: {
    width: 200,
  },
  filterDiv: {
    marginTop: '5px',
  },
  button: {
    margin: theme.spacing(1),
  },
  midButton: {
    margin: theme.spacing(1),
    width: '50%',
  },
  buttonDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // margin: theme.spacing(1),
  },
})

const valuesLimits = [0, 999999999]

class FilterContent extends React.Component {
  state = {
    // active: this.props.filter.active,
    smin: this.props.filter.smin,
    smax: this.props.filter.smax,
    sdpmin: this.props.filter.sdpmin,
    sdpmax: this.props.filter.sdpmax,
    proxiStation: this.props.filter.proxiStation,
    //fstation: false,
    notBuildedOnly: this.props.filter.notBuildedOnly,
  }

  isLoadingQuery = () => {
    const { updateFilter } = this.props.filter
    if (updateFilter) {
      return updateFilter.status ? updateFilter.status === 'pending' : false
    } else {
      return false
    }
  }

  isValidSurfaceInterval = () => {
    if (this.state.smin === '' || this.state.smax === '') {
      return true
    } else {
      return this.state.smin < this.state.smax ? true : false
    }
  }

  isValidPlancherInterval = () => {
    if (this.state.sdpmin === '' || this.state.sdpmax === '') {
      return true
    } else {
      return this.state.sdpmin < this.state.sdpmax ? true : false
    }
  }

  handleCheck = (name) => (event) => {
    this.setState({ [name]: event.target.checked })
  }

  handleChange = (name) => (event) => {
    let saisie = event.target.value

    if (
      name === 'smin' ||
      name === 'smax' ||
      name === 'sdpmin' ||
      name === 'sdpmax'
    ) {
      //on vire tout ce qui n'est pas un chiffre, max 9 char
      saisie = saisie.replace(/[^0-9]/g, '').slice(0, 9)
      if (saisie) {
        saisie = parseInt(saisie)
      } else {
        saisie = ''
      }
      // on limite les valeur max et min
      if (saisie > valuesLimits[1]) {
        saisie = valuesLimits[1]
      }
      if (saisie < valuesLimits[0]) {
        saisie = valuesLimits[0]
      }
    }

    this.setState({ [name]: saisie })
  }

  donwnloadCsvFilter = (e) => {
    Axios.post(
      process.env.REACT_APP_API_PATH + 'search/getFilteredParcelListdata.php',
      { commune: this.props.commune, ...this.state }
    ).then(({ data }) => {
      if (data) {
        var new_workbook = XLSX.utils.book_new()
        var filename = 'filtrage_parcellaire_' + data.commune + '.xlsx'
        var filterParamsSheet = XLSX.utils.json_to_sheet([
          {
            Commune: data.commune,
            'Surface minimale': data.smin,
            'Surface maximale': data.smax,
            'SDP minimale': data.sdpmin,
            'SDP maximale': data.sdpmax,
            "< 500m d'une station": data.proxiStation ? 'Oui' : 'Non',
            'Parcelle non bâtie uniquement': data.notBuildedOnly
              ? 'Oui'
              : 'Non',
          },
        ])

        var resultsSheet = XLSX.utils.json_to_sheet(
          data.results.map((data) => ({ ...data }))
        )
        XLSX.utils.book_append_sheet(
          new_workbook,
          resultsSheet,
          'Liste des parcelles'
        )
        XLSX.utils.book_append_sheet(
          new_workbook,
          filterParamsSheet,
          'Paramètres de filtre'
        )
        XLSX.writeFile(new_workbook, filename)
      }
    })
  }

  // handleViewOnCom = e => {
  //   e.preventDefault()
  //   this.props.findCommune({ commune: this.props.commune })
  // }

  render() {
    const { classes, commune, filter, handleViewOnCom, hPerNiv } = this.props

    return (
      <form className={classes.root} autoComplete="off">
        <FormControl className={classes.formControl}>
          <div className={classes.filterDiv}>
            <FormLabel component="legend">Surface de la parcelle</FormLabel>
            <TextField
              color="secondary"
              // disabled={!this.state.active}
              error={!this.isValidSurfaceInterval()}
              id="surface-min"
              label="Min"
              value={this.state.smin}
              onChange={this.handleChange('smin')}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">m²</InputAdornment>
                ),
              }}
            />
            <TextField
              color="secondary"
              // disabled={!this.state.active}
              error={!this.isValidSurfaceInterval()}
              id="surface-max"
              label="Max"
              value={this.state.smax}
              onChange={this.handleChange('smax')}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">m²</InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.filterDiv}>
            <FormLabel component="legend">
              Surface maximale de plancher autorisé
            </FormLabel>
            <TextField
              disabled
              color="secondary"
              error={!this.isValidPlancherInterval()}
              id="plancher-min"
              label="Min"
              value={this.state.sdpmin}
              onChange={this.handleChange('sdpmin')}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">m²</InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              color="secondary"
              error={!this.isValidPlancherInterval()}
              id="plancher-max"
              label="Max"
              value={this.state.sdpmax}
              onChange={this.handleChange('sdpmax')}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">m²</InputAdornment>
                ),
              }}
            />
          </div>
          <FormLabel style={{ marginTop: '5px' }} component="legend">
            Dérogation au stationnement :
          </FormLabel>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.proxiStation}
                  onChange={this.handleCheck('proxiStation')}
                  value="proxiStation"
                  color="secondary"
                />
              }
              labelPlacement="start"
              label="< 500m d'une station"
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  disabled
                  checked={this.state.fstation}
                  onChange={this.handleCheck('fstation')}
                  value="fstation"
                  color="secondary"
                />
              }
              labelPlacement="start"
              label="< 500m d'une future station"
            /> */}
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.notBuildedOnly}
                onChange={this.handleCheck('notBuildedOnly')}
                value="notBuildedOnly"
                color="secondary"
              />
            }
            labelPlacement="start"
            label="Parcelles non bâties"
          />
          <div className={classes.filterDiv} />
          <div className={classes.buttonDiv}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.midButton}
              disabled={
                !(
                  this.isValidPlancherInterval() &&
                  this.isValidSurfaceInterval()
                ) ||
                // !this.state.active ||
                this.isLoadingQuery()
              }
              onClick={() => {
                this.props.updateFilter({
                  commune: commune,
                  hPerNiv,
                  ...this.state,
                })
              }}
            >
              Filtrer
            </Button>
            <Button
              // disable={}
              variant="contained"
              color="secondary"
              className={classes.midButton}
              onClick={() => {
                this.donwnloadCsvFilter()
              }}
            >
              Export excel
            </Button>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={!(filter.nbResults > 0)}
            onClick={() => {
              this.props.resetFilter()
            }}
          >
            Réinitialiser le filtre
          </Button>
          <Button
            disabled={!(filter.nbResults > 0)}
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleViewOnCom}
          >
            Voir la selection
          </Button>

          {this.isLoadingQuery() ? (
            <CircularProgress size={20} style={{ alignSelf: 'center' }} />
          ) : (
            <>
              <Typography className="w3-padding-16 w3-blue" align="center">
                {'Résultats : '}
                {filter.nbResults
                  ? filter.nbResults.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')
                  : ' Aucun '}
                {/* {filter.nbResults > 0 && (
                )} */}
              </Typography>
              {/* <Typography align="center">
                Attention : Affichage limité à 100 parcelles
              </Typography> */}
            </>
          )}
        </FormControl>
      </form>
    )
  }
}

const mapStateToProps = ({
  filter,
  project: {
    gabaritConfig: { log_hau_niv, bur_hau_niv, bati_is_log },
  },
}) => ({
  filter: filter,
  hPerNiv: bati_is_log ? log_hau_niv : bur_hau_niv,
})

const mapDispatchToProps = {
  updateFilter,
  resetFilter,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FilterContent))
