import React, { Component } from 'react'
import { connect } from 'react-redux'

import { cleanParcelleInfo } from '../../../store/map/infoInteraction/infoInteractionReducer'
import { openStreetView } from 'store/map/mapReducer'

import FeatureInfoComponent from './FeatureInfoComponent'

// import { Collapse } from '@material-ui/core'

// import Alert from '@material-ui/lab/Alert'

class FeatureInfoContainer extends Component {
  // state = { isOpenedAlert: true }

  // handleCloseAlert = e => {
  //   this.setState({ isOpenedAlert: false })
  // }
  handleClose = (e) => {
    this.props.cleanParcelleInfo()
  }

  handleOpenStreetView = () => {
    const { lng, lat } = this.props.streetCoord
    this.props.openStreetView(lng, lat)
  }

  render() {
    const { items } = this.props

    return (
      <div>
        {items && (
          <FeatureInfoComponent
            // handleRuleConfigWindow={handleRuleConfigWindow}
            handleOpenStreetView={this.handleOpenStreetView}
            handleClose={this.handleClose}
            // commune={commune}
            // isAdmin={isAdmin}
            {...this.props}
          />
        )}
        {/* <Collapse in={this.state.isOpenedAlert}>
          <Alert
            style={{
              position: 'absolute',
              bottom: '40px',
              right: '70px',
              height: '500px',
              backgroundColor: 'white',
            }}
            variant="outlined"
            severity="info"
            onClose={this.handleCloseAlert}
          >
            This is an info alert — check it out!
          </Alert>
        </Collapse> */}
      </div>
    )
  }
}

const mapStateToProps = ({
  map: { infoInteraction, interaction },
  project: {
    adminRight,
    gabaritConfig: { bur_hau_niv },
  },
}) => {
  // tableau listant dans l'ordre les éléments à afficher dans le composant
  const positions = [
    'commune',
    'section',
    'numéro',
    'contenance cadastral',
    'Zonage',
    'Hauteur maximale',
    'Bande de hauteur',
    'Emprise maximale',
    'Bande de constructibilité',
  ]

  if (infoInteraction.geojson && interaction === 'info') {
    const { properties, id } = infoInteraction.geojson.features[0]
    // création d'un tableau composés d'objets correspondant aux propriétés à afficher ordonnés selon la position souhaitée
    const items = positions.map((key) => ({ [key]: properties[key] }))

    return {
      isVisible: true,
      items,
      title: 'Parcelle n°' + id,
      isAdmin: adminRight || false,
      streetCoord: { lng: properties.x, lat: properties.y },
      commune: properties.commune,
      surfaceTerrain: properties.aire,
      hPerNiv: bur_hau_niv,
      ces: properties.ces,
      h_max: properties.h_max,
    }
  } else {
    return { isVisible: false }
  }
}

export default connect(mapStateToProps, { cleanParcelleInfo, openStreetView })(
  FeatureInfoContainer
)
