import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Axios from 'axios'

import {
  withStyles,
  Paper,
  Typography,
  Zoom,
  IconButton,
  Toolbar,
  Tooltip,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
// import Streetview from '@material-ui/icons/Streetview'

// import AssignmentIcon from '@material-ui/icons/Assignment'
import StreetViewIcon from 'assets/StreetViewIcon'
import EditRuleIcon from 'assets/EditRuleIcon'

const styles = (theme) => ({
  paper: {
    zIndex: 10,
    padding: 0,
    position: 'absolute',
    bottom: '8em',
    left: 0,
    right: 0,
    margin: 'auto',
    width: 396,
    height: 370,
    [theme.breakpoints.down('sm')]: {
      bottom: '0.2em',
    },
  },
  tableInfo: { paddingLeft: 24, paddingRight: 24, width: '100%' },
  cellInfo: { borderRight: '1px solid grey' },
  rowImpPair: { backgroundColor: '#f1f1f1' },

  typography: { textAlign: 'center' },
  grow: { flexGrow: 1 },
})

const getCodeFromCommune = (commune) =>
  commune ? commune.split('(')[1].split(')')[0] : null

class FeatureInfoComponent extends Component {
  state = {
    pluDocReference: null,
  }

  componentDidMount() {
    // console.log('componentDidMount', this.props.items[0].commune)
    this.getPluPages()
  }

  componentDidUpdate(prvPr, prvSt) {
    const codecomm = getCodeFromCommune(this.props.items[0].commune)
    const prvCodecom = getCodeFromCommune(prvPr.items[0].commune)
    const zonage = this.props.items[4].Zonage
    const prvzonage = prvPr.items[4].Zonage
    if (codecomm !== prvCodecom || zonage !== prvzonage) {
      this.getPluPages()
    }
  }

  getSdpBureaux() {
    const { h_max, ces, hPerNiv, surfaceTerrain } = this.props
    if (!h_max || !hPerNiv) {
      return null
    }
    let emprise = ces ? (ces / 100) * surfaceTerrain : surfaceTerrain // si aucune reglementation sur l'emprise on prend toute la parcelle
    return Math.ceil(h_max / hPerNiv) * emprise
  }

  getUrlBilanPromoteur() {
    const { surfaceTerrain } = this.props
    const url =
      process.env.REACT_APP_ABS_API_PATH +
      'vues/bilanPromoteur.php?' +
      (surfaceTerrain ? 'surfaceTerrain=' + surfaceTerrain + '&' : '') +
      (this.getSdpBureaux() ? 'SDPBureaux=' + this.getSdpBureaux() : '')
    return url
  }

  getPluPages() {
    const { items } = this.props
    const codeCommune = getCodeFromCommune(items[0].commune) || null
    const Zonage = items[4].Zonage
    Axios.get(
      process.env.REACT_APP_API_PATH +
        'search/getPluPages.php?commune=' +
        +codeCommune +
        '&zonage=' +
        Zonage
    ).then(({ data }) => {
      // pluDocReference = data
      this.setState({ pluDocReference: data ? { ...data } : null })
    })
  }
  render() {
    const {
      classes,
      handleClose,
      handleRuleConfigWindow,
      title,
      isVisible,
      isAdmin,
      items,
      handleOpenStreetView,
    } = this.props

    return (
      <Zoom in={isVisible}>
        <Paper className={classes.paper}>
          <Toolbar className="w3-bottombar w3-border w3-border-lime">
            <Typography className={classes.grow} gutterBottom>
              {title}
            </Typography>
            <Tooltip title="Ouvrir Street View">
              <IconButton
                onClick={handleOpenStreetView}
                aria-label="Ouvrir Street View"
              >
                <StreetViewIcon />
              </IconButton>
            </Tooltip>
            {isAdmin && (
              <Tooltip title="Saisie du formulaire de Réglementation">
                <IconButton
                  onClick={handleRuleConfigWindow}
                  aria-label="Saisie Règlement"
                >
                  <EditRuleIcon />
                </IconButton>
              </Tooltip>
            )}
            <IconButton
              className="w3-red"
              onClick={handleClose}
              aria-label="Fermer"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <div style={{ overflowY: 'scroll', height: 300 }}>
            <table className={classes.tableInfo}>
              <tbody>
                {items
                  ? items.map((item, idx) => {
                      {
                        const [key, value] = Object.entries(item)[0]
                        return (
                          <tr
                            key={key}
                            className={
                              idx % 2 !== 0 ? classes.rowImpPair : 'false'
                            }
                          >
                            <td className={classes.cellInfo}>
                              <Typography>
                                <b>{key}</b>
                              </Typography>
                            </td>
                            <td>{value} </td>
                          </tr>
                        )
                      }
                    })
                  : // <List component="ul" dense>
                    //   {items.map(item => {
                    //     {
                    //       const [key, value] = Object.entries(item)[0]
                    //       return (
                    //         <ListItem style={{ padding: '0px 16px' }} key={key}>
                    //           <ListItemText primary={key} secondary={value} />
                    //         </ListItem>
                    //       )
                    //     }
                    //   })}
                    // </List>
                    null}
              </tbody>
            </table>
            <div
              className="w3-padding-small"
              // style={{ overflowY: 'scroll', height: '186px' }}
            >
              <div>
                {this.state.pluDocReference ? (
                  this.state.pluDocReference.pages ? (
                    <span className=" w3-medium w3-padding-small">
                      <i className="fa fa-hand-o-right w3-text-deep-orange"></i>
                      {' Accès direct règlementation : '}
                      <select
                        name="pagesPlu"
                        id="pagesPlu-select"
                        onChange={(e) => {
                          window.open(
                            this.state.pluDocReference.link +
                              '#page=' +
                              e.target.value
                          )
                        }}
                      >
                        {this.state.pluDocReference.pages.map((page) => (
                          <option key={page} value={page}>
                            {page}
                          </option>
                          // <a
                          //   key={page}
                          //   href={
                          //     this.state.pluDocReference.link + '#page=' + page
                          //   }
                          //   target="_blank"
                          //   rel="noopener noreferrer"
                          //   className="w3-hover-opacity  w3-medium w3-padding-small"
                          //   style={{ textDecoration: 'none' }}
                          // >
                          // {page}
                          // </a>
                        ))}
                      </select>
                    </span>
                  ) : (
                    <a
                      href={this.state.pluDocReference.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="w3-hover-opacity  w3-medium w3-padding-small"
                      style={{ textDecoration: 'none' }}
                    >
                      <i className="fa fa-hand-o-right w3-text-deep-orange"></i>
                      {' Accès direct règlementation'}
                    </a>
                  )
                ) : (
                  <span className=" w3-medium w3-padding-small">
                    Documents PLU non disponible pour le zonage de cette commune
                  </span>
                )}
              </div>

              <b className="  w3-medium w3-padding-small">
                Documents juridiques
              </b>
              <div>
                <a
                  href={
                    process.env.REACT_APP_ABS_API_PATH +
                    'documents/getDocument.php?doc=modele_courrier_intention_achat'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{ textDecoration: 'none' }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i>{' '}
                  Courrier d’intention d’achat
                </a>
              </div>
              <div>
                <a
                  href={
                    process.env.REACT_APP_ABS_API_PATH +
                    'documents/getDocument.php?doc=protocole_etude_exclusif_2019'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{ textDecoration: 'none' }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i>
                  {' Protocole exclusif d’études'}
                </a>
              </div>
              <div>
                <a
                  href={
                    process.env.REACT_APP_ABS_API_PATH +
                    'documents/getDocument.php?doc=PUV'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{ textDecoration: 'none' }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i> PUV
                </a>
              </div>

              <b className="  w3-medium w3-padding-small">
                Renseignements cadastre/risques/urbanisme
              </b>
              <div>
                <a
                  href="google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{
                    textDecoration: 'none',
                    color: 'currentColor',
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    pointerEvents: 'none',
                  }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i>{' '}
                  Certificat Alignement
                </a>
              </div>

              <div>
                <a
                  href="google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{
                    textDecoration: 'none',
                    color: 'currentColor',
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    pointerEvents: 'none',
                  }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i>{' '}
                  Certificat Carrières
                </a>
              </div>

              <div>
                <a
                  href="google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{
                    textDecoration: 'none',
                    color: 'currentColor',
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    pointerEvents: 'none',
                  }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i>{' '}
                  Certificat Hygiène/Salubrité
                </a>
              </div>
              <div>
                <a
                  href="google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{
                    textDecoration: 'none',
                    color: 'currentColor',
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    pointerEvents: 'none',
                  }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i>{' '}
                  Certificat Non-Péril
                </a>
              </div>
              <div>
                <a
                  href="google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{
                    textDecoration: 'none',
                    color: 'currentColor',
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    pointerEvents: 'none',
                  }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i>{' '}
                  CIRRA
                </a>
              </div>
              <div>
                <a
                  href="google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{
                    textDecoration: 'none',
                    color: 'currentColor',
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    pointerEvents: 'none',
                  }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i>{' '}
                  Concordance cadastral
                </a>
              </div>
              <div>
                <a
                  href="google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{
                    textDecoration: 'none',
                    color: 'currentColor',
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    pointerEvents: 'none',
                  }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i> CU
                </a>
              </div>
              <div>
                <a
                  href="google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{
                    textDecoration: 'none',
                    color: 'currentColor',
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    pointerEvents: 'none',
                  }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i> DP
                </a>
              </div>
              <div>
                <a
                  href="google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{
                    textDecoration: 'none',
                    color: 'currentColor',
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    pointerEvents: 'none',
                  }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i>{' '}
                  ERP/ESRIS
                </a>
              </div>

              <div>
                <a
                  href="google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{
                    textDecoration: 'none',
                    color: 'currentColor',
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    pointerEvents: 'none',
                  }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i>{' '}
                  ERPS
                </a>
              </div>

              <div>
                <a
                  href="google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{
                    textDecoration: 'none',
                    color: 'currentColor',
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    pointerEvents: 'none',
                  }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i>{' '}
                  Identité propriétaire
                </a>
              </div>

              <div>
                <a
                  href="google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{
                    textDecoration: 'none',
                    color: 'currentColor',
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    pointerEvents: 'none',
                  }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i> NRU
                </a>
              </div>

              <div>
                <a
                  href="google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{
                    textDecoration: 'none',
                    color: 'currentColor',
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    pointerEvents: 'none',
                  }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i> PSR
                </a>
              </div>

              <div>
                <a
                  href="google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{
                    textDecoration: 'none',
                    color: 'currentColor',
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    pointerEvents: 'none',
                  }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i>{' '}
                  Rapport georisques
                </a>
              </div>

              <b className="  w3-medium w3-padding-small">Simulateurs</b>
              <div>
                <a
                  href={
                    process.env.REACT_APP_ABS_API_PATH +
                    'vues/chargesFoncieres.php'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{ textDecoration: 'none' }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i>{' '}
                  Calculer la charge foncière
                </a>
              </div>
              <div>
                <a
                  href={this.getUrlBilanPromoteur}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-opacity  w3-medium w3-padding-small"
                  style={{ textDecoration: 'none' }}
                >
                  <i className="fa fa-hand-o-right w3-text-deep-orange"></i>{' '}
                  Calculer le Bilan Promoteur
                </a>
              </div>
            </div>
          </div>
        </Paper>
      </Zoom>
    )
  }
}

FeatureInfoComponent.propTypes = {
  handleClose: PropTypes.func,
  title: PropTypes.string,
  items: PropTypes.array,
}

FeatureInfoComponent.defaultProps = {
  title: 'Aucune information',
}

export default withStyles(styles)(FeatureInfoComponent)
