import createDuck from '../createDuck'
import { getViewportFromBbox } from 'store/map/mapReducer'

const { reducer: openProjectReducer, openProject } = createDuck({
  namespace: 'batir/project',
  actionName: 'openProject',
  path: process.env.REACT_APP_API_PATH + 'project/getProjectInfo.php',
  callback: function(data) {
    return (dispatch, getState) => {
      dispatch(getViewportFromBbox(data.bbox, 60))
      // console.log(data.bbox)
      // dispatch(getViewportFromBbox([[2.1, 48.6], [2.7, 49.1]], true)) //vue sur paris
    }
  },
})

export default openProjectReducer
export { openProject }
