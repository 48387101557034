import createDuck from '../createDuck'

const { reducer: projectAreaReducer, editProjectArea } = createDuck({
  namespace: 'batir/project',
  actionName: 'editProjectArea',
  payloadName: 'projectArea',
  path:
    process.env.REACT_APP_API_PATH + 'project/projectArea/editProjectArea.php',
})

export default projectAreaReducer
export { editProjectArea }
