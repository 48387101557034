import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function UrbanismeIcon(props) {
  return (
    <SvgIcon {...props} style={{ fontSize: '1.7rem' }} viewBox="0 0 64 64">
      <image
        width={64}
        height={64}
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA0lBMVEUAAADZTBD/AADYTBDZ TRDZTBD/gADZTBD/VQDMMwDmTRrZTBDVVQDZTA/fQADjVRzZTBDRRhfaTRDaTRHdTRHZTBDYThTZ TQ/ZTBDZTRDbSRLaTRGUpqaVpKaVpKffUBDZTBDYTBCVpabZTBDSSw/ZTBDVRw7ZTBDXUQ3ZTBDb SQzbSQDZTBDcUQzZTRDVShXZTBDWRxTZTBDZTBPZTBDZTBDbSRLZTBCWpaWTpqbZSxDWShDZTBDY TQ/ZTBDZTRDZTA/aTBDYTQ/ZTBCVpab///8baurFAAAAQ3RSTlMA/gH1+v0C+wMFCvgG9wgJ8wuP TB7yDWTp8A6qVudUEO+R5u0R7BLrE/wVB+gW5hjlGeMb+eIc4FVT3x/dIfbc59pjjh9nqwAAAAFi S0dERY6zqFcAAAAHdElNRQfjChcTJxwL3kcIAAABVklEQVRIx93T6VLCMBQF4LQEESjggopKReq+ 74or6un7P5NNU6c3aWOKU3DG+yMhnX7kpEkY+5cFtZxpETJwp0pEU0FKtLQoh+jB1JfKIqIBNCIa zksks1lLLvlFsL9aS96pzwSDEszJOUcWUsUcmdxBgWA1zE9KOOpi1IhPfURce7AmPEKqRUgLbTK5 XJklWAWdSckCFtNgbs7VXVpmrNGlZAWrZC0/3fa1XvJjHRvKR67TKJtx1/f9LdEPksfbGCjEI6Oh G3d+GAZ0uh3sKh/ZS+8zk4KFUVGyh30jYfnkAIdkdKQEM5AejhVyYienOCOjmiC2YOe4ICMujo+N XOJKJ9/V90Ot5P5c44aQW0oyItmfO9zHf8/jt5rykMpYJvKAromMgox4FM+f8ExIK9pTpp96ffkv eCXkrQgZw1DvbRP5MBF8mkiBmg3Rr1iBGgVyP0R9AW1GmleT3R1hAAAAJXRFWHRkYXRlOmNyZWF0 ZQAyMDE5LTEwLTI0VDAyOjM5OjI4LTA3OjAwjpeQNwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOS0x MC0yNFQwMjozOToyOC0wNzowMP/KKIsAAAAASUVORK5CYII="
      />
    </SvgIcon>
  )
}
