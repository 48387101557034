import './defaultValues/defaultValues'

export const calibrationValues = ({
  log_epai_bati,
  log_epai_moy_fac,
  log_hau_niv,
  log_surf_stu,
  log_surf_t2,
  log_surf_t3,
  log_surf_t4,
  log_surf_t5,
  log_rend_surf,
  log_surf_rapp,
  bur_epai_bati,
  bur_epai_moy_fac,
  bur_hau_niv,
  bur_surf_1p,
  bur_surf_2p,
  bur_surf_3p,
  bur_surf_4p,
  bur_surf_5p,
  bur_surf_op,
  bur_rend_surf,
  bur_surf_rapp,
  bur_effec,
  bur_surf_reun,
  bur_csst,
  bur_comm_is_full,
  bati_is_log,
}) => {
  return {
    isBatiLogement: bati_is_log,
    logements: {
      epaisseurDuBatiment: {
        label: 'Epaisseur du bâtiment',
        value: log_epai_bati,
        unit: 'm',
        choices: [
          { label: '13', value: 13 },
          { label: '15', value: 15 },
        ],
      },
      epaisseurMoyDesFacades: {
        label: 'Epaisseur Moyenne des facades',
        value: log_epai_moy_fac,
        unit: 'cm',
        defVal: 34,
      },
      hauteurParNiveaux: {
        label: 'Hauteur par niveaux',
        value: log_hau_niv,
        unit: 'm',
        defVal: 3,
      },
      surfaceStudio: {
        label: 'Surface studio',
        value: log_surf_stu,
        unit: 'm²',
        defVal: 29.9,
      },
      surfaceT2: {
        label: 'Surface 2 pièces',
        value: log_surf_t2,
        unit: 'm²',
        defVal: 42.9,
      },
      surfaceT3: {
        label: 'Surface 3 pièces',
        value: log_surf_t3,
        unit: 'm²',
        defVal: 63.3,
      },
      surfaceT4: {
        label: 'Surface 4 pièces',
        value: log_surf_t4,
        unit: 'm²',
        defVal: 84.1,
      },
      surfaceT5: {
        label: 'Surface 5 pièces',
        value: log_surf_t5,
        unit: 'm²',
        defVal: 108,
      },
      rendementSurfaceParSdp: {
        label: 'Rendement surface habitable/SDP',
        value: log_rend_surf, //95%
        unit: '%',
        defVal: 95,
      },
      surfaceRapporté: {
        label: 'Surface rapporté',
        value: log_surf_rapp,
        unit: ' m²/unité',
        defVal: 25,
      },
    },
    bureaux: {
      epaisseurDuBatiment: {
        label: 'Epaisseur du bâtiment',
        value: bur_epai_bati,
        unit: 'm',
        defVal: 18,
      },
      effectif: {
        label: 'Effectif',
        value: bur_effec,
        unit: 'agent(s)',
        defVal: 12,
        // demande a korsakoff
      },
      epaisseurMoyDesFacades: {
        label: 'Epaisseur Moyenne des facades',
        value: bur_epai_moy_fac,
        unit: 'cm',
        defVal: 32,
      },
      hauteurParNiveaux: {
        label: 'Hauteur par niveaux',
        value: bur_hau_niv,
        unit: 'm',
        defVal: 3.3,
      },
      surfaceBureauFerme1p: {
        label: 'Surface bureaux fermé 1 personne',
        value: bur_surf_1p,
        unit: 'm²',
        defVal: 12,
      },
      surfaceBureauFerme2p: {
        label: 'Surface bureaux fermé 2 personnes',
        value: bur_surf_2p,
        unit: 'm²',
        defVal: 15,
      },
      surfaceBureauFerme3p: {
        label: 'Surface bureaux fermé 3 personnes',
        value: bur_surf_3p,
        unit: 'm²',
        defVal: 20,
      },
      surfaceBureauFerme4p: {
        label: 'Surface bureaux fermé 4 personnes',
        value: bur_surf_4p,
        unit: 'm²',
        defVal: 25,
      },
      surfaceBureauFerme5p: {
        label: 'Surface bureaux fermé 5 personnes',
        value: bur_surf_5p,
        unit: 'm²',
        defVal: 30,
      },
      surfaceOpenSpace: {
        label: 'Surface open-space',
        value: bur_surf_op,
        unit: 'm²',
        defVal: 70,
      },
      rendementSurfaceParSdp: {
        label: 'Rendement surface utile/SDP',
        value: bur_rend_surf,
        unit: '%',
        defVal: 95,
      },
      surfaceRapporté: {
        label: 'Surface rapporté',
        value: bur_surf_rapp,
        unit: ' m²/unité',
        defVal: 25,
      },
      salleDeReunion: {
        label: 'Salle de Reunion pour 5 personnes',
        value: bur_surf_reun, // m² pour 5 personnes
        unit: 'm²',
        defVal: 15,
      },
      csst: {
        label: 'Circulation/sanitaire/service/technique',
        value: bur_csst, // c'est a dire +20% des surface (salle de reunion + surface des bureaux à ajouter), correspondant cirulation/sanitaire/service/technique
        unit: 'm²',
        defVal: 0.2,
      },
      commerceProportion: {
        label: 'Profondeur des commerces',
        value: bur_comm_is_full,
        unit: '',
        defVal: false,
        choices: [
          { label: 'Sur toute la parcelle', value: true },
          { label: '15 mètres', value: false },
        ],
      },
    },
  }
}
