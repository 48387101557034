import { polygon as Polygon, centroid as Centroid } from '@turf/turf'

import { snapLocation } from './snapLocation'
import getHoveredElmts from './hoveredElmts'

import { customMapControlsEventBlocker } from './eventBlock'

export default function() {
  const cleanHoveredInfo = this.props.cleanHoveredInfo

  const {
    drawing,
    setBuilding,
    viewport,
    setBuildingPolygon,
    cleanBuilding,
  } = this.props
  const {
    editedNode,
    snappedPoint,
    clickLocations,
    buildingMoveTarget,
  } = this.state
  return {
    onClick: e => {
      if (
        !drawing.buildingId &&
        customMapControlsEventBlocker(e.target.className)
      ) {
        // sélection d'un polygone de bâti à éditer
        const building = e.features
          ? e.features.find(f => f.layer.id === 'projects_buildings_fill')
          : null

        if (building) {
          // chargement de la géométrie dans l'overlay de dessin
          this.setState({
            clickLocations: building.geometry.coordinates[0],
          })
          setBuilding({
            buildingId: building.id,
            polygon: building.geometry.coordinates[0],
            height: building.properties.height,
          })
        }
      } else if (drawing.action === 'changePolygon') {
        if (editedNode.length === 0) {
          // sélection d'un noeud à éditer
          if (snappedPoint.length > 0) {
            // un noeud est à portée d'accroche, on le sélectionne
            this.setState({ editedNode: snappedPoint, snappedPoint: [] })
          }
        } else {
          // MAJ du sommet dans l'état applicatif
          const location = snapLocation(
            e.lngLat,
            this.getSnappingLocation(),
            10,
            viewport
          ).location

          if (clickLocations.indexOf(editedNode) === 0) {
            // le sommet sélectionné est le premier (et donc dernier point) du polygone
            clickLocations[clickLocations.indexOf(editedNode)] = location
            clickLocations[clickLocations.length - 1] = clickLocations[0]
          } else {
            clickLocations[clickLocations.indexOf(editedNode)] = location
          }

          this.setState({
            clickLocations,
            editedNode: [],
          })
        }
      } else if (drawing.buildingId && drawing.action === 'movePolygon') {
        setBuildingPolygon(buildingMoveTarget.geometry.coordinates[0])
        cleanBuilding()
        // this.setState({ buildingMoveTarget: null })
        // setBuilding({
        //   buildingId: buildingMoveTarget.properties.id,
        //   polygon: buildingMoveTarget.geometry.coordinates[0],
        //   height: buildingMoveTarget.properties.height,
        // })
        // setBuilding(buildingMoveTarget.geometry.coordinates[0])
        console.log(buildingMoveTarget)
      }
    },
    onHover: e => {
      if (!drawing.buildingId) {
        // récupération du polygone survolé
        const building = e.features
          ? e.features.find(f => f.layer.id === 'projects_buildings_fill')
          : null

        if (building) {
          // récupération de l'ID du polygone survolé
          this.setState({
            hoveredBuildingId: building.id,
          })
        } else {
          this.setState({
            hoveredBuildingId: null,
          })
        }
      } else if (
        drawing.buildingId &&
        drawing.action === 'changePolygon' &&
        editedNode.length === 0
      ) {
        // on cherche à s'accrocher aux sommets du polygon sélectionné
        const { location, snapped } = snapLocation(
          e.lngLat,
          clickLocations.slice(0, -1),
          10,
          viewport
        )

        if (snapped) {
          this.setState({
            mouseLocation: location,
            snappedPoint: location,
          })
        } else {
          this.setState({ mouseLocation: location, snappedPoint: [] })
        }
      } else if (
        drawing.buildingId &&
        drawing.action === 'changePolygon' &&
        editedNode.length > 0
      ) {
        const { location, snapped } = snapLocation(
          e.lngLat,
          this.getSnappingLocation(),
          10,
          viewport
        )
        if (snapped) {
          this.setState({
            mouseLocation: location,
            snappedPoint: location,
          })
        } else {
          this.setState({ mouseLocation: location, snappedPoint: [] })
        }
      }
      getHoveredElmts(e, this.props)
    },

    onMouseMove: e => {
      if (drawing.buildingId && drawing.action === 'movePolygon') {
        // 1. formatage du polygon de l'emprise du bati
        const polygonEmprise = Polygon([drawing.polygon])

        //2.recup le centroid du batiment & coords de la souris
        const empriseCentroid = Centroid(polygonEmprise).geometry.coordinates
        const mouseCoords = e.lngLat

        //3.recup la dist entre le centroid du batiment et les coords de la souris
        const posDiff = [
          empriseCentroid[0] - mouseCoords[0],
          empriseCentroid[1] - mouseCoords[1],
        ]
        //4.appliquer cet difference sur chaque coord du bati
        const buildingMoveTarget = Polygon(
          [
            drawing.polygon.map(coord => [
              coord[0] - posDiff[0],
              coord[1] - posDiff[1],
            ]),
          ],
          { id: drawing.buildingId, height: drawing.height }
        )

        // e.preventDefault()
        this.setState({ buildingMoveTarget: buildingMoveTarget })
        // console.log(polygonTarget)
      }
    },
    onMouseLeave: e => {
      cleanHoveredInfo()
    },
  }
}
