// reducteur racine combinant tous les réducteurs

import { combineReducers } from 'redux'

import project from './project/projectReducer'
import user from './user/userReducer'
import map from './map/mapReducer'
import drawing from './drawing/drawingReducer'
import search from './search/searchReducer'
import filter from './filter/updateFilterReducer'
import findCommuneReducer from './filter/findCommuneReducer'
import popupMessage from './popup/popupReducer'

export default combineReducers({
  user,
  map,
  project,
  drawing,
  search,
  filter,
  findCommuneReducer,
  popupMessage,
})
