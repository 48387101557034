import createDuck from '../createDuck'

const { reducer: departementReducer, searchDepartement } = createDuck({
  namespace: 'batir/search',
  actionName: 'searchDepartement',
  payloadName: 'departement',
  path: process.env.REACT_APP_API_PATH + 'search/searchDepartement.php',
})

export default departementReducer
export { searchDepartement }
