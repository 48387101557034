import createDuck from '../createDuck'

const { reducer: updateGabaritReducer, updateGabarit } = createDuck({
  namespace: 'batir/project',
  actionName: 'updateGabarit',
  path: process.env.REACT_APP_API_PATH + 'project/updateGabarit.php',
})

export default updateGabaritReducer
export { updateGabarit }
