import React from 'react'
import PropTypes from 'prop-types'

import Pin from '../../../common/overlays/Pin'
import { Marker } from 'react-map-gl'

const SnappedPoint = ({ point }) =>
  point.length > 0 ? (
    <Marker key={'marker-1'} longitude={point[0]} latitude={point[1]}>
      <Pin size={20} />
    </Marker>
  ) : null

SnappedPoint.propTypes = {
  point: PropTypes.array.isRequired,
}

export default SnappedPoint
