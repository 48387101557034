import createDuck from '../createDuck'
// import DEFAULT_STATE from 'store/DEFAULT_STATE'

const { reducer: logOutReducer, logOut } = createDuck({
  namespace: 'batir/user',
  actionName: 'logOut',
  path: process.env.REACT_APP_API_PATH + 'user/logOut.php',
})

export default logOutReducer
export { logOut }
