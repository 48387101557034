import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function EditRuleIcon(props) {
  return (
    <SvgIcon {...props} style={{ fontSize: '30px' }} viewBox="0 0 64 64">
      <image
        width={64}
        height={64}
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABPlBMVEUAAAAiFw0gGA0hGA0h GA0iGA4hFw4hFw4hGA0hGA0fFAohGA0iGA0gGA0gFQshGA0gGQ0hGA0hGA0gGQ4hGA0jFwz8iyj7 iyj9jCj/iCshGA78iyj8iyj8iyj8iin/kiT9iyj8iyf8iij6iin8iyj9iyj9iyj9iin/iCL//wD6 jSb7iyj8iyj8jCj/hyj9iij8iygAAAD6iiv/mTP/iiT7jSr8iyj8iygfGA78jCj/jSj8iyj8iyj7 iyf8iyj7jCf/iyP/iSf8iyj7iykhGA39jCn8iyj/kCwfFg3/iSf8iij8iyj/kiT/iy78iyj/jyb8 iygdFAr/hiT9iij9iyj9jCj7iyj/jyn/hy39iyj7jCb8iyj/lSv8iyj7iij8iyn7jCj9iyj7iyj/ jCb7iyj/jSz7iyghGA38iyj///+jS0qMAAAAZ3RSTlMATL3080uEg/h0GXW+dhjyxcPCSOMWv9p+ HuLb/blXB3/vmTKuzM1rDwEvke3fIHrgAzAFI0PB90mSJunEQuyJFg3AivFq6hc5J93GDgvhIsUa FdLT1NcZEdA83gz5P9yH0UAU2B2LNkn8pAAAAAFiS0dEabxrxLQAAAAHdElNRQfkAhASEjFdUPA8 AAACE0lEQVRYw+2YCTPDQBTHVxSlqJsq4qyirfu+0qr7pu6bsu/7fwKJRBJjw75sUJ3+ZzLdSWZ/ 895/33vThJCCCioIqSKpmKLlk0qceKV4mq4yh/iov7wCn1ZFwE/ZMVbScndOVVGJed9Hq90Bq2mQ eZ9SdzzHnT8FrKnFXd8Ca+tw1++n/CMe8orbQ14VPGQDWR4yRpaYh05AjIcfNn7agQGavnAA88tD jHKgDp2Ux73sdMp/WofetZ7nwH/jIUY5UIdOyuNerm8wzraRNDW3hHBAloet1ASGAdraO2RBD207 OkFTV3ePkIe2R6E2gN4+lRnpj7r30P6oHXoHyOBQDCCecO2hfUcHRLQfeRhgJMEDZHloHgptGJVj MEbGJyanYPqd6KJsLOIMmYVZMqeaOD+3YBAFC3sMYvIiLC0rJGkQRXs5AsMpWNFWBjEt2Murar5r 68QibqSFUibROExvGmuDKAYkoS3Y3jHWuynY2ydfAXne7/iIiHc9PiJqHvIQcQOWgxikqE8Y3xMl GsAASSJuEZMH5qSwVEL9VYcuYzw6BkaMZaivP+kNK0YlAyfbrBglH45oxKjGd3r2RYVjfdTiUxx8 dEXMQEbR84bwuRdEk3dyoU81D7LWecolXIkD7T5ew40HQJuPt3DnBdD0MXoM954ANeLD49NiBrJR cZhODL/95ck+e8Qj5PwlfrN1r8b3Cnchb1zJdt44AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTAy LTE3VDAxOjE4OjQ5LTA3OjAwV+SFCQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wMi0xN1QwMTox ODo0OS0wNzowMCa5PbUAAAAASUVORK5CYII="
      />
    </SvgIcon>
  )
}
