import React from 'react'
import { Fab, withStyles, Tooltip } from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import EditLocation from '@material-ui/icons/EditLocation'
import VerticalAlignTop from '@material-ui/icons/VerticalAlignTop'
import SelectAll from '@material-ui/icons/SelectAll'
import OpenWith from '@material-ui/icons/OpenWith'

import PropTypes from 'prop-types'

const styles = theme => ({
  overlay: {
    zIndex: 1,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
})

const EditButtonsComponent = ({ pixelBBox, classes, handleEditionMode }) =>
  pixelBBox.length > 0 ? (
    <>
      <Tooltip title={"Editer l'emprise au sol"} placement="bottom">
        <Fab
          className={classes.overlay}
          size="small"
          style={{
            top: (pixelBBox[0][1] + pixelBBox[1][1]) / 2,
            left: (pixelBBox[0][0] + pixelBBox[1][0]) / 2 - 96,
          }}
          onClick={handleEditionMode('changePolygon')}
        >
          <EditLocation />
        </Fab>
      </Tooltip>
      <Tooltip title={"Deplacer l'emprise au sol"} placement="bottom">
        <Fab
          className={classes.overlay}
          size="small"
          style={{
            top: (pixelBBox[0][1] + pixelBBox[1][1]) / 2,
            left: (pixelBBox[0][0] + pixelBBox[1][0]) / 2 - 48,
          }}
          onClick={handleEditionMode('movePolygon')}
        >
          <OpenWith />
        </Fab>
      </Tooltip>

      <Tooltip title={'Modifier la hauteur'} placement="bottom">
        <Fab
          className={classes.overlay}
          size="small"
          style={{
            top: (pixelBBox[0][1] + pixelBBox[1][1]) / 2,
            left: (pixelBBox[0][0] + pixelBBox[1][0]) / 2,
          }}
          onClick={handleEditionMode('changeHeight')}
        >
          <VerticalAlignTop />
        </Fab>
      </Tooltip>

      <Tooltip title={"Reduire l'emprise"} placement="bottom">
        <Fab
          className={classes.overlay}
          size="small"
          style={{
            top: (pixelBBox[0][1] + pixelBBox[1][1]) / 2,
            left: (pixelBBox[0][0] + pixelBBox[1][0]) / 2 + 48,
          }}
          onClick={handleEditionMode('reduce1m')}
        >
          <SelectAll />
        </Fab>
      </Tooltip>
      <Tooltip title={'Supprimer le bâtiment'} placement="bottom">
        <Fab
          className={classes.overlay}
          size="small"
          style={{
            top: (pixelBBox[0][1] + pixelBBox[1][1]) / 2,
            left: (pixelBBox[0][0] + pixelBBox[1][0]) / 2 + 96,
          }}
          onClick={handleEditionMode('delete')}
        >
          <Delete />
        </Fab>
      </Tooltip>
    </>
  ) : null

EditButtonsComponent.propTypes = {
  pixelBBox: PropTypes.array.isRequired,
  handleEditionMode: PropTypes.func.isRequired,
}

export default withStyles(styles)(EditButtonsComponent)

// const mapStateToProps = store => ({
//   thematics: store.map.thematics || [],
// })
// const mapDispatchToProps = { updateLayers }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withStyles(styles)(LegendPanel))
