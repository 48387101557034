import React, { Component } from 'react'
import { connect } from 'react-redux'
import SearchForm from './SearchForm'

import { searchCommune } from 'store/search/communeReducer'
import { searchSection } from 'store/search/sectionReducer'
import { searchNumero } from 'store/search/numeroReducer'
import { findParcelle } from 'store/map/findParcelleReducer'
import { findCommune } from 'store/filter/findCommuneReducer'
class Search extends Component {
  state = {
    commune: '',
    section: '',
    numero: '',
    tabValue: 0,
  }

  handleCommuneInputChange = inputValue => {
    if (this.props.communeOptions.length > 0 || inputValue) {
      this.props.searchCommune({ input: inputValue })
    }
  }

  handleSectionInputChange = inputValue => {
    if (this.props.sectionOptions.length > 0 || inputValue) {
      this.props.searchSection({
        input: inputValue,
        commune: this.state.commune,
      })
    }
  }

  handleNumeroInputChange = inputValue => {
    if (this.props.numeroOptions.length > 0 || inputValue) {
      this.props.searchNumero({
        input: inputValue,
        commune: this.state.commune,
        section: this.state.section,
      })
    }
  }

  handleCommuneChange = input => {
    this.setState({ commune: input ? input.value : '' })
  }

  handleSectionChange = input => {
    this.setState({ section: input ? input.value : '' })
  }

  handleNumeroChange = input => {
    this.setState({ numero: input ? input.value : '' })
  }

  handleTabChange = (e, val) =>
    this.setState({
      tabValue: val,
    })

  handleSubmit = e => {
    this.setState({ commune: '' })
    e.preventDefault()
    this.props.onClose()
    this.props.findParcelle(this.state)
  }
  handleViewOnCom = e => {
    this.setState({ commune: '' })
    e.preventDefault()
    this.props.onClose()
    this.props.findCommune({ commune: this.state.commune })
  }

  onClose = e => {
    this.setState({ commune: '' })
    e.preventDefault()
    this.props.onClose()
  }

  render() {
    const {
      searchCommune,
      searchSection,
      findParcelle,
      findCommune,
      searchNumero,
      // isLoadingSections,
      // isLoadingNumeros,
      ...otherProps
    } = this.props
    return (
      <>
        <SearchForm
          {...otherProps}
          inputs={this.state}
          handleCommuneChange={this.handleCommuneChange}
          handleSectionChange={this.handleSectionChange}
          handleNumeroChange={this.handleNumeroChange}
          handleCommuneInputChange={this.handleCommuneInputChange}
          handleSectionInputChange={this.handleSectionInputChange}
          handleNumeroInputChange={this.handleNumeroInputChange}
          handleSubmit={this.handleSubmit}
          handleTabChange={this.handleTabChange}
          handleViewOnCom={this.handleViewOnCom}
          onClose={this.onClose}
          // isLoadingSections={isLoadingSections}
          // isLoadingNumeros={isLoadingNumeros}
        />
      </>
    )
  }
}

const mapStateToProps = ({
  search: { communes, sections, numeros /*, searchSection, searchNumero*/ },
}) => ({
  communeOptions: communes || [],
  sectionOptions: sections || [],
  numeroOptions: numeros || [],
  // isLoadingSections: Boolean(
  //   searchSection && searchSection.status === 'pending'
  // ),
  // isLoadingNumeros: Boolean(searchNumero && searchNumero.status === 'pending'),
})

export default connect(mapStateToProps, {
  searchCommune,
  searchSection,
  searchNumero,
  findParcelle,
  findCommune,
})(Search)
