import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Card,
  CardContent,
  Grid,
  CardHeader,
  CardActions,
  withStyles,
  Dialog,
  Select,
  MenuItem,
} from '@material-ui/core'

// import Autocomplete from 'common/components/Autocomplete'

const styles = theme => ({
  dialogPaper: { overflow: 'visible' },
  card: {
    overflow: 'visible',
  },
  cardHeader: {
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  cardHeaderTitle: {
    color: theme.palette.primary.contrastText,
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  errorMessage: {
    marginTop: theme.spacing(2),
  },
  button: {
    backgroundColor: 'rgb(0,0,0)',
  },
})

const PluAvancementForm = ({
  classes,
  inputs,
  handleSubmit,
  // handleDepartementInputChange,
  handleDepartementChange,
  // departementOptions,
  errorMessage,
  ...otherProps
}) => {
  const departementOptions = [
    { value: '91', label: 'ESSONNE (91)' },
    { value: '92', label: 'HAUTS DE SEINE (92)' },
    { value: '75', label: 'PARIS (75)' },
    { value: '77', label: 'SEINE ET MARNE (77)' },
    { value: '93', label: 'SEINE SAINT DENIS (93)' },
    { value: '94', label: 'VAL DE MARNE (94)' },
    { value: '95', label: 'VAL D OISE (95)' },
    { value: '78', label: 'YVELINES (78)' },
  ]
  return (
    <Dialog {...otherProps} fullWidth classes={{ paper: classes.dialogPaper }}>
      <Card className={classes.card}>
        <CardHeader
          color="primary"
          classes={{
            root: classes.cardHeader,
            title: 'w3-light-grey w3-center w3-border-bottom w3-padding',
          }}
          title="Exporter l'avancement du PLU"
        />
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Select
                fullWidth
                value={inputs.departement}
                onChange={handleDepartementChange}
                name="département"
              >
                {departementOptions.map((opt, idx) => {
                  return (
                    <MenuItem key={idx} value={opt.value}>
                      {opt.label}
                    </MenuItem>
                  )
                })}
              </Select>
              {/* <Autocomplete
                // onInputChange={handleDepartementInputChange}
                onChange={handleDepartementChange}
                options={departementOptions}
                name="département"
                noOptionsMessage={() => 'aucun département correspondant'}
                placeholder="Chercher un département..."
              /> */}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions classes={{ root: classes.cardActions }}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="secondary"
            className={classes.button}
          >
            Export
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  )
}

PluAvancementForm.defaultProps = {
  inputs: {},
}

PluAvancementForm.propTypes = {
  inputs: PropTypes.shape({
    departement: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func,
}

export default withStyles(styles)(PluAvancementForm)
