import createDuck from '../createDuck'
import { getUserProjects } from 'store/user/userProjectsReducer'

const { reducer: deleteProjectReducer, deleteProject } = createDuck({
  namespace: 'batir/project',
  actionName: 'deleteProject',
  path: process.env.REACT_APP_API_PATH + 'project/deleteProject.php',
  callback: function() {
    return (dispatch, getState) => {
      dispatch(getUserProjects())
    }
  },
})

export default deleteProjectReducer
export { deleteProject }
