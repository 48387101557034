import React from 'react'
import PropTypes from 'prop-types'
import { Snackbar, IconButton, withStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
  snackbar: {
    margin: theme.spacing(1),
  },

  icon: {
    marginRight: theme.spacing(1),
  },
})

const FailedPopup = ({ classes, handleClose, open, message }) => (
  <Snackbar
    className={classes.snackbar}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    open={open}
    autoHideDuration={6000}
    onClose={handleClose}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
    message={<span id="message-id">{message}</span>}
    action={
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    }
  />
)

FailedPopup.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default withStyles(styles)(FailedPopup)
