import Axios from 'axios'
import { validSession } from './user/validSessionReducer'

export default function createDuck({
  namespace,
  path,
  actionName,
  payloadName = null,
  initialState = {},
  callback = null,
}) {
  // passage en majuscules avec _ du nom de l'action
  const upperName = actionName.replace(/[A-Z]/g, m => '_' + m).toUpperCase()

  // Actions
  const QUERY_PENDING = `${namespace}/${upperName}_PENDING`
  const QUERY_FULFILLED = `${namespace}/${upperName}_FULFILLED`
  const QUERY_REJECTED = `${namespace}/${upperName}_REJECTED`

  // Reducer
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case QUERY_PENDING:
        return {
          ...state,
          [actionName]: { status: 'pending' },
        }
      case QUERY_FULFILLED:
        return {
          ...state,
          [actionName]: { status: 200 },
          ...action.payload,
        }

      case QUERY_REJECTED:
        return { ...state, [actionName]: { ...action.payload } }

      default:
        return state
    }
  }

  // Action creators
  const queryStart = () => ({
    type: QUERY_PENDING,
  })

  const querySuccess = payload => ({
    type: QUERY_FULFILLED,
    payload: payloadName ? { [payloadName]: payload } : payload,
  })

  const queryFail = status => ({
    type: QUERY_REJECTED,
    payload: { status },
  })

  // Async action creator
  const actionCreator = params => {
    return (dispatch, getState) => {
      dispatch(queryStart())

      Axios.post(path, { ...params })
        .then(res => {
          dispatch(querySuccess(res.data))

          // appel du callback passé en paramètre
          if (callback) dispatch(callback(res.data))

          // la session est correcte et toujours active
          if (!getState().user.session.isValid)
            dispatch(
              validSession({ isValid: true, message: 'Connexion réussie !' })
            )
        })
        .catch(err => {
          if (err.response) {
            dispatch(queryFail(err.response.status))
            // la session est expirée ou invalide
            if (
              err.response.status === 401 &&
              getState().user.session.isValid !== false
            )
              dispatch(
                validSession({
                  isValid: false,
                  message: err.response.data.error,
                })
              )
          }
        })
    }
  }

  return {
    reducer,
    [actionName]: actionCreator,
  }
}
