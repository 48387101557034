import createDuck from '../createDuck'

const { reducer: getUserProjectsReducer, getUserProjects } = createDuck({
  namespace: 'batir/user',
  actionName: 'getUserProjects',
  payloadName: 'userProjects',
  path: process.env.REACT_APP_API_PATH + 'user/getUserProjects.php',
})

export default getUserProjectsReducer
export { getUserProjects }
