import createDuck from '../createDuck'

const { reducer: loginReducer, logIn } = createDuck({
  namespace: 'batir/user',
  actionName: 'logIn',
  path: process.env.REACT_APP_API_PATH + 'user/logIn.php',
})

export default loginReducer
export { logIn }
