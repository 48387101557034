import React from 'react'
import PropTypes from 'prop-types'

import {
  Card,
  CardContent,
  Grid,
  CardHeader,
  withStyles,
  Dialog,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core'

import Autocomplete from 'common/components/Autocomplete'

const styles = (theme) => ({
  dialogPaper: { overflow: 'visible' },
  card: {
    overflow: 'visible',
  },
  cardHeader: {
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  cardHeaderTitle: {
    color: theme.palette.primary.contrastText,
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  errorMessage: {
    marginTop: theme.spacing(2),
  },
  button: {
    backgroundColor: 'rgb(0,0,0)',
  },
  formControl: {
    minWidth: '100%',
  },
})

const PluReglementForm = ({
  classes,
  inputs,
  handleSubmit,
  handleCommuneInputChange,
  handleCommuneChange,
  communeOptions,
  errorMessage,
  ...otherProps
}) => {
  return (
    <Dialog {...otherProps} fullWidth classes={{ paper: classes.dialogPaper }}>
      <Card className={classes.card}>
        <CardHeader
          color="primary"
          classes={{
            root: classes.cardHeader,
            title: 'w3-light-grey w3-center w3-border-bottom w3-padding',
          }}
          title="Télécharger la réglementation du PLU"
        />
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Autocomplete
                onInputChange={handleCommuneInputChange}
                onChange={handleCommuneChange}
                options={communeOptions || []}
                name="commune"
                noOptionsMessage={() =>
                  'Aucune commune correspondante ayant validé les procedures'
                }
                placeholder="Chercher une commune..."
              />
              {Boolean(inputs.documentsList.length > 0) && (
                <FormControl className={classes.formControl}>
                  <InputLabel
                    shrink
                    id="demo-simple-select-placeholder-label-label"
                  >
                    Liste des documents disponible
                  </InputLabel>
                  <Select
                    fullWidth
                    displayEmpty
                    defaultValue=""
                    onChange={(e) =>
                      e.target.value !== '' && window.open(e.target.value)
                    }
                    name="pdfListe"
                  >
                    {inputs.documentsList.map((opt, idx) => {
                      return (
                        <MenuItem key={idx} value={opt.value}>
                          <span
                            className={
                              opt.value === ''
                                ? 'w3-wide w3-light-grey w3-border-bottom w3-padding-small'
                                : null
                            }
                          >
                            {opt.label}
                          </span>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Dialog>
  )
}

PluReglementForm.defaultProps = {
  inputs: {},
}

PluReglementForm.propTypes = {
  inputs: PropTypes.shape({
    departement: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func,
}

export default withStyles(styles)(PluReglementForm)
