import React, { Component } from 'react'
import { connect } from 'react-redux'
import ChartePromForm from './ChartePromForm'

import { searchCommune } from 'store/search/communeReducer'

class CharteProm extends Component {
  state = {
    commune: null,
    downloading: false,
    feedback: null,
  }

  handleCommuneInputChange = (inputValue) => {
    if (this.props.communeOptions.length > 0 || inputValue) {
      this.props.searchCommune({ input: inputValue, withChartProm: true })
    }
  }

  handleCommuneChange = (input) => {
    this.setState({
      commune: input ? input : '',
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    window.open(
      process.env.REACT_APP_ABS_API_PATH +
        'documents/getChartePromFile.php?commune=' +
        this.state.commune.value
    )
  }

  onClose = (e) => {
    this.setState({ commune: '' })
    e.preventDefault()
    this.props.onClose()
  }

  render() {
    const { searchCommune, ...otherProps } = this.props
    return (
      <>
        <ChartePromForm
          {...otherProps}
          inputs={this.state}
          handleCommuneInputChange={this.handleCommuneInputChange}
          handleCommuneChange={this.handleCommuneChange}
          handleSubmit={this.handleSubmit}
          onClose={this.onClose}
        />
      </>
    )
  }
}

const mapStateToProps = ({ search: { communes } }) => ({
  communeOptions:
    communes && communes.length !== null
      ? communes.filter((elmt) => elmt.hasFile)
      : [],
})

export default connect(mapStateToProps, { searchCommune })(CharteProm)
