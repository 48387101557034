import React from 'react'

import { withStyles, Typography, Grid, Button } from '@material-ui/core'
import HelpBox from '../../../common/components/HelpBox'

const styles = theme => ({
  typography: {
    textAlign: 'center',
  },

  button: {
    backgroundColor: 'rgb(0,0,0)',
  },
})

const EditDialogComponent = ({
  open,
  classes,
  applyEdition,
  cancelEdition,
}) => {
  return (
    <HelpBox open={open}>
      <Typography variant="body1" className={classes.typography} gutterBottom>
        Vous pouvez éditer l'emprise du bâtiment sélectionné en cliquant sur un
        de ses sommets.
      </Typography>
      <Grid container justify="space-around">
        <Grid item container justify="center" xs>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={applyEdition}
          >
            Valider
          </Button>
        </Grid>
        <Grid item container justify="center" xs>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={cancelEdition}
          >
            Annuler
          </Button>
        </Grid>
      </Grid>
    </HelpBox>
  )
}

export default withStyles(styles)(EditDialogComponent)
