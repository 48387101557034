import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Form from './Form'
import { logIn } from 'store/user/logInReducer'
import FailedPopup from '../../common/components/FailedPopup'
import video from './video/zoom-avant-france.mov'
import logoUrbascope2 from '../../assets/logoUrbascope2.png'

class Login extends Component {
  state = {
    email: '',
    password: '',
    popupOpened: true,
  }

  handleClosePopup = e => {
    this.setState({ popupOpened: false })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { email, password } = this.state
    this.props.logIn({ email, password })
  }

  componentDidUpdate(prevProps) {
    if (this.props.session.message !== prevProps.session.message) {
      this.setState({ popupOpened: true }) // réinitialisation de l'ouverture du popup au changement de message
    }
  }

  render() {
    const { from } = this.props.location.state || {
      from: { pathname: '/home' },
    }

    if (this.props.session.isValid) {
      return <Redirect to={from} />
    }

    return (
      <div>
        <video
          loop={true}
          autoPlay={true}
          muted={true}
          style={{
            zIndex: -1,
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            objectFit: 'cover',
          }}
          id="background-video"
        >
          <source src={video} type="video/webm" />
        </video>
        {this.props.session.isValid === false ? (
          <FailedPopup
            open={
              this.props.session.isValid === false && this.state.popupOpened
            }
            handleClose={this.handleClosePopup}
            message={this.props.session.message}
          />
        ) : null}
        <Form
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          {...this.state}
        />
        <div
          className="w3-container w3-padding-large w3-text-white"
          style={{ position: 'fixed', bottom: 0, width: '100%' }}
        >
          <p className="w3-center">
            <img src={logoUrbascope2} width="30%" alt="logoUrbascope2" />
            {/* <p className="w3-center">
              <a
                href="projet.php"
                className="w3-button w3-light-grey w3-center w3-large w3-padding-16 w3-round-large"
                style={{ textDecoration: 'none' }}
              >
                Connexion
                <i className="fa fa-plus w3-text-deep-orange" />
              </a>
            </p> */}
          </p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ user: { session } }) => {
  return { session }
}

export default connect(mapStateToProps, { logIn })(Login)
