import createDuck from '../createDuck'
import makePdf from '../../common/pdfMake/pdfMake'

const { reducer: pdfReducer, exportPdf } = createDuck({
  namespace: 'batir/project',
  actionName: 'exportPdf',
  payloadName: 'projectImplentation',
  path:
    process.env.REACT_APP_API_PATH +
    'project/projectImplentation/simulateProjectImplentation.php',
  callback: function(projectImplentation) {
    return (dispatch, getState) => {
      // console.log(projectImplentation)
      makePdf(projectImplentation)
    }
  },
})

export default pdfReducer
export { exportPdf }
