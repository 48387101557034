import getHoveredElmts from './hoveredElmts'

export default function() {
  const cleanHoveredInfo = this.props.cleanHoveredInfo

  return {
    onHover: e => {
      getHoveredElmts(e, this.props)
    },
    onMouseLeave: e => {
      cleanHoveredInfo()
    },
  }
}
