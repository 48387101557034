import React, { Component } from 'react'
import { connect } from 'react-redux'
import MapToolTipsComponent from './MapToolTipsComponent'

class MapToolTipsContainer extends Component {
  render() {
    return <MapToolTipsComponent {...this.props} />
  }
}

const mapStateToProps = ({ map }) => ({
  visible:
    (map.interaction === 'create' || map.interaction === 'measure') &&
    !map.drawInteraction.buildingPolygon,
  interaction: map.interaction,
})

export default connect(mapStateToProps)(MapToolTipsContainer)
