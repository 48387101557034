import createDuck from '../createDuck'

const { reducer: refreshBuildingsReducer, refreshBuildings } = createDuck({
  namespace: 'batir/project',
  actionName: 'refreshBuildings',
  payloadName: 'projectBuildings',
  path:
    process.env.REACT_APP_API_PATH + 'project/building/refreshBuildings.php',
})

export default refreshBuildingsReducer
export { refreshBuildings }
