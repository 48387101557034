import React, { Component } from 'react'
import { connect } from 'react-redux'
import HomeLayout from './HomeLayout'

import { getUserProjects } from '../../store/user/userProjectsReducer'
import { logOut } from 'store/user/logOutReducer'
import { deleteProject } from 'store/project/deleteProjectReducer'

class Home extends Component {
  state = { anchorMenuEl: null, isMenuOpen: false }
  componentDidMount() {
    this.props.getUserProjects()
  }

  // handleMenuOpen = e => {
  //   this.setState({ isMenuOpen: true, anchorMenuEl: e.currentTarget })
  // }

  // handleMenuClose = e => {
  //   this.setState({ isMenuOpen: false, anchorMenuEl: null })
  // }

  deleteProject = projectId => {
    this.props.deleteProject({ projectId: projectId })
  }

  render() {
    return (
      <HomeLayout
        data={this.props.userProjects}
        {...this.state}
        logOut={this.props.logOut}
        // handleMenuClose={this.handleMenuClose}
        // handleMenuOpen={this.handleMenuOpen}
        deleteProject={this.deleteProject}
      />
    )
  }
}

const mapStateToProps = ({ user: { userProjects } }) => {
  return { userProjects }
}

export default connect(
  mapStateToProps,
  { getUserProjects, logOut, deleteProject }
)(Home)
