import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import ProjectForm from './ProjectForm'

import { searchCommune } from 'store/search/communeReducer'
import { createProject } from 'store/project/createProjectReducer'

class NewProject extends Component {
  state = {
    commune: '',
    title: '',
    descr: '',
  }

  handleAutocompleteInputChange = inputValue => {
    if (this.props.autoCompleteOptions.length > 0 || inputValue) {
      this.props.searchCommune({ input: inputValue })
    }
  }

  isValidTitle = () =>
    Boolean(this.state.title.length > 0 && this.state.title.length <= 25)

  isValidDescription = () => Boolean(this.state.descr.length < 40)

  isValidInputs = () =>
    Boolean(this.isValidTitle() && this.isValidDescription())

  handleAutocompleteChange = input => {
    this.setState({ commune: input ? input.value : '' })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.createProject(this.state)
  }

  render() {
    const {
      searchCommune,
      createProject,
      projectId,
      ...otherProps
    } = this.props
    return projectId ? (
      <Redirect to={`/project/${projectId}`} />
    ) : (
      <ProjectForm
        {...otherProps}
        inputs={this.state}
        handleAutocompleteChange={this.handleAutocompleteChange}
        handleAutocompleteInputChange={this.handleAutocompleteInputChange}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        isValidTitle={this.isValidTitle()}
        isValidDescription={this.isValidDescription()}
        isValidInputs={this.isValidInputs()}
      />
    )
  }
}

const mapStateToProps = ({
  search: { communes },
  project: { createProject, projectId },
}) => ({
  autoCompleteOptions: communes || [],
  errorMessage:
    createProject && createProject.errorMessage
      ? createProject.errorMessage
      : null,
  projectId: projectId ? projectId : null,
})

export default connect(mapStateToProps, { searchCommune, createProject })(
  NewProject
)
