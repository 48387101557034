import React, { Component } from 'react'
import { connect } from 'react-redux'
import PluReglementForm from './PluReglementForm'

import { searchCommune } from 'store/search/communeReducer'

import Axios from 'axios'

class PluReglement extends Component {
  state = {
    commune: null,
    documentsList: [],
  }

  handleCommuneInputChange = (inputValue) => {
    if (this.props.communeOptions.length > 0 || inputValue) {
      this.props.searchCommune({ input: inputValue })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    window.open(
      process.env.REACT_APP_HDS_DOCS + this.state.commune.value + '.zip'
    )
  }

  handleCommuneChange = (input) => {
    if (input) {
      Axios.get(
        process.env.REACT_APP_API_PATH +
          'search/getPluDocs.php?commune=' +
          input.value
      ).then(({ data }) => {
        let xmltxt = data
          .split('$XML_ITEM')[1]
          .split("4 => '")[1]
          .split(", 5 => '")[0]
          .split('<item>')
        let loadedDocumentList = []
        xmltxt.forEach((txt) => {
          if (txt) {
            const title = txt.split('<title>')[1].split('</title>')[0]
            const link = txt.split('<link>')[1].split('</link>')[0]
            loadedDocumentList.push({ label: title, value: link })
          } else {
            loadedDocumentList.push({
              label: "Plans Locaux d'urbanisme ",
              value: '',
            })
          }
        })
        this.setState({
          documentsList: loadedDocumentList,
        })
      })
    } else {
      this.setState({ documentsList: [] })
    }
  }

  onClose = (e) => {
    this.setState({ commune: '' })
    e.preventDefault()
    this.props.onClose()
  }

  render() {
    const { searchCommune, ...otherProps } = this.props
    return (
      <>
        <PluReglementForm
          {...otherProps}
          inputs={this.state}
          handleCommuneInputChange={this.handleCommuneInputChange}
          handleSubmit={this.handleSubmit}
          handleCommuneChange={this.handleCommuneChange}
          onClose={this.onClose}
        />
      </>
    )
  }
}

const mapStateToProps = ({ search: { communes } }) => ({
  communeOptions: communes && communes.length !== null ? communes : [],
})

export default connect(mapStateToProps, { searchCommune })(PluReglement)
