import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'

import miniSat from '../../../assets/miniSat.png'
import miniPlan from '../../../assets/miniPlan.png'

import { switchSatellite } from 'store/map/mapReducer'

const styles = theme => ({
  miniature: {
    border: '0.1em solid white',
  },
})

class SatelliteSwitcher extends Component {
  handleChange = e => {
    this.props.switchSatellite()
  }
  render() {
    const { satellite } = this.props

    return (
      <img
        className={this.props.classes.miniature}
        onClick={this.handleChange}
        src={satellite ? miniPlan : miniSat}
        alt="Sat View"
      />
    )
  }
}

export default withStyles(styles)(
  connect(
    ({ map: { satellite } }) => ({ satellite }),
    { switchSatellite }
  )(SatelliteSwitcher)
)
