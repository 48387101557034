import createDuck from '../createDuck'

const { reducer: sectionReducer, searchSection } = createDuck({
  namespace: 'batir/search',
  actionName: 'searchSection',
  payloadName: 'sections',
  path: process.env.REACT_APP_API_PATH + 'search/searchSection.php',
})

export default sectionReducer
export { searchSection }
