import dvf2014 from './dvf2014.png'
import dvf2015 from './dvf2015.png'
import dvf2016 from './dvf2016.png'
import dvf2017 from './dvf2017.png'
import dvf2018 from './dvf2018.png'

import services from './services.png'
import commerces from './commerces.png'
import enseignement from './enseignement.png'
import sante from './sante.png'
import transports from './transports.png'
import loisirs from './loisirs.png'

import services_publics from './services_publics.png'

import metro from './metro.png'
import train from './train.png'

export const loadImages = map => {
  // dvf 14-18
  map.loadImage(dvf2014, function(error, image) {
    if (error) throw error
    map.addImage('dvf2014', image)
  })
  map.loadImage(dvf2015, function(error, image) {
    if (error) throw error
    map.addImage('dvf2015', image)
  })
  map.loadImage(dvf2016, function(error, image) {
    if (error) throw error
    map.addImage('dvf2016', image)
  })
  map.loadImage(dvf2017, function(error, image) {
    if (error) throw error
    map.addImage('dvf2017', image)
  })
  map.loadImage(dvf2018, function(error, image) {
    if (error) throw error
    map.addImage('dvf2018', image)
  })

  // bpe
  map.loadImage(services, function(error, image) {
    if (error) throw error
    map.addImage('services', image)
  })
  map.loadImage(commerces, function(error, image) {
    if (error) throw error
    map.addImage('commerces', image)
  })
  map.loadImage(enseignement, function(error, image) {
    if (error) throw error
    map.addImage('enseignement', image)
  })
  map.loadImage(sante, function(error, image) {
    if (error) throw error
    map.addImage('sante', image)
  })
  map.loadImage(transports, function(error, image) {
    if (error) throw error
    map.addImage('transports', image)
  })
  map.loadImage(loisirs, function(error, image) {
    if (error) throw error
    map.addImage('loisirs', image)
  })

  //services_publics
  map.loadImage(services_publics, function(error, image) {
    if (error) throw error
    map.addImage('services_publics', image)
  })

  // transports
  map.loadImage(metro, function(error, image) {
    if (error) throw error
    map.addImage('metro', image)
  })
  map.loadImage(train, function(error, image) {
    if (error) throw error
    map.addImage('train', image)
  })
}
