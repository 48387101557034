import React from 'react'
import { NumberFormatCustom } from '../../../common/components/NumberFormatter'

import { TextField, Grid, Typography, InputAdornment } from '@material-ui/core'

const SurfaceCalculatorLogement = ({
  handleChange,
  inputs,
  surfParEtage,
  sommeSurfLog,
  sommeNbStation,
  isSommeSurfLogValid,
  aireParcel,
}) => {
  return (
    <>
      <Grid // ----------------------------------------------------STUDIO
        container
        spacing={1}
        alignItems="center"
        style={{ paddingBottom: '0.4em' }}
      >
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Nombre de studio"
            value={inputs.nbStu}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleChange('nbStu')}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Rendement"
            value={inputs.rendLog}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onChange={handleChange('rendLog')}
            helperText="(Shab/Sdp)"
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="surface d'un studio"
            value={inputs.surfLogStu}
            name="surfLogStu"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
            onChange={handleChange('surfLogStu')}
            helperText={
              'en Shab, soit ' +
              parseFloat((inputs.surfLogStu * 100) / inputs.rendLog).toFixed(
                1
              ) +
              'm² Sdp'
            }
          />
        </Grid>
        <Grid item xs={1}>
          <Typography align="center" variant="h6">
            =>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            disabled
            label="Surface totale des studios "
            value={parseFloat(inputs.surfLogStu * inputs.nbStu).toFixed(1)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
            helperText={
              'en Shab, soit ' +
              parseFloat(
                (inputs.surfLogStu * inputs.nbStu * 100) / inputs.rendLog
              ).toFixed(1) +
              'm² Sdp'
            }
          />
        </Grid>

        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Nb de place de stationnement par studios"
            value={inputs.stStu}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleChange('stStu')}
            helperText={'soit un totale de ' + inputs.nbStu * inputs.stStu}
          />
        </Grid>
      </Grid>

      <Grid // ----------------------------------------------------2-PIECE
        container
        spacing={1}
        alignItems="center"
        style={{ paddingBottom: '0.4em' }}
      >
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Nombre de 2-pièces "
            value={inputs.nb2p}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleChange('nb2p')}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="surface d'un 2-pièces"
            value={inputs.surfLog2p}
            name="surfLog2p"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
            onChange={handleChange('surfLog2p')}
            helperText={
              'en Shab, soit ' +
              parseFloat((inputs.surfLog2p * 100) / inputs.rendLog).toFixed(1) +
              'm² Sdp'
            }
          />
        </Grid>
        <Grid item xs={1}>
          <Typography align="center" variant="h6">
            =>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            disabled
            label="Surface totale des 2-pièces"
            value={inputs.surfLog2p * inputs.nb2p}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
            helperText={
              'en Shab, soit ' +
              parseFloat(
                (inputs.surfLog2p * inputs.nb2p * 100) / inputs.rendLog
              ).toFixed(1) +
              'm² Sdp'
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Nb de place de stationnement par 2-pièces"
            value={inputs.st2p}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleChange('st2p')}
            helperText={'soit un totale de ' + inputs.nb2p * inputs.st2p}
          />
        </Grid>
      </Grid>

      <Grid // ----------------------------------------------------3-PIECE
        container
        spacing={1}
        alignItems="center"
        style={{ paddingBottom: '0.4em' }}
      >
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Nombre de 3-pièces"
            value={inputs.nb3p}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleChange('nb3p')}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="surface d'un 3-pièces"
            value={inputs.surfLog3p}
            name="surfLog3p"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
            onChange={handleChange('surfLog3p')}
            helperText={
              'en Shab, soit ' +
              parseFloat((inputs.surfLog3p * 100) / inputs.rendLog).toFixed(1) +
              'm² Sdp'
            }
          />
        </Grid>
        <Grid item xs={1}>
          <Typography align="center" variant="h6">
            =>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            disabled
            label="Surface totale des 3-pièces"
            value={inputs.surfLog3p * inputs.nb3p}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
            helperText={
              'en Shab, soit ' +
              parseFloat(
                (inputs.surfLog3p * inputs.nb3p * 100) / inputs.rendLog
              ).toFixed(1) +
              'm² Sdp'
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Nb de place de stationnement par 3-pièces"
            value={inputs.st3p}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleChange('st3p')}
            helperText={'soit un totale de ' + inputs.nb3p * inputs.st3p}
          />
        </Grid>
      </Grid>

      <Grid // ----------------------------------------------------4-PIECE
        container
        spacing={1}
        alignItems="center"
        style={{ paddingBottom: '0.4em' }}
      >
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Nombre de 4-pièces"
            value={inputs.nb4p}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleChange('nb4p')}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="surface d'un 4-pièces"
            value={inputs.surfLog4p}
            name="surfLog4p"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
            onChange={handleChange('surfLog4p')}
            helperText={
              'en Shab, soit ' +
              parseFloat((inputs.surfLog4p * 100) / inputs.rendLog).toFixed(1) +
              'm² Sdp'
            }
          />
        </Grid>
        <Grid item xs={1}>
          <Typography align="center" variant="h6">
            =>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            disabled
            label="Surface totale des 4-pièces"
            value={inputs.surfLog4p * inputs.nb4p}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
            helperText={
              'en Shab, soit ' +
              parseFloat(
                (inputs.surfLog4p * inputs.nb4p * 100) / inputs.rendLog
              ).toFixed(1) +
              'm² Sdp'
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Nb de place de stationnement par 4-pièces"
            value={inputs.st4p}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleChange('st4p')}
            helperText={'soit un totale de ' + inputs.nb4p * inputs.st4p}
          />
        </Grid>
      </Grid>

      <Grid // ----------------------------------------------------5-PIECE
        container
        spacing={1}
        alignItems="center"
        style={{ paddingBottom: '0.4em' }}
      >
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Nombre de 5-pièces"
            value={inputs.nb5p}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleChange('nb5p')}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="surface d'un 5-pièces"
            value={inputs.surfLog5p}
            name="surfLog5p"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
            onChange={handleChange('surfLog5p')}
            helperText={
              'en Shab, soit ' +
              parseFloat((inputs.surfLog5p * 100) / inputs.rendLog).toFixed(1) +
              'm² Sdp'
            }
          />
        </Grid>
        <Grid item xs={1}>
          <Typography align="center" variant="h6">
            =>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            disabled
            label="Surface totale des 5-pièces "
            value={inputs.surfLog5p * inputs.nb5p}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
            helperText={
              'en Shab, soit ' +
              parseFloat(
                (inputs.surfLog5p * inputs.nb5p * 100) / inputs.rendLog
              ).toFixed(1) +
              'm² Sdp'
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Nb de place de stationnement par 5-pièces"
            value={inputs.st5p}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleChange('st5p')}
            helperText={'soit un totale de ' + inputs.nb5p * inputs.st5p}
          />
        </Grid>
      </Grid>
      {/* <Grid
        container
        spacing={1}
        alignItems="center"
        style={{ paddingBottom: '0.4em' }}

      >
        <Grid item xs={3}>
          <TextField
color="secondary"
            fullWidth
            label="Epaisseur des murs"
            value={inputs.surfCsstLog}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onChange={handleChange('surfCsstLog')}
          />
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6" align="center">
            =>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" align="center">
            {surfParEtage + ' m²'}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography align="center" variant="h6">
            =
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
color="secondary"
            fullWidth
            disabled
            label="Surface totale de l'épaisseur des murs "
            value={(surfParEtage * inputs.surfCsstLog) / 100}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
          />
        </Grid>
      </Grid> */}

      <Grid
        container
        spacing={1}
        alignItems="center"
        style={{ paddingBottom: '0.4em' }}
      >
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Circulation/sanitaire/service et technique"
            value={inputs.surfCsstLog}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onChange={handleChange('surfCsstLog')}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Typography variant="h6" align="center">
            {surfParEtage + ' m²'}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography align="center" variant="h6">
            =
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            disabled
            helperText={'Circulation Sanitaire Service et Technique'}
            label="Surface totale C.S.S.T."
            value={(surfParEtage * inputs.surfCsstLog) / 100}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            color="secondary"
            fullWidth
            label="Surface d'un stationnement"
            value={inputs.surfStationn}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
            onChange={handleChange('surfStationn')}
            helperText={
              'pour un total de ' +
              sommeNbStation +
              ' places soit ' +
              sommeNbStation * inputs.surfStationn +
              'm²'
            }
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        alignItems="center"
        style={{ paddingBottom: '0.4em' }}
      >
        <Grid item xs={2} />

        <Grid item xs={8}>
          <Typography
            variant="h6"
            align="center"
            className="w3-indigo w3-padding-small"
            color={!isSommeSurfLogValid ? 'error' : 'initial'}
          >
            {'Somme des surfaces restantes à ' +
              (isSommeSurfLogValid ? 'attribuer ' : 'retirer ') +
              ': ' +
              (surfParEtage - sommeSurfLog).toFixed(2) +
              ' m², nombre de sous-sols à prevoir : ' +
              Math.ceil(aireParcel / (sommeNbStation * inputs.surfStationn))}
          </Typography>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </>
  )
}

export default SurfaceCalculatorLogement
