import React, { Component } from 'react'
import {
  Dialog,
  withStyles,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core'
import { connect } from 'react-redux'
// import { Redirect } from 'react-router-dom'
// import ProjectForm from './ProjectForm'

import { getUserContacts } from 'store/user/userContactsReducer'

const styles = (theme) => ({
  dialogPaper: { overflow: 'visible' },
  card: {
    overflow: 'visible',
  },
  cardHeader: {
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  cardHeaderTitle: {
    color: theme.palette.primary.contrastText,
  },
  // cardActions: {
  //   justifyContent: 'flex-end',
  // },
  // errorMessage: {
  //   marginTop: theme.spacing(2),
  // },
})

class CollabWindow extends Component {
  state = {}

  componentDidUpdate(prevProps, prevstate) {
    const { open, userContacts } = this.props
    if (Boolean(open && open !== prevProps.open)) {
      //si la fenetre vien de s'ouvrir, on requête la liste des contacts
      this.props.getUserContacts()
    }
    console.log(userContacts)
  }

  //fake
  // usersContacts = [
  //   {
  //     name: 'test',
  //     email: 'test@geocible.fr',
  //   },
  // ]

  render() {
    const {
      classes,
      userContacts,
      getUserContacts,
      // createProject,
      // projectId,
      ...otherProps
    } = this.props
    return (
      <Dialog
        {...otherProps}
        fullWidth
        classes={{ paper: classes.dialogPaper }}
      >
        <Card className={classes.card}>
          <CardHeader
            color="primary"
            classes={{
              root: classes.cardHeader,
              title: classes.cardHeaderTitle,
            }}
            title="Mes interlocuteurs"
          />
          <CardContent>{/* <div></div> */}</CardContent>
        </Card>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ user: { userContacts } }) => ({
  userContacts,
})

export default connect(mapStateToProps, { getUserContacts })(
  withStyles(styles)(CollabWindow)
)
