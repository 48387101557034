import React, { Component } from 'react'
import { connect } from 'react-redux'

import { updateLayers } from '../../../store/map/mapReducer'

import {
  Drawer,
  withStyles,
  /*IconButton Typography*/
} from '@material-ui/core'
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import ThematicsList from './ThematicsList'

const styles = theme => ({
  drawerHeader: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: '#1D1D1D',
    justifyContent: 'space-between',
  },
  // title: { color: theme.palette.primary.contrastText },
  drawer: { width: 280 },
  paper: {
    background: '#000000',
    width: 280,
    height: `calc(100% - ${65}px)`,
    marginTop: 65,
  },
  chevronIcon: { color: theme.palette.primary.main },
})

class LeftPanel extends Component {
  state = {
    tabValue: 0,
  }
  toggleLayer = idx => e => {
    e.stopPropagation()
    // régénération du tableau des couches de la thématique
    const updatedLayers = this.props.thematics[idx].layers.map(layer =>
      layer.layerId === e.target.value
        ? { ...layer, visible: e.target.checked }
        : layer
    )

    // régénération du tableau des thématiques
    const updatedThematics = [...this.props.thematics]
    updatedThematics[idx].layers = updatedLayers
    this.props.updateLayers(updatedThematics)
  }

  checkAllDvfLayers = idx => e => {
    e.stopPropagation()
    // régénération du tableau des couches de la thématique
    const updatedLayers = this.props.thematics[idx].layers.map(layer => {
      return {
        ...layer,
        visible: layer.layerId.startsWith('dvf_idf')
          ? e.target.checked
          : layer.visible,
      }
    })

    // régénération du tableau des thématiques
    const updatedThematics = [...this.props.thematics]
    updatedThematics[idx].layers = updatedLayers
    this.props.updateLayers(updatedThematics)
  }

  handleTabChange = (e, val) =>
    this.setState({
      tabValue: val,
    })

  render() {
    const { classes, thematics, open, handleDrawerToggle } = this.props
    return (
      <Drawer
        open={open}
        variant="persistent"
        onClose={() => handleDrawerToggle('openedLayerDrawer')}
        classes={{ root: classes.drawer, paper: classes.paper }}
        elevation={4}
        PaperProps={{ elevation: 4 }}
      >
        <ThematicsList
          data={thematics}
          checkAllDvfLayers={this.checkAllDvfLayers}
          isAllChecked={this.isAllChecked}
          toggleLayer={this.toggleLayer}
        />
      </Drawer>
    )
  }
}

const mapStateToProps = store => ({
  thematics: store.map.thematics || [],
})
const mapDispatchToProps = { updateLayers }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LeftPanel))
