import React, { Component } from 'react'
import { connect } from 'react-redux'
import CalibrationForm from './CalibrationForm'
import { calibrationValues } from '../../../common/dataMapper'
import { withStyles } from '@material-ui/core'

import { updateGabarit } from 'store/project/updateGabaritReducer'

const styles = theme => ({
  // root: {
  //   flexGrow: 1,
  //   backgroundColor: theme.palette.background.paper,
  // },
})

class CalibrationConfig extends Component {
  state = {}
  componentDidMount() {
    if (this.props.project.gabaritConfig) {
      this.setState(calibrationValues(this.props.project.gabaritConfig))
    }
  }

  // setDefaultConfig = () => {
  //   this.setState({})
  // }

  updateGabarit = e => {
    e.preventDefault()
    this.props.updateGabarit({
      projectId: this.props.project.projectId,
      config: this.state,
    })
    this.props.onClose()
  }
  handleClose = () => {
    this.setState(calibrationValues(this.props.project.gabaritConfig))
    this.props.onClose()
  }
  handleLogeEntryChange = (entryName, defVal = 1) => e => {
    let saisie = e.target.value
    saisie = saisie === 0 || !saisie ? defVal : saisie
    saisie = saisie > 100000 ? 100000 : saisie
    const entry = this.state.logements[entryName]
    this.setState({
      logements: {
        ...this.state.logements,
        [entryName]: { ...entry, value: saisie },
      },
    })
  }

  handleBureEntryChange = (entryName, defVal = 1) => e => {
    let saisie = e.target.value
    saisie = saisie === 0 || !saisie ? defVal : saisie
    saisie = saisie > 100000 ? 100000 : saisie
    const entry = this.state.bureaux[entryName]
    this.setState({
      bureaux: {
        ...this.state.bureaux,
        [entryName]: { ...entry, value: saisie },
      },
    })
  }

  switchGabaritType = () => {
    this.setState({ isBatiLogement: !this.state.isBatiLogement })
  }

  render() {
    const { classes, project, ...otherProps } = this.props
    return (
      <CalibrationForm
        {...otherProps}
        inputs={this.state}
        updateGabarit={this.updateGabarit}
        handleClose={this.handleClose}
        handleLogeEntryChange={this.handleLogeEntryChange}
        handleBureEntryChange={this.handleBureEntryChange}
        switchGabaritType={this.switchGabaritType}
      />
    )
  }
}

const mapStateToProps = ({ project }) => ({
  project,
})

export default connect(mapStateToProps, { updateGabarit })(
  withStyles(styles)(CalibrationConfig)
)
