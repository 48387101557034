import React, { Component } from 'react'
import { connect } from 'react-redux'
import RulesForm from './RulesForm'
import /*rulesValues, rulesToData */ '../../../common/dataMapper'
import { withStyles } from '@material-ui/core'
// import {
//   // defaultRules,
//   sousDestinationList,
// } from '../../../common/defaultValues/defaultValues'
import {
  getParcelleRules,
  updateRules,
  updateRulesCommune,
} from 'store/map/infoInteraction/infoInteractionReducer'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
})

class RulesConfig extends Component {
  state = {}

  componentDidUpdate(prevProps, prevState) {
    const { getParcelleRules, parcelleId, rulesConfig } = this.props
    if (
      this.props.parcelleId &&
      prevProps.parcelleId !== this.props.parcelleId
    ) {
      getParcelleRules({ parcelle_id: parcelleId })
    }
    if (rulesConfig && rulesConfig !== prevProps.rulesConfig) {
      this.setState(/*rulesValues(*/ rulesConfig /*)*/)
    }
  }

  updateRules = e => {
    e.preventDefault()
    this.props.updateRules({
      parcelleId: this.props.parcelleId,
      rules: this.state,
    })
    this.props.onClose()
  }

  updateRulesCommune = e => {
    e.preventDefault()
    this.props.updateRulesCommune({
      parcelleId: this.props.parcelleId,
      rules: this.state,
    })
    this.props.onClose()
  }

  handleClose = () => {
    this.setState(/*rulesValues(*/ this.props.rulesConfig) //)
    this.props.onClose()
  }
  handleRuleSelectionChange = (entryType, entryName, newValue = null) => {
    const entry = this.state[entryName]
    switch (entryType) {
      case 'selection':
        this.setState({
          [entryName]: { ...entry, value: newValue ? newValue : entry.value },
        })

        break
      default:
    }
  }

  /* Changement de la valeur saisie selon le type d'entrée */
  handleRuleEntryChange = (entryType, entryName) => e => {
    const entry = this.state[entryName]

    switch (entryType) {
      case 'bool':
        this.setState({ [entryName]: { ...entry, value: !entry.value } })
        break

      case 'float':
        let saisie = e.target.value
        if (entry.unit === '%') {
          saisie = saisie > 100 ? 100 : saisie
        } else {
          saisie = saisie > 1000000 ? 1000000 : saisie
        }
        this.setState({
          [entryName]: { ...entry, value: saisie },
        })
        break

      case 'text':
        this.setState({
          [entryName]: { ...entry, value: e.target.value },
        })
        break

      case 'list':
        // if (entryName === 'destination') {
        //   // pour mettre un choix valide sur la sousdestination quelque soit la destination choisie
        //   // const defaultSsdestVal =
        //   //   sousDestinationList[e.target.value][0]['value']
        //   // const sousDestinationEntry = this.state['sousDestination']
        //   // this.setState({
        //   //   [entryName]: { ...entry, value: e.target.value },
        //   //   sousDestination: {
        //   //     ...sousDestinationEntry,
        //   //     value: defaultSsdestVal,
        //   //   },
        //   // })
        // } else {
        this.setState({
          [entryName]: { ...entry, value: e.target.value },
        })
        // }
        break

      default:
    }
  }

  render() {
    const {
      classes,
      project,
      parcelleId,
      getParcelleRules,
      rulesConfig,
      ...otherProps
    } = this.props
    // console.log(this.state)

    return (
      <RulesForm
        {...otherProps}
        inputs={this.state}
        updateRules={this.updateRules}
        updateRulesCommune={this.updateRulesCommune}
        handleClose={this.handleClose}
        handleRuleEntryChange={this.handleRuleEntryChange}
        handleRuleSelectionChange={this.handleRuleSelectionChange}
        parcelleId={parcelleId}
      />
    )
  }
}

const mapStateToProps = ({
  project,
  map: {
    infoInteraction: { geojson, rulesConfig },
  },
}) => ({
  project,
  parcelleId: geojson ? geojson.features[0].id : null,
  rulesConfig,
})

export default connect(mapStateToProps, {
  getParcelleRules,
  updateRules,
  updateRulesCommune,
})(withStyles(styles)(RulesConfig))
