import getHoveredElmts from './hoveredElmts'
import { customMapControlsEventBlocker } from './eventBlock'

export default function() {
  const getParcelleInfo = this.props.getParcelleInfo
  const cleanHoveredInfo = this.props.cleanHoveredInfo

  return {
    onClick: e => {
      // console.log(e.target.className)
      const parcelle = e.features
        ? e.features.find(f => f.layer.id === 'cadastre_parcelles_fill')
        : null
      if (parcelle && customMapControlsEventBlocker(e.target.className)) {
        getParcelleInfo({
          parcelleId: parcelle.properties.parcelleId,
        })
      }
    },
    onHover: e => {
      getHoveredElmts(e, this.props)
    },
    onMouseLeave: e => {
      cleanHoveredInfo()
    },
  }
}
