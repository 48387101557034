import { createStore, compose, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'
import rootReducer from './rootReducer'

import DEFAULT_STATE from './DEFAULT_STATE'

// const persistedState = localStorage.getItem('reduxState')
//   ? JSON.parse(localStorage.getItem('reduxState'))
//   : DEFAULT_STATE

const enhancer = compose(
  // permet à Redux de communiquer avec une API de façon asynchrone
  applyMiddleware(thunkMiddleware, promiseMiddleware()),
  // permet à l'extension redux-devtools de Chrome de fonctionner si celle-ci est active
  // voir : https://github.com/zalmoxisus/redux-devtools-extension#usage
  typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : x => x
)

const store = createStore(rootReducer, DEFAULT_STATE, enhancer)

// store.subscribe(() => {
//   localStorage.setItem('reduxState', JSON.stringify(store.getState().user.sid))
// })

export default store
