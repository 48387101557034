import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Loading from '../components/Loading'

import { startSession } from '../../store/user/sessionReducer'
import { validSession } from '../../store/user/validSessionReducer'

class PrivateRoute extends Component {
  componentDidMount() {
    this.props.startSession()
  }

  render() {
    const {
      component: Component,
      sessionIsValid,
      waitingValidation,
      cookies,
      ...rest
    } = this.props
    if (waitingValidation) {
      return <Loading />
    } else {
      return (
        <Route
          {...rest}
          render={props => {
            return sessionIsValid ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: this.props.location },
                }}
              />
            )
          }}
        />
      )
    }
  }
}

const mapStateToProps = ({ user: { session } }) => {
  return {
    sessionIsValid: session.isValid,
    waitingValidation: session.isValid === undefined,
  }
}

export default connect(
  mapStateToProps,
  { startSession, validSession }
)(PrivateRoute)
