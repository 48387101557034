import { area, buffer, simplify } from '@turf/turf'

const bufferRadiusInit = newArea => {
  // initialisation pour le rayon du buffer
  if (newArea > 5000) {
    return -10
  } else if (newArea <= 5000) {
    return -1
  } else {
    return -0.1
  }
}

export default function bufferParcelToBati(polygon, targetArea) {
  // cette fct  reduit  un polygon de parcelle jusqu'a un aire cible(m²)
  // var prevArea = null
  let i = 0
  let stepReduction = 0
  let newArea = area(polygon) // initialisation pour l'aire apres buffer du polygon
  let bufferRadius = bufferRadiusInit(newArea)

  // newArea <= 100 ? -1 : -10 // initialisation pour le rayon du buffer

  console.log('cible: ', targetArea)
  console.log(i, newArea, newArea - targetArea)

  console.log('Boucle de reduction (rayon 1m)')
  do {
    i = i + 1
    const prevArea = area(polygon)
    //aire avant reduction
    const prevRemaining = prevArea - targetArea
    // ce qu'il restait a l'iteration precedante
    try {
      polygon = buffer(polygon, bufferRadius, {
        // on reduit le polygon d'un certain rayon
        units: 'meters',
      })
      newArea = area(polygon) // maj de l'aire du polygon
    } catch (error) {
      console.log('erreur sur le nouveau polygon')
      console.log(polygon)
      console.error(error)
      return null
    }

    const remaining = newArea - targetArea // ce qu'il reste a reduire
    console.log(i, area(polygon), remaining)

    if (remaining - stepReduction < 0) {
      /*Necessaire pour que le polygon ne disparaisse pas =>crash de l'algorithme.
      Si le step de l'iteration precedante est superieur a ce qu'il reste a
      reduire on abaisse le rayon de reduction  */
      console.log('reducebuffer from', bufferRadius, 'to', bufferRadius / 2)
      try {
        const simpoly = simplify(polygon, {
          tolerance: 0.00001,
          highQuality: false,
          mutate: true,
        })
        area(simpoly)
        polygon = simpoly
      } catch (err) {
        console.log('erreur sur la simplification')
        console.log(err)
      }

      bufferRadius = bufferRadius / 2
    }
    stepReduction = prevRemaining - remaining //màj du pas de reduction
    if (remaining < 0 && bufferRadius < 0) {
      bufferRadius = (bufferRadius * -1) / 2
      console.log('changement + ', bufferRadius)
    }
    if (remaining > 0 && bufferRadius > 0) {
      bufferRadius = (bufferRadius * -1) / 2
      console.log('changement - ', bufferRadius)
    }

    if (remaining > -1 && remaining < 1) {
      break // cible atteinte a un metre pres
    }
  } while (
    (newArea < Math.trunc(targetArea) - 1 ||
      newArea > Math.trunc(targetArea) + 1) &&
    i < 500
  )

  return polygon
}

// [2.4295759007041955, 48.91286066366932],
// [2.429587217051324, 48.91288005800498],
// [2.4296408030851575, 48.91289901439085],
// [2.4299810389210394, 48.913003623857996],
// [2.4300264633113593, 48.91300049562951],
// [2.4304749429948744, 48.91248774718671],
// [2.4308184471712546, 48.91230884893064],
// [2.430745118862422, 48.91208000439861],
// [2.4304503178373165, 48.91199969616661],
// [2.4304148765145808, 48.91196373630202],
// [2.4303964121829047, 48.91187384284447],
//  [2.43062329760809, 48.91139030259037],
//  [2.4306184486818494, 48.911318038937715],
//  [2.431059808857411, 48.910376006628105],
//  [2.431049072552461, 48.91034696570817],
//  [2.4309651011838054, 48.9103087920985],
// [2.430910017927888, 48.910327099892875],
// [2.4307880368632673, 48.91058921104764],
// [2.4306031683388665, 48.91092943532175],
// [2.4300758229514443, 48.91177840704751],
// [2.4300075656323905, 48.91181624826617],
// [2.429893459738539, 48.9118176010872],
// [2.4298645272490265, 48.91183416667706],
// [2.429857403698655, 48.91214679182125],
// [2.429809120779269, 48.912366606713626],
// [2.4295759007041955, 48.91286066366932]
