import reduceReducers from 'reduce-reducers'

import logOutReducer from './logOutReducer'
import userProjects from './userProjectsReducer'
import sessionReducer from './sessionReducer'
import logInReducer from './logInReducer'
import validSessionReducer from './validSessionReducer'

export default reduceReducers(
  logInReducer,
  sessionReducer,
  logOutReducer,
  userProjects,
  validSessionReducer
)
