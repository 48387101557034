import DEFAULT_STATE from '../DEFAULT_STATE'
import infoInteraction, {
  cleanParcelleInfo,
} from './infoInteraction/infoInteractionReducer'
import findParcelleReducer from './findParcelleReducer'
import WebMercatorViewport from 'viewport-mercator-project'

import createDuck from '../createDuck'
import reduceReducer from 'reduce-reducers'

import Axios from 'axios'

// Actions
const UPDATE_VIEWPORT = 'batir/map/UPDATE_VIEWPORT'
const GENERATE_VIEWPORT = 'batir/map/GENERATE_VIEWPORT'
const UPDATE_LAYERS = 'batir/map/UPDATE_LAYERS'
const CHANGE_INTERACTION = 'batir/map/CHANGE_INTERACTION'
const SWITCH_SATELLITE = 'batir/map/SWITCH_SATELLITE'
const CLOSE_STREETVIEW = 'batir/map/CLOSE_STREETVIEW'

const OPEN_STREETVIEW = 'batir/map/OPEN_STREETVIEW'

// Reducer
const mapViewportReducer = function(state, action) {
  switch (action.type) {
    case 'batir/user/LOG_OUT_REJECTED':
      return { ...DEFAULT_STATE.map }

    case UPDATE_VIEWPORT:
      return { ...state, viewport: action.viewport }

    case GENERATE_VIEWPORT:
      return {
        ...state,
        viewport: action.payload.viewport,
      }

    case UPDATE_LAYERS:
      return { ...state, thematics: action.thematics }

    case CHANGE_INTERACTION:
      return { ...state, interaction: action.payload }

    case SWITCH_SATELLITE:
      return { ...state, satellite: !state.satellite }

    case CLOSE_STREETVIEW:
      return {
        ...state,
        streetView: { ...state.streetView, openedStreetView: false },
      }

    case OPEN_STREETVIEW:
      return {
        ...state,
        streetView: {
          openedStreetView: true,
          lng: action.lng,
          lat: action.lat,
        },
      }

    default:
      return state
  }
}

// Action Creators
export function getViewportFromBbox(bbox, pitch = null, zoom = null) {
  const viewport = new WebMercatorViewport({
    width: window.innerWidth,
    height: window.innerHeight,
  }).fitBounds(bbox)

  let newViewport = { ...viewport }
  if (zoom) {
    newViewport = { ...newViewport, zoom: zoom }
  }
  if (pitch) {
    newViewport = { ...newViewport, pitch: pitch }
  }

  return {
    type: GENERATE_VIEWPORT,
    payload: { viewport: { ...newViewport } },
  }
}

// export function getViewportFromLocation(address) {
//   return function(dispatch) {
//     console.log(fetchLocation(address))
//     dispatch(fetchLocation(address))
//   }
// }

export function fetchLocation(address) {
  return (dispatch, getState) => {
    Axios.get(
      `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(
        address
      )}&autocomplete=0&limit=1`
    ).then(({ data }) => {
      const prevViewport = getState().map.viewport
      console.log(prevViewport)
      if (data.features.length > 0) {
        const { coordinates } = data.features[0].geometry
        const viewport = {
          ...prevViewport,
          longitude: coordinates[0],
          latitude: coordinates[1],
          zoom: 15.5,
          pitch: 60,
        }
        dispatch({ type: UPDATE_VIEWPORT, viewport: viewport })
      }
    })
  }
}

// export function fetchGeocoder(lon, lat) {
//   return (dispatch, getState) => {
//     //   Axios.get(
//     //     `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(
//     //       address
//     //     )}&autocomplete=0&limit=1`
//     //   ).then(({ data }) => {
//     const prevViewport = getState().map.viewport
//     // if (data.features.length > 0) {
//     // const { coordinates } = data.features[0].geometry
//     const viewport = {
//       ...prevViewport,
//       longitude: lon,
//       latitude: lat,
//       zoom: 15.5,
//     }
//     dispatch({ type: UPDATE_VIEWPORT, viewport: viewport })
//     // }
//     // })
//   }
// }

// export function fetchLocation(address) {
//   return {
//     type: UPDATE_VIEWPORT,
//     viewport:
//     Axios.get(
//       `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(
//         address
//       )}&autocomplete=0&limit=1`
//     ).then(({ data }) => {
//       if (data.features.length > 0) {
//         const { coordinates } = data.features[0].geometry
//         console.log(coordinates)
//         return { longitude: coordinates[0], latitude: coordinates[1] }
//       }
//     }),
//   }
// }

export function updateViewport(viewport) {
  return {
    type: UPDATE_VIEWPORT,
    viewport,
  }
}

export function updateLayers(thematics) {
  return {
    type: UPDATE_LAYERS,
    thematics,
  }
}

export function changeInteraction(interactionType) {
  return (dispatch, getState) => {
    if (
      // pour re-afficher les parcelles
      interactionType === 'info' ||
      interactionType === 'select' ||
      interactionType === 'measure' ||
      interactionType === 'generate'
    ) {
      const { thematics } = getState().map
      const updatedThematics = thematics.map(thema => {
        return thema.thematicId === 'parcellaire'
          ? {
              ...thema,
              layers: thema.layers.map(layer =>
                layer.layerId === 'cadastre_parcelles'
                  ? { ...layer, visible: true }
                  : layer
              ),
            }
          : thema
      })

      dispatch({
        type: UPDATE_LAYERS,
        thematics: updatedThematics,
      })
    }
    if (getState().interaction !== 'info') dispatch(cleanParcelleInfo())
    dispatch({
      type: CHANGE_INTERACTION,
      payload: interactionType,
    })
  }
}

export function switchSatellite() {
  return { type: SWITCH_SATELLITE }
}

export function closeStreetView() {
  return { type: CLOSE_STREETVIEW }
}

export function openStreetView(lng, lat) {
  return { type: OPEN_STREETVIEW, lng, lat }
}

const { reducer: getMapInfoReducer, getMapInfo } = createDuck({
  namespace: 'batir/map',
  actionName: 'getMapInfo',
  payloadName: 'thematics',
  path: process.env.REACT_APP_API_PATH + 'map/getMapInfo.php',
})

// const urlgeocoder = address => {
//   return `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(
//     address
//   )}&autocomplete=0&limit=1`
// }
// const { reducer: fetchLocationReducer, fetchLocation } = createDuck({
//   namespace: 'batir/map',
//   actionName: 'fetchLocation',
//   // payloadName: 'viewport',
//   // path: urlgeocoder(),
//   callback: function(data) {
//     if (data.features.length > 0) {
//       const { coordinates } = data.features[0].geometry
//       console.log(coordinates)
//       // return  { longitude: coordinates[0], latitude: coordinates[1] }
//       // return (dispatch, getState) => {
//       //   dispatch(getViewportFromLngLat())
//       // }
//     }
//   },
// })

const mapReducer = reduceReducer(
  getMapInfoReducer,
  mapViewportReducer,
  findParcelleReducer
)

export default (state = DEFAULT_STATE.map, action) =>
  mapReducer(
    {
      ...state,
      infoInteraction: infoInteraction(state.infoInteraction, action),
    },
    action
  )

export { getMapInfo }
