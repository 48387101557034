import idfJson from './idfContour'

// source de données statiques stockées sur la base PostgreSQL
const vt_data_list = [
  'static_data',
  'static_bpe',
  'static_reseaux_ferre_idf',
  'static_dvf',
  'static_parcelles',
  'static_retrait',
  'static_bati_idf',
  'static_services_public',
  'static_patri_imm_bati',
  'static_patri_imm_zone',
  'static_patri_mondial_bati',
  'static_patri_mondial_zone',
  'static_perimetre_quartier_prioritaire',
  'static_zones_log_abc_123',
  'static_lineaire_comm',
  'static_plu_hauteur_type',
  'static_plu_emprise_type',
  'static_loyer_idf',
  'static_isohypse_idf',
  'static_permis_idf',
  'static_pprn_zone_alea_idf',
  'static_pprn_perimetre_idf',
  'static_pprn_zone_idf',
  'static_pprn_enjeu_idf',
  'static_pprt_zone_alea_idf',
  'static_pprt_perimetre_idf',
  'static_pprt_zone_idf',
  'static_pprt_enjeu_idf',
  'static_log_soc_idf',
  'static_prix_terre_agricole_idf',
  'static_projet_secteur_operationnel_idf',
  'static_projet_espace_bati_idf',
  'static_oin_idf',
  'static_sdrif_n_espace_urba',
  'static_sdrif_espace_urba',
  'static_r_argile',
  'static_r_inondation',
  'static_r_nappe',
  'static_r_cavite',
  'static_r_basias',
  'static_r_icpe',
  'static_ban_idf',
]

let vt_datas = {
  idfContour: {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [{ ...idfJson }],
    },
  },
}

vt_data_list.forEach(
  namespace =>
    (vt_datas[namespace] = {
      type: 'vector',
      tiles: [
        process.env.REACT_APP_ABS_API_PATH +
          'vt/getTileContent.php?layer=' +
          namespace +
          '&z={z}&x={x}&y={y}',
      ],
    })
)

export default vt_datas
