import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function MenuListIcon(props) {
  return (
    <SvgIcon {...props} style={{ fontSize: '1.7rem' }} viewBox="0 0 64 64">
      <image
        width={64}
        height={64}
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABiVBMVEUAAABhcX1hcX9hcH5h cX5hcH5jb4BmZmZhcX9gcX9gcX1icX5icX9hcX5ecoBgcX5hcX5gbnxhcX5hcX5hcH1icX5hcX5h cH5hcX9ic4BicX9hcH6AgIBhcn5fcH1kcHxicX1gcn5gc4BhcX5hcX1ib3xhb31hcX5hcX5jcYBb bYBgcX5idnZhcn5hcn5hcX5hcX6AgIBgcH5hcX5hcX5icn1hcX5hcn1icX5gcX1icX1gb31icX5h cX1gcn7bVQTbVAXbVATbVATbUwXaUgTMZgDaUwPcVAPZVATbVAXaVAXbVATdVwfbVAPbVAPdUwfb VATbVATcVATcVATbVATbUwPbUwXdVQTbVAPbUwO/QADcVAXcUgRhcX6Snabf4+X4+fnf4uWRnKX/ //+Tnqf4+Pn3+Pje4eSQm6STnqZjc4CnsLdse4dseofCyM3Ax8zw8vPw8fLu8PHBx8zAxsvd4OOP m6TbVATmiVL43c39+PT43MzmiFDmilT99/P99vL428vmh0/milMZBSu1AAAAXXRSTlMAP6nl+qg+ BZmXPaqn5Cbe3SX5+HRz49ylPJWWBKY7KWZ3KL69Jzfw7zYO5g17ec3LAl3z8l7xXIh6eDW8u2U/ qeX6qD4FmZc9qqfkJt7dJfn4dHPj3KU8lZYEpjs3Ne3OAAAAAWJLR0RjXL4tqgAAAAd0SU1FB+MK FxMlLB8xFSYAAAHmSURBVFjD7ZRZU1NBEEYHAgSQTZQgoJCwSdiUqIRFgZCEVRA1gCwfmCgmBpWd FggQ/OXMDFyqqMu9D+0j93uZqq46p3qmZ0YIJ05uJyfXlefKL+Di7kJcpcjNExi8NPD6l+Ta+td4 Qq7FHMEDIPFN5TtQwhG4gHUtiAOlqlBWDutUPDQJKoENLfgBPFKFx7BLlXUHScCjO6i24avNHeQD KS34CTzhnEGB9CaSG8mUXGtYcyy66a+WxQt3ncE/5QmEqCkprfQ8q+fiTnQavD5vIx9vbNJTbG5h 8q3Pr+9Bm5/Ft3cA6cRmKg24OjmCLuBXXD3G30A3R/AC+KNf4xbwUhV6Aj7rBHpMglfAthZsA69V 4Y3thxK4S7CjBbtAryoEbAXBu7awpwX7xhaCfdYJmrfQLw8xKfkDeYgDrDHKTzF9uHkox+hhjVH4 24yLNMjihWh5q/l3ZUxeKYZ8Q/+BO5EZHgmNhsIRLh4do6uMR3kCg5cGXv+S/Ht0fJKR6wRHMEmU 0a/xlGiKIwgRHWnBCdG0KryfIevMfjAJ5ojOtOCc6KMqfCK7fLbuIEsU0x3M2/Dz5g7CRBda8I9o gXMGEenNZM+yF3JdZM1x/Ka/LyxeRJcMfpknEGJxanoutrLKxZ3c51wCguYB1YOfak0AAAAldEVY dGRhdGU6Y3JlYXRlADIwMTktMTAtMjRUMDI6Mzc6NDQtMDc6MDCRkcN3AAAAJXRFWHRkYXRlOm1v ZGlmeQAyMDE5LTEwLTI0VDAyOjM3OjQ0LTA3OjAw4Mx7ywAAAABJRU5ErkJggg=="
      />
    </SvgIcon>
  )
}
