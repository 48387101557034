export const calcAireBatiTot = batiments => {
  let aireBatiTot = 0
  if (batiments) {
    batiments.forEach(bati => (aireBatiTot += bati.empr_sol_bati_m2))
  }
  return aireBatiTot
}

export const calcEmpBati = (superficieZone, surfBati) => {
  return (surfBati * 100) / superficieZone
}

export const checkBatiValidEmprise = (
  superficieZone,
  surfBati,
  empriseCoeffMax
) => {
  if (empriseCoeffMax && calcEmpBati(superficieZone, surfBati) < 100) {
    return calcEmpBati(superficieZone, surfBati) > empriseCoeffMax
      ? false
      : true
  } else {
    // pas de données OU non Reglementé
    return true
  }
}

export const checkBatiValidHauteur = (valeur, limite) => {
  if (limite === 0 || !limite) {
    //pas de données OU non Reglementé
    return true
  } else {
    return valeur > limite ? false : true
  }
}

export const concatAireBatis = batiments => {
  let arr = []
  if (batiments) {
    batiments.forEach(bati => arr.push(bati.empr_sol_bati_m2.toFixed(0)))
  }
  return arr.join(' ; ')
}

export const sommeAireBatis = batiments => {
  if (batiments) {
    const sum = (accumulator, bati) =>
      accumulator + parseInt(bati.empr_sol_bati_m2.toFixed(0))
    return batiments.reduce(sum, 0)
  } else {
    return 0
  }
}

export const concatHauteurBatis = batiments => {
  let arr = []
  if (batiments) {
    batiments.forEach(bati => arr.push(bati.height))
  }
  return arr.join(' ; ')
}

export const calculSurfaceParEtage = (
  hauteur,
  emprisePourcent,
  hParNiveau,
  aireParcelle
) => {
  if (!hParNiveau) {
    // console.log('erreur Division par zero')
    return null
  }
  return Math.trunc(
    ((aireParcelle * emprisePourcent) / 100) * Math.trunc(hauteur / hParNiveau)
  )
}
