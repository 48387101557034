import createDuck from '../createDuck'
import XLSX from 'xlsx'

const { reducer: exportPluReducer, exportPlu } = createDuck({
  namespace: 'batir/search',
  actionName: 'exportPlu',
  payloadName: 'dep',
  path: process.env.REACT_APP_API_PATH + 'search/exportPlu.php',
  callback: function({ dep, results }) {
    var new_workbook = XLSX.utils.book_new()
    var filename = 'Avancement_PLU_' + dep + '.xlsx'

    var sheet = XLSX.utils.json_to_sheet(
      results.map(
        ({
          insee16,
          nom,
          population,
          document,
          commune_rnu,
          document_pos_plu_ta,
          procedure_,
          date,
          zonage,
          servitude,
        }) => ({
          'Code INSEE': insee16,
          Commune: nom,
          Population: population,
          Document: document,
          RNU: commune_rnu,
          'POS/PLU/TA': document_pos_plu_ta,
          Procedure: procedure_,
          Date: date,
          Zonage: zonage,
          Servitude: servitude,
        })
      )
    )
    XLSX.utils.book_append_sheet(new_workbook, sheet, 'Secteurs')
    XLSX.writeFile(new_workbook, filename)
  },
})

export default exportPluReducer
export { exportPlu }
