import React from 'react'
import PropTypes from 'prop-types'
import { Typography, withStyles, Popover } from '@material-ui/core'
import Warning from '@material-ui/icons/Warning'
import EuroSymbol from '@material-ui/icons/EuroSymbol'
import AccountBalance from '@material-ui/icons/AccountBalance'
import LocationCity from '@material-ui/icons/LocationCity'
import DeviceHub from '@material-ui/icons/DeviceHub'
import PopupState, { bindToggle, bindPopover } from 'material-ui-popup-state'

import UrbanismeIcon from '../../../assets/UrbanismeIcon'
import TerritoirIcon from '../../../assets/TerritoirIcon'
import { ParcellaireIcon } from '../../../assets/ParcelCouchesIcon'
import EquipementIcon from '../../../assets/EquipementIcon'
import ImmoFoncierIcon from '../../../assets/ImmoFoncierIcon'

import LayersList from './LayersList'

const styles = theme => ({
  expansionPanelDetails: {
    paddingTop: 0,
    paddingBottom: 0,
    background: '#FFFFFF',
    color: theme.palette.secondary.main,
  },
  expanded: { marginTop: 1 },
  expansionPanel: {
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
    marginTop: 1,
    borderBottom: '1px solid #4b4b4b',
  },
  chevronIcon: { color: theme.palette.primary.main },
})

function ThematicsIcon({ thematicId }) {
  switch (thematicId) {
    case 'parcellaire':
      return <ParcellaireIcon />
    case 'prix_foncier':
      return <ImmoFoncierIcon />
    case 'equipement':
      return <EquipementIcon />
    case 'commerces':
      return null
    case 'fiscalite':
      return <EuroSymbol style={{ color: 'rgb(224, 116, 29)' }} />
    case 'risques':
      return <Warning style={{ color: 'rgb(224, 116, 29)' }} />
    case 'transport':
      return null
    case 'urbanisme':
      return <UrbanismeIcon />
    case 'patrimoine':
      return <AccountBalance style={{ color: 'rgb(224, 116, 29)' }} />
    case 'politique_ville':
      return <LocationCity style={{ color: 'rgb(224, 116, 29)' }} />
    case 'reseaux_souterains':
      return <DeviceHub style={{ color: 'rgb(224, 116, 29)' }} />
    case 'portraits':
      return <TerritoirIcon style={{ color: 'rgb(224, 116, 29)' }} />

    default:
      return null
  }
}

const ThematicsList = ({ classes, data, ...layersListProps }) => {
  return data.map((thematic, idx) => (
    <PopupState
      variant="popover"
      popupId={'popup-' + thematic.thematicId}
      key={thematic.thematicId}
    >
      {popupState => (
        <div
          {...bindToggle(popupState)}
          style={{
            padding: '12px 24px',
            borderBottom: '1px solid #4b4b4b',
          }}
        >
          {ThematicsIcon(thematic)}
          <Typography
            style={{
              fontSize: 12,
              color: 'white',
            }}
          >
            {thematic.title}
          </Typography>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            style={{
              pointerEvent: 'none',
            }}
          >
            <LayersList
              idx={idx}
              layers={thematic.layers}
              addCheckAll={thematic.thematicId === 'prix_foncier'}
              {...layersListProps}
            />
          </Popover>
        </div>
      )}
    </PopupState>
  ))
}

ThematicsList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      thematicId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      layers: PropTypes.arrayOf(
        PropTypes.shape({
          layerId: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          visible: PropTypes.bool.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired
  ).isRequired,
}

export default withStyles(styles)(ThematicsList)
