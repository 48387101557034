import React from 'react'
import { Checkbox, ListItemText, withStyles } from '@material-ui/core'

const styles = theme => ({})

const LayerItem = ({ layer, toggleLayer, classes, idx }) => (
  <>
    <Checkbox
      disabled={!layer.available}
      checked={layer.visible}
      value={layer.layerId}
      onClick={toggleLayer(idx)}
      color="secondary"
    />
    <ListItemText>{layer.title}</ListItemText>
  </>
)

export default withStyles(styles)(LayerItem)
