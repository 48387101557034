export default {
  user: { userProjects: [], userContacts: [], session: {} },
  project: {
    loaded: false,
    projectImplentation: {},
    gabaritConfig: {},
  },
  map: {
    interaction: 'none',
    infoInteraction: {},
    drawInteraction: {},
    editInteraction: {},
    satellite: false,
    streetView: {
      openedStreetView: false,
      lat: 48.8756483,
      lng: 2.2994439,
    },
  },
  drawing: {},
  search: {},
  filter: {
    active: false,
    smin: '',
    smax: '',
    sdpmin: '',
    sdpmax: '',
    proxiStation: false,
    fstation: false,
    notBuildedOnly: false,
    results: [0],
  },

  popupMessage: { text: 'Bienvenue sur votre projet UrbaScope' },
}
