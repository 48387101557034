import React from 'react'
// import PropTypes from 'prop-types'

import {
  Button,
  // TextField,
  Card,
  CardContent,
  Grid,
  CardHeader,
  CardActions,
  withStyles,
  Dialog,
  Typography,
  // InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

import {
  calcAireBatiTot,
  calcEmpBati,
  checkBatiValidEmprise,
  checkBatiValidHauteur,
} from 'common/calc/calc'

const styles = theme => ({
  dialogPaper: { overflow: 'visible', maxWidth: '80vw' },
  card: {
    overflow: 'visible',
  },
  cardHeader: {
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  cardHeaderTitle: {
    color: theme.palette.primary.contrastText,
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  errorMessage: {
    marginTop: theme.spacing(2),
  },
  button: {
    backgroundColor: 'rgb(0,0,0)',
  },
})

const SimulationResultForm = ({
  classes,
  inputs,
  handleClose,
  errorMessage,
  ...otherProps
}) => {
  const { batiments, projectZones, h_max, emp_max_autorise } = inputs
  const aireBatiTot = batiments ? calcAireBatiTot(batiments) : null

  return (
    <Dialog {...otherProps} fullWidth classes={{ paper: classes.dialogPaper }}>
      <Card className={classes.card}>
        <CardHeader
          color="primary"
          classes={{
            root: classes.cardHeader,
            title: 'w3-light-grey w3-center w3-border-bottom w3-padding',
          }}
          title="Resultats de la simulation"
        />
        <form onSubmit={handleClose}>
          <CardContent style={{ maxHeight: '70vh', overflow: 'auto' }}>
            <Grid
              container
              justify="space-around"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid container item sm={6} alignItems="flex-start" spacing={2}>
                <Typography
                  className="w3-border-bottom"
                  style={{ fontWeight: 'bold' }}
                >
                  Zone
                </Typography>

                <Grid container alignItems="flex-end" spacing={2}>
                  <List dense>
                    {projectZones && (
                      <>
                        <ListItem>
                          <ListItemText
                            primary={
                              'Superficie de la zone : ' +
                              projectZones.superficie.toFixed(2) +
                              ' m²'
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              'Superficie totale du bati : ' +
                              aireBatiTot.toFixed(2) +
                              ' m²'
                            }
                          />
                        </ListItem>

                        <ListItem>
                          <ListItemText
                            primary={
                              'Emprise totale du bati : ' +
                              calcEmpBati(
                                projectZones.superficie,
                                aireBatiTot
                              ).toFixed(2) +
                              ' %'
                            }
                            secondary={
                              emp_max_autorise
                                ? 'Emprise maximale autorisé : ' +
                                  emp_max_autorise +
                                  ' %'
                                : 'Aucune'
                            }
                          />
                          {!checkBatiValidEmprise(
                            projectZones.superficie,
                            aireBatiTot,
                            emp_max_autorise
                          ) && (
                            <ListItemIcon>
                              <WarningIcon style={{ color: 'red' }} />
                            </ListItemIcon>
                          )}
                        </ListItem>
                      </>
                    )}
                    <Divider />
                  </List>
                </Grid>
              </Grid>

              <Grid container item sm={6} alignItems="flex-start" spacing={2}>
                {batiments && batiments.length > 0
                  ? batiments.map(bati => (
                      <Grid
                        key={bati.building_id}
                        item
                        sm={6}
                        // alignItems="flex-start"
                        // spacing={2}
                      >
                        <Typography
                          className="w3-border-bottom"
                          style={{ fontWeight: 'bold' }}
                        >
                          {'Batiment ' + bati.building_id}
                        </Typography>
                        <List dense>
                          <ListItem>
                            <ListItemText
                              primary={'Hauteur : ' + bati.height + ' m'}
                              secondary={
                                'limite : ' + (h_max ? h_max + ' m' : 'Aucune')
                              }
                            />
                            {!checkBatiValidHauteur(bati.height, h_max) && (
                              <ListItemIcon>
                                <WarningIcon style={{ color: 'red' }} />
                              </ListItemIcon>
                            )}
                          </ListItem>

                          <ListItem>
                            <ListItemText
                              primary={
                                'Surface : ' +
                                bati.empr_sol_bati_m2.toFixed(2) +
                                ' m²'
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                'Emprise : ' +
                                calcEmpBati(
                                  projectZones.superficie,
                                  bati.empr_sol_bati_m2
                                ).toFixed(2) +
                                '%'
                              }
                            />
                          </ListItem>

                          <Divider />
                        </List>
                      </Grid>
                    ))
                  : 'Aucun batiment'}
              </Grid>
            </Grid>

            <Grid container item xs={12} alignItems="flex-end" spacing={2} />
            {errorMessage ? (
              <Typography
                className={classes.errorMessage}
                align="center"
                color="error"
              >
                {errorMessage}
              </Typography>
            ) : null}
          </CardContent>
          <CardActions classes={{ root: classes.cardActions }}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.button}
              disabled={false}
            >
              Fermer
            </Button>
          </CardActions>
        </form>
      </Card>
    </Dialog>
  )
}

// SimulationResultForm.propTypes = {
//   inputs: PropTypes.object,
// } TODO

export default withStyles(styles)(SimulationResultForm)
