import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  TextField,
  Card,
  CardContent,
  Grid,
  CardHeader,
  CardActions,
  withStyles,
} from '@material-ui/core'

import AccountCircle from '@material-ui/icons/AccountCircle'
import Lock from '@material-ui/icons/Lock'

const styles = theme => ({
  cardHeader: {
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingBottom: 2,
  },
  cardHeaderTitle: {
    color: theme.palette.primary.contrastText,
  },
  cardContent: {
    paddingTop: 2,
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
})

const Form = ({ classes, email, password, handleChange, handleSubmit }) => (
  <Grid
    container
    justify="center" // centrage horizontal
    alignItems="flex-start" // centrage vertical (à condition de forcer l'utilisation de toute la hauteur de l'écran)
    style={{ minHeight: '100vh' }} // utilisation de toute la hauteur de l'écran
  >
    <Grid item xs={10} sm={6} md={4}>
      <Card elevation={2}>
        <CardHeader
          color="primary"
          classes={{
            root: classes.cardHeader,
            title: classes.cardHeaderTitle,
          }}
          title="Connexion"
        />
        <form onSubmit={handleSubmit}>
          <CardContent
            classes={{
              root: classes.cardContent,
            }}
          >
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={1}>
                <AccountCircle />
              </Grid>
              <Grid item xs={11}>
                <TextField
                  color="secondary"
                  autoFocus
                  fullWidth
                  type="email"
                  label="email"
                  value={email}
                  name="email"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end" spacing={1}>
              <Grid item xs={1}>
                <Lock />
              </Grid>
              <Grid item xs={11}>
                <TextField
                  color="secondary"
                  type="password"
                  fullWidth
                  label="mot de passe"
                  value={password}
                  name="password"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions classes={{ root: classes.cardActions }}>
            <Button
              type="submit"
              color="secondary"
              disabled={email && password ? false : true}
            >
              Se connecter
            </Button>
          </CardActions>
        </form>
      </Card>
    </Grid>
  </Grid>
)

Form.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default withStyles(styles)(Form)
