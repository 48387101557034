import React, { Component } from 'react'
import { connect } from 'react-redux'
import mapboxgl from 'mapbox-gl'
import ReactMapGL, { Marker } from 'react-map-gl'

import memoize from 'memoize-one'
import isEqual from 'react-fast-compare'

import 'mapbox-gl/dist/mapbox-gl.css'

import { loadImages } from '../../assets/mapIcons'

import toolTipRenderer, {
  hoverableLayers,
} from '../../common/mapStyle/toolTipRenderer'

import PositionMarker from '../../assets/PositionMarker'

import DrawOverlay from './DrawOverlay/DrawOverlay'

import { updateViewport } from '../../store/map/mapReducer'
import defaultMapStyle from '../../common/mapStyle/defaultMapStyle'

import idfJson from '../../common/mapStyle/sources/idfContour'
import CustomMapControls from './CustomMapControls/CustomMapControls'

import buffer from '@turf/buffer'
// import centroid from '@turf/centroid'

const mapboxAccessToken = process.env.REACT_APP_MAPBOX_TOKEN

const defaultInteractiveLayersIds = {
  info: [...hoverableLayers, 'cadastre_parcelles_fill'],
  select: [...hoverableLayers, 'cadastre_parcelles_fill'],
  edit: [...hoverableLayers, 'projects_buildings_fill'],
  generate: [...hoverableLayers, 'cadastre_parcelles_fill'],
  create: [...hoverableLayers],
  none: [...hoverableLayers],
}

const labelManagementList = [
  {
    layer_concerned: 'bpe_label',
    checked_id: 'bpe',
  },
  {
    layer_concerned: 'metro_idf',
    checked_id: 'reseaux_ferre_idf',
  },
  {
    layer_concerned: 'gare_idf',
    checked_id: 'reseaux_ferre_idf',
  },
  {
    layer_concerned: 'plu_hauteur_contour',
    checked_id: 'plu_hauteur_type',
  },
  {
    layer_concerned: 'plu_hauteur_label',
    checked_id: 'plu_hauteur_type',
  },
  {
    layer_concerned: 'plu_emprise_contour',
    checked_id: 'plu_emprise_type',
  },
  {
    layer_concerned: 'plu_emprise_label',
    checked_id: 'plu_emprise_type',
  },
  {
    layer_concerned: 'cadastre_parcelles_fill',
    checked_id: 'cadastre_parcelles',
  },
  {
    layer_concerned: 'zones_log_abc_123_label',
    checked_id: 'zones_log_abc_123',
  },
  {
    layer_concerned: 'loyer_idf_label',
    checked_id: 'loyer_idf',
  },
  {
    layer_concerned: 'isohypse_idf_label',
    checked_id: 'isohypse_idf',
  },
  {
    layer_concerned: 'log_soc_idf_label',
    checked_id: 'log_soc_idf',
  },
  {
    layer_concerned: 'prix_terre_agricole_idf_label',
    checked_id: 'prix_terre_agricole_idf',
  },
  {
    layer_concerned: 'prix_terre_agricole_idf_contour',
    checked_id: 'prix_terre_agricole_idf',
  },
  {
    layer_concerned: 'projet_secteur_operationnel_idf_label',
    checked_id: 'projet_secteur_operationnel_idf',
  },
  {
    layer_concerned: 'projet_secteur_operationnel_idf_contour',
    checked_id: 'projet_secteur_operationnel_idf',
  },
  {
    layer_concerned: 'projet_espace_bati_idf_label',
    checked_id: 'projet_espace_bati_idf',
  },
  {
    layer_concerned: 'oin_idf_label',
    checked_id: 'oin_idf',
  },
  {
    layer_concerned: 'r_basias_label',
    checked_id: 'r_basias',
  },
]

const labeledLayerList = labelManagementList.map(lm => lm.layer_concerned)

const getChecked_idByLayerConcerned = layer_concerned => {
  return labelManagementList.find(lm => lm.layer_concerned === layer_concerned)
    .checked_id
}

class MapContainer extends Component {
  constructor(props) {
    super(props)
    this.mapRef = React.createRef()

    this.state = {
      mapStyle: defaultMapStyle,
      defaultInteractiveLayersIds,
      positionMarkerGeojson: null,
    }
  }

  componentDidMount() {
    var scale = new mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: 'metric',
    })
    this.mapRef.current.getMap().addControl(scale)
  }

  // maj de la vue de la carte
  onViewportChange = viewport => {
    this.props.updateViewport(viewport)
  }

  // réexécute la création du style uniquement si un des paramètres a changé
  createMapStyle = memoize(
    ({
      defaultMapStyle,
      layersVisibility,
      projectArea,
      projectBuildings,
      hoveredBuildingId,
      movingBuilding,
      hoveredParcelleId,
      buildingMoveTarget,
      hideBuildingElevation,
      satellite,
      filter,
      hpNiv,
    }) => {
      // génère le style en injectant la propriété indiquant la visibilité pour les couches provenant de la base de données
      const layers = defaultMapStyle.layers.map(layer => {
        //Traitement des cas Particuliers (pour associer un style dont l'id n'est pas reconnu dans la table de la BDD)
        /* pour associer a une case de la liste du panneau de gauche et ne pas la distinguer comme nouvelle couche  */
        if (labeledLayerList.includes(layer.id)) {
          if (
            layersVisibility.hasOwnProperty(
              getChecked_idByLayerConcerned(layer.id)
            )
          ) {
            return {
              ...layer,
              layout: {
                ...layer.layout,
                visibility: layersVisibility[
                  getChecked_idByLayerConcerned(layer.id)
                ]
                  ? 'visible'
                  : 'none',
              },
            }
          } else {
            return { ...layer }
          }
        } else {
          // reste des couches du panneau de gauche

          if (layersVisibility.hasOwnProperty(layer.id)) {
            return {
              ...layer,
              layout: {
                ...layer.layout,
                visibility: layersVisibility[layer.id] ? 'visible' : 'none',
              },
            }
          } else {
            return layer
          }
        }
      })

      // Géneration des étagements de batiments tracé
      let projectBuildingsFloors = {
        type: 'FeatureCollection',
        features: [],
      }
      projectBuildings.features.forEach(emprise => {
        const nbniv = Math.floor(emprise.properties.height / hpNiv)
        emprise.properties.nbniv = nbniv
        for (let i = 1; i < nbniv; i++) {
          const floorLevel = hpNiv * i
          projectBuildingsFloors.features.push({
            ...emprise,
            properties: {
              floorLevelInf: floorLevel - 0.1,
              floorLevelSup: floorLevel + 0.1,
            },
          })
        }
      })
      projectBuildingsFloors = buffer(projectBuildingsFloors, 0.0001)

      // sources absentes du style initial
      const sources = {
        ...defaultMapStyle.sources,
        projects_parcelles: {
          type: 'geojson',
          data: projectArea,
        },
        projects_buildings: {
          type: 'geojson',
          data: projectBuildings,
          buffer: 512, // le buffer permet de réduire les risques de coupures de polygones au clic
        },
        projects_buildings_floors: {
          type: 'geojson',
          data: projectBuildingsFloors,
          buffer: 512,
        },
        idfContour: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [{ ...idfJson }],
          },
        },
        buildingMoveTarget: {
          type: 'geojson',
          data: buildingMoveTarget,
          buffer: 512,
        },
      }

      const mapStyle = {
        ...defaultMapStyle,
        layers,
        sources,
      }
      // pour mettre en rouge les parcelle lorsque le filtrage est actif
      const { results } = filter
      const resultsConds = results.length === 0 ? [''] : results
      mapStyle.layers = mapStyle.layers.map(layer =>
        layer.id === 'cadastre_parcelles'
          ? {
              ...layer,
              paint: {
                ...layer.paint,
                'line-color': [
                  'match',
                  ['get', 'parcelleId'],
                  resultsConds,
                  'hsl(0, 100%, 60%)',
                  'hsl(0, 0%, 20%)',
                ],
                'line-width': [
                  'match',
                  ['get', 'parcelleId'],
                  resultsConds,
                  6,
                  1,
                ],
              },
            }
          : layer
      )

      // filtrage de la parcelle en cours de survol lors de la sélection des parcelles
      if (hoveredParcelleId) {
        mapStyle.layers = mapStyle.layers.map(layer =>
          layer.id === 'cadastre_parcelles_selected'
            ? { ...layer, filter: ['==', 'parcelleId', hoveredParcelleId] }
            : layer
        )
      }

      //filtrage du bâti en cours de survol lors de l'édition/suppression du bâti
      if (hoveredBuildingId) {
        mapStyle.layers = mapStyle.layers.map(layer =>
          layer.id === 'projects_buildings_selected'
            ? { ...layer, filter: ['==', '$id', hoveredBuildingId] }
            : layer
        )
      }
      // pour le deplacement du batit
      mapStyle.layers = mapStyle.layers.map(layer =>
        layer.id === 'buildingMoveTarget'
          ? {
              ...layer,
              layout: {
                ...layer.layout,
                visibility: movingBuilding ? 'visible' : 'none',
              },
            }
          : layer
      )

      //on cache la couche d'élévation à l'édition de l'emprise du bâti
      if (hideBuildingElevation) {
        mapStyle.layers = mapStyle.layers.map(layer =>
          layer.id === 'projects_buildings_elevation' ||
          layer.id === 'projects_buildings_floors' ||
          layer.id === 'projects_buildings_sup'
            ? { ...layer, layout: { visibility: 'none' } }
            : layer
        )
      }

      //on cache/affiche la couche satellite
      mapStyle.layers = mapStyle.layers.map(layer =>
        layer.id === 'satellite' && satellite ? { ...layer, layout: {} } : layer
      )

      return mapStyle
    },
    isEqual
  )

  getCursor = ({ isLoaded, isDragging, isHovering }) => {
    if (!isLoaded) return 'wait'
    else if (isHovering && this.props.clickLocations.length === 0)
      return 'pointer'
    else if (
      this.props.interaction === 'create' ||
      this.props.clickLocations.length > 0
    )
      return 'crosshair'
    else return 'default'
  }

  handleOnResult = event => {
    this.setState({
      positionMarkerGeojson: event.result.geometry,
    })
  }

  set3DView = () => {
    const oldPitch = this.props.viewport.pitch
    const newPitch = oldPitch === 0 ? 60 : 0
    this.props.updateViewport({ ...this.props.viewport, pitch: newPitch })
  }

  render() {
    const {
      viewport,
      visibility,
      projectArea,
      projectBuildings,
      hoveredBuildingId,
      movingBuilding,
      hoveredParcelleId,
      buildingMoveTarget,
      hideBuildingElevation,
      interaction,
      satellite,
      filter,
      hpNiv,
      measuredDistance,
      measuredSurface,
    } = this.props

    const mapStyle = this.createMapStyle({
      defaultMapStyle: this.state.mapStyle,
      layersVisibility: visibility,
      projectArea,
      projectBuildings,
      hoveredBuildingId,
      movingBuilding,
      hoveredParcelleId,
      buildingMoveTarget,
      hideBuildingElevation,
      satellite,
      filter,
      hpNiv,
    })

    const { positionMarkerGeojson } = this.state
    return (
      <>
        <ReactMapGL
          mapboxApiAccessToken={mapboxAccessToken}
          attributionControl={false}
          {...viewport}
          width="100%"
          height="100%"
          onViewportChange={this.onViewportChange}
          mapStyle={mapStyle}
          ref={this.mapRef}
          getCursor={this.getCursor}
          {...this.props.eventHandlers}
          mapRef={this.mapRef}
          doubleClickZoom={false}
          interactiveLayerIds={
            this.state.defaultInteractiveLayersIds[interaction] || []
          }
          onLoad={() => loadImages(this.mapRef.current.getMap())}
        >
          {toolTipRenderer(this.props)}
          {Boolean(positionMarkerGeojson) && (
            <Marker // positon lors du geocodage(apres saisie d'une adresse)
              latitude={positionMarkerGeojson.coordinates[1]}
              longitude={positionMarkerGeojson.coordinates[0]}
            >
              <PositionMarker></PositionMarker>
            </Marker>
          )}
          <DrawOverlay
            clickLocations={this.props.clickLocations}
            mouseLocation={this.props.mouseLocation}
            editedNode={this.props.editedNode}
            pixelBBox={this.props.pixelBBox}
            handleEditionMode={this.props.handleEditionMode}
            handlePolygonEdition={this.props.handlePolygonEdition}
            snappedPoint={this.props.snappedPoint}
          />
          <CustomMapControls
            viewport={viewport}
            setNorth={this.setNorth}
            set3DView={this.set3DView}
            mapRef={this.mapRef}
            onViewportChange={this.onViewportChange}
            mapboxAccessToken={mapboxAccessToken}
            handleOnResult={this.handleOnResult}
            measuredDistance={measuredDistance}
            measuredSurface={measuredSurface}
          />
        </ReactMapGL>
      </>
    )
  }
}

const mapStateToProps = ({
  map: {
    viewport,
    thematics,
    interaction,
    satellite,
    infoInteraction: { hoveredInfo },
  },
  drawing,
  project: { projectArea, projectBuildings, gabaritConfig },
  filter,
}) => {
  // génération de la visibilité des couches à partir de l'arbre des thématiques
  const visibility = thematics
    ? thematics
        .reduce(
          (mergedLayers, thematic) => [...mergedLayers, ...thematic.layers],
          []
        )
        .reduce(
          (layersVisibility, layer) => ({
            ...layersVisibility,
            [layer.layerId]: layer.visible,
          }),
          {}
        )
    : {}
  return {
    interaction,
    viewport,
    visibility,
    projectArea,
    projectBuildings,
    satellite,
    filter,
    hoveredInfo,
    movingBuilding: Boolean(drawing.action === 'movePolygon'),
    hpNiv: gabaritConfig.log_hau_niv || gabaritConfig.bur_hau_niv,
    measuredDistance: drawing.measureDistance || '',
    measuredSurface: drawing.measureSurface || '',
  }
}

const mapDispatchToProps = {
  updateViewport,
}

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer)
