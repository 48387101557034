import React from 'react'
import {
  withStyles,
  // List,
  // ListItem /*ListItemText*/,
  Tooltip,
  Typography,
} from '@material-ui/core'

import services from '../../../assets/bpe/services.png'
import commerces from '../../../assets/bpe/commerces.png'
import enseignement from '../../../assets/bpe/enseignement.png'
import sante from '../../../assets/bpe/sante.png'
import transports from '../../../assets/bpe/transports.png'
import loisirs from '../../../assets/bpe/loisirs.png'

import services_publics from '../../../assets/services_publics/services_publics.png'

import dvf2014 from '../../../assets/dvf/dvf2014.png'
import dvf2015 from '../../../assets/dvf/dvf2015.png'
import dvf2016 from '../../../assets/dvf/dvf2016.png'
import dvf2017 from '../../../assets/dvf/dvf2017.png'
import dvf2018 from '../../../assets/dvf/dvf2018.png'

import train from '../../../assets/transports/train.png'
import metro from '../../../assets/transports/metro.png'

const styles = theme => ({
  symbolLine: {
    height: theme.spacing(0.5),
    width: theme.spacing(4),
    border: 'none',
  },
})

const icon = text => {
  switch (text) {
    case 'Services':
      return services
    case 'Commerces':
      return commerces
    case 'Enseignement':
      return enseignement
    case 'Santé':
      return sante
    case 'Transports et déplacements':
      return transports
    case 'Sports, loisirs et culture':
      return loisirs

    case 'services_publiques':
      return services_publics

    case 'dvf2014':
      return dvf2014
    case 'dvf2015':
      return dvf2015
    case 'dvf2016':
      return dvf2016
    case 'dvf2017':
      return dvf2017
    case 'dvf2018':
      return dvf2018

    case 'train':
      return train
    case 'metro':
      return metro

    default:
      return text
  }
}

const LegendContent = ({ classes, title, legend, on }) => {
  return legend.map(({ color, text, info }, idx) =>
    title === 'Base permanente des équipements' ||
    title === 'Gares et stations de métro' ||
    title === 'Services publics' ||
    title.startsWith('Demandes de valeurs foncières') ? (
      <Tooltip title={info} key={idx}>
        <div
          style={{
            width: '33px' /*container-width*/,
            height: '33px',
            overflow: 'hidden' /*hide bounds of image */,
            marginLeft: 10,
          }}
        >
          <img
            style={{
              display: 'block' /*remove inline-block spaces*/,
              width: '33px',
            }}
            src={icon(text)}
            alt="legende"
          />
        </div>
      </Tooltip>
    ) : (
      <Tooltip key={idx} title={info || ''}>
        <div // symbole de legende
          style={{
            backgroundColor:
              text === 'SCOT' || text === 'Communes' ? 'white' : color,
            width: 65,
            height: 33,
            marginLeft: 1,
            padding: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            borderBottom:
              text === 'SCOT' || text === 'Communes'
                ? '6px solid ' + color
                : null,
          }}
        >
          <Typography align="center">{text}</Typography>
        </div>
      </Tooltip>
    )
  )
}

export default withStyles(styles)(LegendContent)
