import { snapLocation } from './snapLocation'
import getHoveredElmts from './hoveredElmts'

export default function() {
  const { viewport, setBuildingPolygon } = this.props
  const { clickLocations, creatingNode } = this.state
  const cleanHoveredInfo = this.props.cleanHoveredInfo

  return {
    onClick: e => {
      // mise à jour de la ligne dessinée avec un nouveau sommet
      if (creatingNode) {
        const location = snapLocation(
          e.lngLat,
          this.getSnappingLocation(),
          10,
          viewport
        ).location

        // ajout d'un nouveau point à la ligne
        this.setState(
          {
            clickLocations: [...clickLocations, location],
          },
          () => {
            const { clickLocations } = this.state // il est important de récupérer le dernier état !

            if (clickLocations[0] === location) {
              // 1polygon doit avoir au moins 3points soit 4valeur fermé
              if (clickLocations.length === 2 || clickLocations.length === 3) {
                this.setState(this.initialState)
              } else if (clickLocations.length > 3) {
                // const poly = polygon([clickLocations], {})
                // console.log(poly)

                // le polygone est fermé, on stocke l'information dans l'état applicatif
                setBuildingPolygon(clickLocations)
                this.setState(this.initialState)
              }
            } else {
              this.setState({ creatingNode: false })
            }
          }
        )
      }
    },
    onMouseDown: e => {
      // lorsqu'on appuie sur le curseur on considère que l'on est en train de créer un point
      this.setState({ creatingNode: true })
    },
    onHover: e => {
      const { location, snapped } = snapLocation(
        e.lngLat,
        this.getSnappingLocation(),
        10,
        viewport
      )

      if (snapped) {
        this.setState({ mouseLocation: location, snappedPoint: location })
      } else {
        this.setState({ mouseLocation: location, snappedPoint: [] })
      }
      getHoveredElmts(e, this.props)
    },
    onMouseLeave: e => {
      cleanHoveredInfo()
    },
  }
}
